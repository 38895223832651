// import palette from './palette';

export default {
  fontFamily: ['NotoSansCJKkr', 'Noto Sans KR'].join(','),
  h1: {
    fontSize: '60px',
    fontWeight: 'bold',
    lineHeight: 1.33,
    letterSpacing: -3,
    textAlign: 'left',
  },
  h2: {
    fontSize: 38,
    letterSpacing: -1.9,
  },
  h3: {
    fontSize: 24
  },
  subtitle1: {
    fontSize: 32,
  },
  subtitle2: {
    fontSize: 20,
    lineHeight: 1.7,
  },
  body1: {
    fontSize: 18,
    lineHeight: 1.67,
    letterSpacing: -0.9,
  },
  body2: {
    fontSize: 16,
    lineHeight: 1.75,
    letterSpacing: -0.8,
  },
  caption: {
    fontSize: 14,
    lineHeight: 1.75,
    letterSpacing: -0.8,
  }
}