import React, { useState } from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { AppBar, Button, IconButton, Toolbar, Grid, Typography, Menu, MenuItem, Box } from '@material-ui/core';

import { Logo, LogoW, ImgProfile } from '../../static/images';
import { IconKakao } from '../../static/images/icon';

function Header({ type, history, loginStore }) {
  const classes = useStyles();
  const { userInfo, logoutAndInitialUser } = loginStore;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePage = (path) => {
    // console.log({ path });
    history.push(path);
  };

  const logout = async () => {
    await logoutAndInitialUser('logout')
    history.push('/')
  }

  const kakaoTest = () => {
    window.Kakao.Channel.chat({
      channelPublicId: '_AsExjC'
    });
  }

  return (
    <AppBar className={clsx(classes.header, classes[type])} id={type} position="absolute">
      <Toolbar className="toolbar">
        <IconButton edge="start" className="header_logo" color="inherit" aria-label="menu" onClick={() => handlePage('/')} />
        <Grid container direction="row" justify="space-between" alignItems="center" className="header_btns">
          <div className="header_main_btn">
            <Button className={clsx(classes.mBtn, "company")} onClick={() => handlePage('/company')}>
              <Typography className="txtDesc">회사 소개</Typography>
            </Button>
            <Button className={clsx(classes.mBtn, "service")} onClick={() => handlePage('/service')}>
              <Typography className="txtDesc servide">
                서비스 소개
              </Typography>
            </Button>
            <Button className={clsx(classes.mBtn, "advice") }id="kakao-talk-channel-chat-button" onClick={kakaoTest}>
              <Typography className="txtDesc advice pColor">
                실시간 상담
              </Typography>
              <div className="kakao_btn">
                <Grid container direction="row" justify="center" alignItems="center">
                  <img src={IconKakao} alt="" />
                </Grid>
              </div>
            </Button>
          </div>
          {
            userInfo && userInfo.userKey
              ? (
                <>
                  <Grid item className={classes.profile}>
                    <Grid  container direction="row" justify="flex-start" alignItems="center" onClick={handleClick}>
                      <img className={classes.iconProfile} src={ImgProfile} />
                      <Typography variant="body1" className="pageHead" color="textPrimary" className={classes.hi}>
                        <b>안녕하세요</b> <b style={{ color: '#f77c40' }}>
                          {userInfo.name ? userInfo.name + '님' : '회원님'}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.menu}
                  >
                    <MenuItem onClick={() => handlePage("/mypage")}>마이페이지</MenuItem>
                    <MenuItem onClick={() => handlePage("/deliverytracking")}>배송조회</MenuItem>
                    <MenuItem onClick={() => handlePage("/myaddress")}>자주쓰는 경로</MenuItem>
                    <MenuItem onClick={() => handlePage("/shippinglist")}>최근이용경로</MenuItem>
                    <Box height={20} />
                    <MenuItem onClick={() => handlePage("/policy")}>약관/정책보기</MenuItem>
                    <MenuItem onClick={() => logout("/")}>로그아웃</MenuItem>
                  </Menu>
                </>
              )
              : (
                <div className="header_account_btn">
                  <Button className={classes.hover} onClick={() => handlePage('/login')}>
                    <Typography className="txtDesc">로그인</Typography>
                  </Button>
                  <span className="divider" />
                  <Button className={classes.hover} onClick={() => handlePage('/signup')}>
                    <Typography className="txtDesc in">회원가입</Typography>
                  </Button>
                </div>
              )
          }
        </Grid>
        <Grid
          className="receive_btn_content"
          container
          direction="row"
          justify="center"
          alignItems="center"
          onClick={() => handlePage("/quickservice")}
        >
          <Button disabled className="receive_btn">접수/예약하기</Button>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  hover: {
    '&:hover': {
      background: 'transparent'
    }
  },
  loginAppbar: {
    backgroundColor: theme.palette.background.default
  },
  mBtn: {
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  mainAppbar: {
    '& .txtDesc': {
      color: '#fff',

    },
    '& .header_logo': {
      background: `url(${LogoW}) no-repeat center !important;`,
    }
  },
  header: {
    '& .toolbar': {
      padding: 0,
      minWidth: 1067,
    },
    '& .header_logo': {
      margin: '15px 0 0 80px;',
      background: `url(${Logo}) no-repeat center`,
      width: 128,
      height: 80,
      borderRadius: 0,
      marginRight: 74,
    },
    '& .kakao_btn': {
      width: 50,
      height: 35,
      backgroundColor: '#ffe600',
      borderRadius: '50%',
      margin: '0 0 0 5px',
      '& .MuiGrid-root': {
        height: '100%',
      },
      '& img': {
        width: 28,
        height: 28,
      }
    },
    '& .header_main_btn': {
      '& .service': { margin: '0 70px' },
      '& button': {
        padding: 10,
        '& p': {
          width: '100% !important;',
          display: 'inline-block'
        }
      },
    },
    '& .header_btns': {
      flexGrow: 1,
      '& .header_account_btn': {
        marginRight: 80,
        '& .divider': {
          width: 2,
          marginRight: 10,
          marginLeft: 5,
          backgroundColor: '#ff925c'
        },
        '& button': {
          width: 84,
          '& span': {
            width: '84px'
          }
        },
        '& .txtDesc': {
          fontSize: 18,
          fontWeight: 'normal'
        }
      },

      '& .txtDesc': {
        width: 142,
        fontSize: 20,
        fontWeight: '500',
        padding: '0 !important',
        '& .MuiButton-label': {
          alignItems: 'start',
          justifyContent: 'start'
        }
      },
    },
    '& .receive_btn_content': {
      width: 429,
      height: 110,
      borderBottomLeftRadius: 50,
      cursor: 'pointer',
      backgroundColor: '#ff925c',
      '&:hover': {
        backgroundColor: '#ff925c',
        boxShadow: 'none',
      },
      '&:active': {
        backgroundColor: '#ff925c',
      },
      '& button': {
        '&:hover': {
          backgroundColor: '#ff925c'
        },
        '& .MuiTouchRipple-root': {
          '&:active': {
            backgroundColor: '#ff925c',
          },
        }

      }
    },
    '& .receive_btn': {
      padding: 0,
      width: 202,
      fontSize: 24,
      color: '#fff',
      fontWeight: 500,
    }
  },
  iconProfile: {
    width: 40,
    marginRight: 10,
  },
  profile: {
    cursor: 'point',
    marginRight: 30,
  },
  menu: {
    '& .MuiMenu-paper': {
      top: '86px !important;',
      boxShadow: '0 15px 30px 0 rgb(255 146 92 / 16%)'
    }
  },
  hi: {
    cursor: 'pointer'
  }
}));

export default inject('loginStore')(withRouter(observer(Header)));
