import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, TextField, Box, FormControlLabel, Divider } from '@material-ui/core';
import { CustomButton } from '../button';
import GuideMessageM from '../message/GuideMessageM';

const useStyles = makeStyles((theme) => ({
  inputBox: {
    maxWidth: 500,
    margin: '60px 0',
    padding: '0 30px',
  },
}));

function LeaveResult(props) {
  const classes = useStyles();
  const history = useHistory();

  const handlePage = () => {
    history.push('/quickservice');
  }
  return (
    <React.Fragment>
      <Grid item className={classes.title}>
        <GuideMessageM 
          title="더 좋은 서비스를</br>고민하겠습니다."
          mTitle="탈퇴하기"
          resultTitle="더 좋은 서비스를</br>고민하겠습니다."
          subTitle="더 많은 고민과 실행으로 다시 만날 때는</br>
          더 다양한 혜택과 편리한 서비스로 인사드리겠습니다.<br></br>
          <b>그동안 이용해주셔서 감사합니다.</b>"
        />
      </Grid>
      <Box height={50} />
      <Grid className={classes.inputBox} container direction="column" justify="center" alignItems="flex-start">
        <CustomButton
          // disabled
          fullWidth
          variant="contained"
          color="primary"
          onClick={handlePage}
        >홈으로 이동</CustomButton>
      </Grid>
    </React.Fragment>
  );
}

export default LeaveResult;