// import React, { useState, useCallback } from 'react';
import React, { useState } from 'react';
import { IDInquiryForm, IDInquiryResult } from '../components/IDInquiryPage';

function IDInquiryPage(props) {
  const [result, setResult] = useState(false);

  const handleIdentifyBtn = () => {
    setResult(true);
  }
  
  return (
    <React.Fragment>
      {
        result 
          ? (
            <IDInquiryResult />
          )
          : (
            <IDInquiryForm
              handleIdentifyBtn={handleIdentifyBtn}
            />
          )
      }
    </React.Fragment>
  );
};

export default IDInquiryPage;