import { client } from '../lib/client';

// 자주쓰는 경로 리스트
export const myaddressSelAPI = () => (
  client({
    url: '/v1/myaddress/list',
  }, true)
);

// 자주쓰는 경로 등록
export const myaddressRegAPI = (data) => (
  client({
    url: '/v1/myaddress',
    method: 'post',
    data
  }, true)
);

// 자주쓰는 경로 수정
export const myaddressModAPI = (data) => (
  client({
    url: '/v1/myaddress',
    method: 'post',
    data
  }, true)
);

//자주쓰는 경로 삭제
export const myaddressDelAPI = (id) => (
  client({
    url: `/v1/myaddress/${id}`,
    method: 'delete'
  }, true)
);

// 최근이용 경로 리스트
export const latestAddressSelAPI = () => (
  client({
    url: '/v1/latest/list',
  }, true)
);

// 최근이용 경로 즐겨찾기 추가/삭제
export const latestLikeModAPI = (id) => (
  client({
    url: `/v1/latest/${id}`,
    method: 'post'
  }, true)
);

//배송조회
export const deliverySelAPI = () => (
  client({
    url: '/v1/delivery/list',
  }, true)
);

// 사용내역
export const uListSelAPI = (params) => (
  client({
    url: '/v1/use/list',
    params
  }, true)
);

// 회원탈퇴
export const leaveAPI = () => (
  client({
    url: '/v1/quit',
    method: 'put',
  }, true)
)