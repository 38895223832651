import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}


function ServiceTab({ match, history }) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const { path } = match;
    if (path === '/quickservice') setTabValue(0);
    else if (path === '/cargoservice') setTabValue(1);
    else if (path === '/moveservice') setTabValue(2);
  }, [])

  const handleChange = useCallback((e, value) => {
    let path = '';
    if (value === 0) path = '/quickservice';
    else if (value === 1) path = '/cargoservice';
    else if (value === 2) path = '/moveservice';

    history.push(path);
  }, [tabValue]);

  return (
    <React.Fragment>
      <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          textColor="primary"
          selectionFollowsFocus
          variant="fullWidth"
        >
          <Tab label="퀵서비스" {...a11yProps(0)}/>
          <Tab label="화물서비스" {...a11yProps(1)}/>
          <Tab label="이사서비스" {...a11yProps(2)}/>
        </Tabs>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 1440,
    padding: '0 240px',
    '& .MuiTabs-root': {
      borderRadius: '30px 30px 0 30px'
    }
  }
}));

export default withRouter(ServiceTab);