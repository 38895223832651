import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Box, Grid, TextField } from '@material-ui/core';

import GuideMessage from '../message/GuideMessage';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButton } from '../button';
import { PwdRegExp } from '../../lib/util';

const useStyles = makeStyles((theme) => ({
  pwChangeFormWrap: {
    paddingTop: 100, 
    paddingBottom: 508
  },
  inputForm: {
    padding: '60px 0'
  },
}));

function PWChangeForm({ history, loginStore }) {
  let params = {};
  const classes = useStyles();
  const { changePwdCall } = loginStore;

  const onSubmit = () => {
    
    if (params.password1 !== params.password) {
      alert("비밀번호가 일치하지 않습니다.")
      return;
    } 

    if (!PwdRegExp(params.password)) {
      alert("비밀번호는 영문,숫자,특수기호 포함 8자리 이상 입력하세요.");
      return;
    }
    changePwdCall(params, () => history.push('/quickservice'));
    
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    params[name] = value;
  }

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" wrap="nowrap" className={classes.pwChangeFormWrap}>
        <Grid item className="loginFormArea">
          <Grid container direction="column" justify="center" className="narrowFormArea">
            <Grid item>
              <Grid container direction="column" justify="center" alignItems="center">
                <GuideMessage
                  title="비밀번호를 재설정해주세요."
                  resultTitle="비밀번호를 재설정해주세요."
                  mTitle="비밀번호찾기"
                  subTitle="안전한 사용을 위해 영문,숫자,특수기호 조합을 권장합니다."
                />
              </Grid>
            </Grid>
            <Grid item className={classes.inputForm}>
              <Box className="mainFormGroup">
                <TextField
                  fullWidth
                  type="password"
                  placeholder="새로운 비밀번호"
                  variant="outlined"
                  className="formArea"
                  name="password1"
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  type="password"
                  placeholder="새로운 비밀번호 확인"
                  variant="outlined"
                  className="formArea"
                  name="password"
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item>
              <CustomButton
                // disabled
                variant="contained"
                fullWidth
                color="primary"
                onClick={onSubmit}
              >비밀번호 변경완료
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject("loginStore")(withRouter(PWChangeForm));