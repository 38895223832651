import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function MovingTooltipDialog({ open, item, handleClose }) {
  const cs = useStyles();
  const [data, setData] = useState({ title: '', subTitle: '' });
  

  useEffect(() => {
    if (!item) return;
    dataSet();
  }, [item]);

  const dataSet = () => {
    const text = { title: '', subTitle: '' };

    switch (item.value) {
      case "OneRoom":
        text.title = '다바스, 라보, 1t, 4t';
        text.subTitle = '다마스: 소형가전, 생활도구, 작은짐<br/> 라보: 가구+가전 포함, 싱글침대, 냉장고(200L이하), 컴퓨터책상, 1인용 쇼파<br/> 1t, 1.4t: 종합가구 + 가전, 침대(퀸,킹), 세탁기, 건조기, 양문형 냉장고';
        break;
      case "SmallFamilyMoving":
        text.title = '1t, 1.4t, 2.5t';
        text.subTitle = '1t, 1.4t, 2.5t(전용면적 33m2 이하): 종합가구 + 가전, 침대(퀸, 킹), 세탁기, 건조기, 양문형 냉장고';
      break;
      case "HeavyFamilyMoving":
        text.title = '3.5t, 5t이상';
        text.subTitle = '3.5t(전용면적 59m2이하): 종합가구+가전, 침대(퀸, 킹), 세탁기, 건조기, 양문형 냉장고<br/> 5t이상(정용면적 60m2이하): 종합가구+가전, 침대(퀸, 킹), 세탁기, 건조기, 양문형 건조기';
        break;
      case "Office":
        text.title = '라보, 1t, 1.4t, 2.5t, 3.5t, 5t이상';
        text.subTitle = '라보: 냉장고(200L)이하, PC및 모니터, 컴퓨터 책상, 의자<br/> 1t, 1.4t, 2.5t(전용면적 33m2 이하): 회의 테이블, 냉장고(200L이하), PC 및 모니터, 책장, 컴퓨터책상, 의자, 쇼파<br/> 3.5t(전용면적 59m2이하): 회의 테이블, 냉장고(200L이하), PC 및 모니터, 책장, 컴퓨터책상, 의자, 쇼파<br/> 5t이상(전용면적 60m2이상): 회의 테이블, 냉장고(200L이하), PC 및 모니터, 책장, 컴퓨터책상, 의자, 쇼파';
        break;
    };
    setData(text);
  }

  if (!item === null) return;
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{item && item.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography><span className="pColor">추천차량</span></Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{data.title}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop: 10}}>
              <Grid item xs={3}>
                <Typography><span className="pColor">물품예시</span></Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>
                  <span dangerouslySetInnerHTML={{ __html: data.subTitle }} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MovingTooltipDialog;