import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { Box, FormControlLabel, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';

import { CustomButton } from '../components/button';
import { IconCheckLW } from '../static/images/icon';
import CustomCheckbox from '../components/button/CustomCheckbox';
import GuideMessage from '../components/message/GuideMessage';
import { EmailRegExp, PwdRegExp } from '../lib/util';
import { useTheme } from '@material-ui/styles';


const params = {
  email: '', password1: '', password: '', name: ''
};

function SignUpPage({ loginStore, history }) {
  const classes = useStyles();
  const { phoneAuth, emailJoin, phoneCheck, phoneInitial } = loginStore;
  const [trems, setTrems] = useState([false, false, false]);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    return () => {
      phoneInitial();
    }
  }, [])

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    if (name.indexOf("trem") === 0) {
      let temp = trems;

      if (name === "tremAll") {

        if (!trems[0]) temp = [true, true, true];
        else temp = [false, false, false];

      } else if (name === "tremService") {
        temp[1] = !trems[1];
        if (!trems[1]) temp[0] = false;
      } else if (name === "tremPlcy") {
        temp[2] = !trems[2];
        if (!trems[2]) temp[0] = false;
      }


      if (temp[1] && temp[2]) temp[0] = true;

      setTrems([...temp]);

    } else {
      params[name] = value;
    }
  }

  const onSubmit = () => {

    // console.log('%c##### debug-params: ', 'color: #058FD7', params);
    if (!trems[0]) {
      alert("약관에 동의해주세요.");
      return;
    }

    if (params.password1 !== params.password) {
      setErrorMsg("비밀번호가 일치하지 않습니다.")
      return;
    } else if (params.password1 === params.password) {
      setErrorMsg("");
    }

    if (!EmailRegExp(params.email)) {
      alert("이메일을 정확히 입력하세요.");
      return;
    }

    if (!PwdRegExp(params.password)) {
      alert("비밀번호는 영문,숫자,특수기호 포함 8자리 이상 입력하세요.");
      return;
    }
    setErrorMsg("");
    emailJoin(params, history);
  }

  const handleTrem = (e, type) => {
    e.preventDefault();
    history.push({
      pathname: "/policy",
      state: { type }
    });

  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" wrap="nowrap" className={classes.signUpWrap}>
        <Grid item className="loginFormArea">
          <Grid container direction="column" justify="center" className="narrowFormArea">
            <Grid item>
              <Grid container direction="column" justify="center" alignItems="center">
                <GuideMessage
                  title="회원가입"
                  subTitle={matches ? "환영합니다.<br/> 순식간에 끝나는 초간단 회원가입입니다." : "환영합니다. 순식간에 끝나는 초간단 회원가입입니다."}
                />
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: 60 }}>
              <Box className={clsx(classes.formUserInfoWrap, "mainFormGroup")}>
                <TextField
                  name="email"
                  placeholder="아이디(e-mail)"
                  variant="outlined"
                  fullWidth
                  className={clsx("formArea")}
                  onChange={handleChange}
                />
                <TextField
                  name="password1"
                  type="password"
                  placeholder="비밀번호(영문,숫자,특수기호 포함 8자리 이상)"
                  variant="outlined"
                  fullWidth
                  className={clsx("formArea")}
                  onChange={handleChange}
                />
                <TextField
                  name="password"
                  type="password"
                  placeholder="비밀번호 확인"
                  variant="outlined"
                  fullWidth
                  className={clsx("formArea")}
                  onChange={handleChange}
                  error={errorMsg ? true : false}
                  helperText={errorMsg}
                />
                {/* <TextField
                  name="name"
                  placeholder="이름"
                  variant="outlined"
                  fullWidth
                  className={clsx("formArea")}
                  onChange={handleChange}
                /> */}
              </Box>
              <CustomButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={phoneAuth}
                endIcon={
                  phoneCheck && (
                    <Icon>
                      <img alt="" src={IconCheckLW}/>
                    </Icon>
                  )
                }
                >핸드폰 인증하기
              </CustomButton>
              <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes.termsLink}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        name="tremAll"
                        checked={trems[0]}
                        onClick={handleChange}
                      />
                    }
                    label={
                      <Typography className={classes.termsText}>
                        <b>모두 동의하기</b>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item style={{ margin: '10px 0' }}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        name="tremService"
                        checked={trems[1]}
                        onClick={handleChange}
                      />
                    }
                    label={
                      <Typography className={classes.termsText}>
                        <span>서비스 이용약관(필수)</span>
                        <a
                          onClick={(e) => handleTrem(e, "service")}
                          style={{borderBottom: '1px solid'}}
                        >전체보기</a>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        name="tremPlcy"
                        checked={trems[2]}
                        onClick={handleChange}
                      />
                    }
                    label={
                      <Typography className={classes.termsText}>
                        <span>개인정보취급방침(필수)</span>
                        <a
                          onClick={(e) => handleTrem(e, "privacy")}
                          style={{ borderBottom: '1px solid' }}
                        >전체보기</a>
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              <CustomButton
                // disabled
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >회원가입완료</CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  signUpWrap: {
    paddingTop: 100,
    paddingBottom: 150,
  },
  formUserInfoWrap: {
    position: 'relative',
    paddingBottom: 20,
  },
  termsLink: {
    paddingTop: 40,
    paddingBottom: 60,
  },
  termsText: {
    fontSize: 18,
    color: '#555558',
    '& a': {
      marginLeft: 6,
      color: '#f77c40',
      fontSize: '16px'
    },
  },
}));

export default inject('loginStore')(observer(SignUpPage));
