import React from 'react';
import { Grid } from '@material-ui/core';
import { Address, GoodsAndCount, PayBox, ServiceTitle, TotalBox } from '../../components/common/eachService';
import { ImgFurniture, ImgOther, ImgPallet, ImgBoxSmall } from '../../static/images';
import UpAndDown from './UpAndDown';

function CargoServiceView(props) {
  const goodsItem = [
    { 
      idx: 0,
      title: '팔레트',
      tip: '팔레트',
      icon: ImgPallet,
      name: 'paletteCnt',
      total: '',
      tipTitle: '<span class="pColor">다마스, 라보 이용 불가</span>',
      tipContext: '<span class="">T11(1100*1100), T12(1200*10000)</span>'
    },
    { 
      idx: 1,
      title: '박스',
      tip: '박스',
      icon: ImgBoxSmall,
      name: 'boxCnt',
      total: '',
      tipTitle: '<span class="pColor">중박스 기준</span>',
      tipContext: '<span class="pColor">3변의 합이 91cm ~ 120cm</span> 미만의 물품'
    },
    { 
      idx: 2,
      title: '가구/집기',
      tip: '가구/집기',
      icon: ImgFurniture,
      name: 'fuCnt',
      total: '',
      tipTitle: '<span class="pColor">차량 적재규격 확인</span> ',
      tipContext: '<span class="">컴퓨터, 모니터, 컴퓨터책상, 의자, 쇼파, 침대, 소형가전 등</span> '
    },
    { 
      idx: 3,
      title: '기타',
      tip: '기타',
      icon: ImgOther,
      name: 'etcCnt',
      total: '',
      tipTitle: '<span class="pColor">직접 기재</span> ',
      tipContext: '<span class="">케이크, 꽃다발(꽃바구니), 화분, 곡식, 대봉 및 원단 등</span>'
    },
  ]

  return (
    <React.Fragment>
      <div className="servicePageArea">
        <Grid className="addressRoot" container direction="row" justify="flex-start" alignItems="flex-start">
          <div className="addrs">
            <Grid className="left" container direction="column" justify="flex-start" alignItems="flex-start">
              <ServiceTitle
                isBtn
                title="출발지와 도착지를 선택해주세요."
                subTitle="로그인하시면 자주 쓰는 경로와 최근 이용 경로 조회가 가능합니다."
              />
              <Address type="cargo" />
            </Grid>
          </div>
          <div className="count">
            <Grid className="right" container direction="column" justify="flex-start" alignItems="flex-start">
              <ServiceTitle
                title="물품 및 수량"
                subTitle="물음표를 누르시면, 크기와 무게 기준을 알려드려요."
              />
              <GoodsAndCount item={goodsItem} />
            </Grid>
          </div>
        </Grid>
        <UpAndDown />
        <PayBox pageType="cargo" />
      </div>
      <TotalBox />
    </React.Fragment>
  );
}

export default CargoServiceView;