const white = '#fff';
const black = '#000';

const COLORS = {
  sub1: '#E4E4E9',
  sub2: '#CACACF',
  sub3: '#A4A4AC',
  sub4: '#555558',
  basic: '#161617',
  point: '#F77C40',
  main1: '#ff925c',
  main2: '#fef7f5',
}

export default {
  white,
  black,
  primary: {
    main: COLORS.main1,
    dark: COLORS.point,
  },
  text: {
    primary: COLORS.sub4,
    secondary: COLORS.sub3,
  },
  background: {
    default: "#fef7f5"
  },
  border: {
    100: COLORS.sub1,
    200: COLORS.sub2,
    300: COLORS.sub3,
    400: COLORS.sub4,
    500: COLORS.sub5,
  },
  
  tertiary: {
    main: '#343d4d',
    dark: '#242b38',
  },

  border: {
    100: '#ccc',
    200: '#ddd',
    300: '#666',
    400: '#e8e9ef',
    500: '#999',
  },

  bgColor: {
    100: '#f8f9fc',
    //100: 'pink',
    200: '#f1f2f5',
    300: '#f1f3f9',
    400: '#d5d5d5',
    disabled: '#f9f9f9',
    gradient: 'linear-gradient(185deg, rgba(174,242,253,1) 0%, rgba(174,242,253,1) 15%, rgba(122,157,244,1) 100%)',
    selected: '#fbfbf4',
  },
};