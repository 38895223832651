import React, { useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, Grid, TextField, Typography } from '@material-ui/core';

import { ServiceTitle } from '../common/eachService';
import CustomSelectGroup from '../common/CustomSelectGroup';
import { CustomButtonGroup, CustomSelectbox } from '../button';
import CountUpDown from '../common/CountUpDown';
import { timeOptions } from '../../lib/util';
import { Calendar } from '../common';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '60px 80px 60px 80px',
    '& .csgSelectbox': {
      width: 449,
    },
    '& .mainFormGroup': {
      width: 449,
      '& .MuiInputBase-root': {
        boxShadow: 'none',
      },
      '& input': {
        border: 'solid 1px #e4e4e9',
        backgroundColor: '#ffffff',
      }
    },
    '& .upmargin': {
      marginTop: 50,
      marginBottom: 20,
    },
    '& .calendar': {
      width: 189
    },
    '& .time': {
      width: 90,
    }
  },
  mb30: {
    marginBottom: 30
  }
}));
function UpAndDown({ qsStore }) {
  const params = {};
  const classes = useStyles();
  const { handleOptions, options } = qsStore;

  useEffect(() => {
    handleOptions('vehicleType', '');
    handleOptions("loadingMethod", "Direct");
    handleOptions("dropMethod", "Direct");
  }, [])

  let rt = ["", "", ""];
  const vehicleType = [
    { title: '차량선택', value: '' },
    { title: '상담 후 선택', value: 'dd' },
    { title: '다마스', value: 'Damas' },
    { title: '라보', value: 'Labo' },
    { title: '1t', value: 'OntT' },
    { title: '1.4t', value: 'OntPointFourT' },
    { title: '2.5t', value: 'TwoPointFiveT' },
    { title: '3.5t', value: 'ThreePointFiveT' },
    { title: '5t', value: 'FiveT' },
  ];

  const upDownWayItems = [
    { title: '직접', value: 'Direct' },
    { title: '고객과 같이', value: 'WithC', tip: '별도 요금이 발생합니다.'},
    { title: '기사님 혼자', value: 'Alone', tip: '별도 요금이 발생합니다.'},
  ];

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    params[name] = value;
    if (name === 'minute' || name === 'hour' || name === 'moveDate') {
      let time = moment(options.reservationTime ? options.reservationTime : new Date());
      if (name === 'moveDate') time = moment(value);

      if (params.minute) time.minute(parseInt(params.minute));
      else time.minute(0);

      if (params.hour) time.hour(parseInt(params.hour));
      else time.hour(0);

      name = "reservationTime";
      value = new Date(time.format('YYYY-MM-DD HH:mm')).getTime();
    }

    if (rt[0] && rt[1] && rt[2]) {
      name = "reservationTime";
      value = new Date(moment(`${rt[0]} ${rt[1]}:${rt[2]}`).format('YYYY-MM-DD HH:mm'));
    } 
    
    handleOptions(name, value);
  }


  return (
    <Grid className={clsx('serviceBox', classes.root)} container direction="row" justify="flex-start" alignItems="flex-start">
      <Grid item xs={6}>
        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
          <Grid item>
            <ServiceTitle
              title="차량 및 상하차 시간"
              subTitle="선택방법에 따라 추가될 수 있습니다."
            />
          </Grid>
          <Grid item className={clsx('selectBox', 'upmargin')} >
            <CustomSelectGroup 
              title="차량 종류" 
              options={vehicleType}
              value={options.vehicleType}
              name="vehicleType"
              handleChange={handleChange}
              tip={true}
            />
          </Grid>
          <Grid item className={classes.mb30}>
            <Grid className="" container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 47 }} variant="subtitle2"><b>이사날짜</b></Typography>
              <Grid item className="calendar"> 
                <Calendar  
                  name="moveDate"
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item style={{ margin: '0 16px' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <FormControl className="time" fullWidth={false}>
                    <CustomSelectbox 
                      variant="filled" 
                      name="hour"
                      onChange={handleChange}
                      inputProps={{ 'title': '시간 선택' }}
                    >
                      <option>00</option>
                      {
                        timeOptions('time').map((item) => (
                          <option key={item.value} value={item.value}>{item.title}</option>
                        ))
                      }
                    </CustomSelectbox>
                  </FormControl>
                  <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500 }}>시</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <FormControl className="time" fullWidth={false}>
                    <CustomSelectbox 
                      variant="filled" 
                      name="minute"
                      onChange={handleChange}
                      inputProps={{ 'title': '분 선택' }}
                    >
                      <option value="00">00</option>
                      {
                        timeOptions('').map((item) => (
                          <option key={item.value} value={item.value}>{item.title}</option>
                        ))
                      }
                    </CustomSelectbox>
                  </FormControl>
                  <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500 }}>분</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid className="" container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 45 }} variant="subtitle2"><b>요청사항</b></Typography>
              <Box className="mainFormGroup">
                <TextField
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  name="requests"
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
          <Grid item>
            <ServiceTitle
              title="상/하차 방법 및 추가 옵션"
              subTitle="선택방법에 따라 추가될 수 있습니다."
            />
          </Grid>
          <Grid item>
            <Grid className="upmargin" container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 20 }} variant="subtitle2"><b>상차방법</b></Typography>
              <CustomButtonGroup 
                name="loadingMethod"
                items={upDownWayItems}
                defaultValue={options.loadingMethod}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid className={classes.mb30} container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 20 }} variant="subtitle2"><b>하차방법</b></Typography>
              <CustomButtonGroup 
                name="dropMethod"
                items={upDownWayItems}
                defaultValue={options.dropMethod}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.mb30}>
            <Grid className="" container direction="row" justify="flex-start" alignItems="flex-start">
              <Typography style={{ paddingRight: 20 }} variant="subtitle2"><b>추가옵션</b></Typography>
              <Grid item>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                  <Grid item style={{ marginBottom: 20 }}>
                    <CountUpDown
                      name="passengerCnt"
                      title="차량동승"
                      handleChange={handleChange}
                      tip="최대1명 , 별도 요금이 발생합니다."
                    />
                  </Grid>
                  <Grid item>
                    <CountUpDown
                      name="workerCnt"
                      title="인부추가"
                      handleChange={handleChange}
                      tip="별도 요금이 발생합니다."
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default inject('qsStore')(observer(UpAndDown));