import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { IconSnsBlog, IconSnsFacebook, IconSnsInstagram, IconSnsKakao } from '../../static/images/icon';

const useStyles = makeStyles((theme) => ({
  footer: {
    left: 0,
    bottom: 0,
    width: '100%',
    minHeight: 100,
    // borderTop: `1px solid #e4e4e9`,
    backgroundColor: '#fff',
  },
  notice_area: {
    height: 80,
    width: '100%',
    backgroundColor: '#fafafb',

    '& .sns_btn': {
      '& button': {
        width: 26,
        height: 26,
        margin: '0 20px'
      },
      '& .btn_facebook': { background: `url(${IconSnsFacebook}) no-repeat center`, },
      '& .btn_kakao': { background: `url(${IconSnsKakao}) no-repeat center`, margin: '0 30px' },
      '& .btn_blog': { background: `url(${IconSnsBlog}) no-repeat center`, marginRight: 30 },
      '& .btn_instagram': { background: `url(${IconSnsInstagram}) no-repeat center` },
    },
  },
  footer_inner: {
    padding: '40px 20px 40px 20px',
  }
}));

function FooterM(props) {
  const cs = useStyles();
  const handlePage = (path) => {
    window.open(path, '_blank');
  }
  
  return (
    <Box component="footer" className={cs.footer}>
      <Grid container direction="row" justify="center" alignItems="center" className={cs.notice_area}>
        <Grid className="sns_btn main_foot" container direction="row" justify="center" alignItems="center">
          <IconButton className="btn_facebook" onClick={() => handlePage("https://www.facebook.com/quinors5480/?")}/>
          <IconButton className="btn_kakao" onClick={() => handlePage("https://pf.kakao.com/_AsExjC")}/>
          <IconButton className="btn_blog" onClick={() => handlePage("https://blog.naver.com/shinhan5480")}/>
          <IconButton className="btn_instagram" onClick={() => handlePage("https://www.instagram.com/quinors_sh/")} />
        </Grid>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={cs.footer_inner}>
        <Typography variant="body2" color="textSecondary" align="left"><b>신한로직스 대표</b> 유재영</Typography>
        <Typography variant="body2" color="textSecondary" align="left"><b>사업자번호</b> 436-86-00203</Typography>
        <Typography variant="body2" color="textSecondary" align="left"><b>통신판매업</b> 제2021-서울중구-1038호</Typography>
        <Typography variant="body2" color="textSecondary" align="left"><b>화물자동차운송주선사업허가증</b> 제020134호</Typography>
        <Box height={20} />
        <Typography variant="body2" color="primary" align="left"><span className="pColor"><b>02-1588-5480</b></span></Typography>
        <Typography variant="body2" color="textSecondary" align="left">
          <b>주소</b> 서울시 중구 통일로 86 바비엥3 407호
        </Typography>
        <Typography variant="body2" color="textSecondary" align="left">
          quinors@quinors.com
        </Typography>
        <Box height={20}/>
        <Typography variant="body2" color="textSecondary" align="left">
          Copyright©QUINORS All rights reserved
        </Typography>
      </Grid>
    </Box>
  );
}

export default FooterM;