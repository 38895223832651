import { makeAutoObservable, action, observable } from 'mobx';
import {
  myaddressRegAPI, myaddressSelAPI,
  latestAddressSelAPI, deliverySelAPI,
  uListSelAPI, myaddressDelAPI, myaddressModAPI,
  latestLikeModAPI, leaveAPI
} from '../api/mypage';

import { PhoneRegExp } from '../lib/util';

export default class MyPageStore {
  root;
  cmStore;
  myAddress = [];
  latestAddress = [];
  delivery = [];
  useList = [];

  constructor(root) {
    this.root = root;
    this.cmStore = this.root.cmStore;
    makeAutoObservable(
      this, {
        myAddress: observable,
        latestAddress: observable,
        delivery: observable,
        useList: observable,
        myAddressSel: action,
        myAddressReg: action,
        myAddressMod: action,
        latestAddressSel: action,
        // useListSel: action,
      }
    )
  }

  // 자주쓰는 경로 목록 조회
  myAddressSel = async() => {
    try {
      const resp = await myaddressSelAPI();
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.myAddress = data;

    } catch (error) {
      console.log("### myAddressSel error: ", error);
    }
  };

  // 자주쓰는 경로 등록
  myAddressReg = async (params, history) => {
    const { toAddress, toBcode, fromAddress, fromBcode } = this.root.cmStore;
    params["startAddr"] = toAddress;
    params["startPointCode"] = toBcode;
    params["endAddr"] = fromAddress;
    params["endPointCode"] = fromBcode;

    const {
      startAddr, startAddrDtl, endAddr, endAddrDtl,
      startNm, startTel, endNm, endTel, addressName
    } = params;

    let msg = "";
    if (!addressName) msg = "경로명을 입력하세요."
    else if (!startAddr) msg = "출발지 주소를 입력하세요.";
    else if (!startAddrDtl) msg = "출발지 상세 주소를 입력하세요.";
    else if (!startNm) msg = "보내는분 이름을 입력하세요.";
    else if (!startTel) msg = "보내는분 연락처를 입력하세요.";
    else if (!endAddr) msg = "도착지 주소를 입력하세요.";
    else if (!endAddrDtl) msg = "도착지 상세 주소를 입력하세요.";
    else if (!endNm) msg = "받는분 이름을 입력하세요.";
    else if (!endTel) msg = "받는분 연락처를 입력하세요.";
    else if (!PhoneRegExp(startTel)) msg = "보내는분 연락처를 정확히 입력하세요.";
    else if (!PhoneRegExp(endTel)) msg = "받는분 연락처를 정확히 입력하세요.";

    if (msg) {
      alert(msg);
      return;
    }

    try {
      const resp = await myaddressRegAPI(params);
      const { status } = resp;
      if (status !== 201) throw resp;

      alert("등록 되었습니다");
      history.push('/myaddress');
    } catch (error) {
      console.log("### myAddressReg error: ", error);
    }
  }


  // 자주쓰는 경로 수정
  myAddressMod = async (params) => {
    const { toAddress, toBcode, fromAddress, fromBcode } = this.root.cmStore;

    params["startAddr"] = toAddress;
    params["startPointCode"] = toBcode;
    params["endAddr"] = fromAddress;
    params["endPointCode"] = fromBcode;


    const {
      startAddr, startAddrDtl, endAddr, endAddrDtl,
      startNm, startTel, endNm, endTel, addressName
    } = params;

    let msg = "";
    if (!addressName) msg = "경로명을 입력하세요."
    else if (!startAddr) msg = "출발지 주소를 입력하세요.";
    else if (!startAddrDtl) msg = "출발지 상세 주소를 입력하세요.";
    else if (!startNm) msg = "보내는분 이름을 입력하세요.";
    else if (!startTel) msg = "보내는분 연락처를 입력하세요.";
    else if (!endAddr) msg = "도착지 주소를 입력하세요.";
    else if (!endAddrDtl) msg = "도착지 상세 주소를 입력하세요.";
    else if (!endNm) msg = "받는분 이름을 입력하세요.";
    else if (!endTel) msg = "받는분 연락처를 입력하세요.";
    else if (!PhoneRegExp(startTel)) msg = "보내는분 연락처를 정확히 입력하세요.";
    else if (!PhoneRegExp(endTel)) msg = "받는분 연락처를 정확히 입력하세요.";

    if (msg) {
      alert(msg);
      return;
    }

    try {
      const resp = await myaddressModAPI(params);
      const { status } = resp;
      if (status !== 201) throw resp;
      alert("수정되었습니다.");
    } catch (error) {
      console.log('%c##### Error-myAddressMod: ', 'color: red', error);
    }
  }

  // 자주쓰는 경로 삭제
  myAddressDel = async (id) => {
    try {
      const resp = await myaddressDelAPI(id);
      const { status } = resp;
      if (status !== 200) throw resp;
      alert("삭제 되었습니다.");
      window.location.reload();
    } catch (error) {
      console.log('%c##### Error-myAddressDel: ', 'color: red', error);
    }
  }

  // 최근 이용 경로 목록
  latestAddressSel = async () => {
    try {
      const resp = await latestAddressSelAPI();
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.latestAddress = data;
    } catch (error) {
      console.log("### latestAddressSel error: ", error);
    }
  };

  // 최근 이용 경로 즐겨찾기 추가, 삭제
  latestAddressListMod = async (item) => {
    // console.log(JSON.parse(JSON.stringify(item)));
    if (item.myAddressKey) {
      const result = window.confirm("정말 삭제 하시겠습니까?");
      if (!result) {
        return;
      }
    }


    try {
      const resp = await latestLikeModAPI(item.orderKey);
      const { status } = resp;
      if (status !== 200) throw resp;
      this.latestAddressSel();
      if (item.myAddressKey) {
        alert("자주쓰는 경로가 해제되었습니다.");
      } else {
        alert("자주쓰는 경로로 등록 되었습니다.");
      }
    } catch (error) {
      console.log("%c##### Error-latestAddressListMod: ", 'color: red', error);
    }
  }

  // 배송조회
  deliverySel = async () => {
    try {
      const resp = await deliverySelAPI();
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.delivery = data;
    } catch (error) {
      console.log("### deliverySel error: ", error);
    }
  }

  // 사용내역
  useListSel = async (params) => {
    try {
      const resp = await uListSelAPI(params);
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.useList = data;
    } catch (error) {
      console.log("### useSel error: ", error);
    }
  }

}
