import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { CustomButtonGroup, CustomCheckbox, CustomSelectbox } from '../button';
import { Calendar, CountUpDown } from '../common';
import { timeOptions } from '../../lib/util';
import VehicleTooltipDialogM from '../common/dialog/VehicleTooltipDialogM';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 30,
  },
  selectAear: {
    paddingBottom: 10,
  },
  selbox: {
    paddingLeft: 10,
  },
  countTitle: {
    width: 87,
  },
  path: {
    maxWidth: '70%',
    flexBasis: '70%',
    '& button': {
      borderRadius: 8,
      width: 95,
      margin: '0 2px',
    }
  },
  pathG: {
    maxWidth: '70%',
    flexBasis: '70%',
    '& button': {
      borderRadius: 8,
      width: 105,
      margin: '0 2px',
      marginBottom: 8,
      '&  p': {
        fontSize: 14
      }
    }
  },
  input: {
    '& .MuiOutlinedInput-root': {
      marginBottom: 0
    }
  },
  paddingT: {
    paddingTop: 30
  },
  updownArea: {
    marginBottom: 10,
    '& .countUpDownTitle': {
      width: 78
    },
    '& button': {
      width: '28px !important;',
      height: '28px !important;'
    },
    '& p': {
      fontWeight: 'bold !important'
    }
  }
}));
const params = {};

function UpAndDownM({ qsStore }) {
  const cs = useStyles();
  const { handleOptions, options, orderCalAPI } = qsStore;
  const [dialog, setDialog] = useState(false);
  const [upTime, setUpTime] = useState(false);
  const [downTime, setDownTime] = useState(false);
  const [goodsTemp, setGoodsItem] = useState("OntT");

  useEffect(() => {
    handleOptions('vehicleType', 'OntT');
    handleOptions("loadingMethod", "Direct");
    handleOptions("dropMethod", "Direct");
    handleOptions("route", "OneWay");
    handleOptions("vehicleOptionType", "Cargo");
    params.vehicleType = "OntT";
  }, []);

  useEffect(() => {
    if (options.paletteCnt > 2) {
      handleOptions("vehicleType", "TwoPointFiveT")
    }
    if (options.paletteCnt > 3) {
      handleOptions("vehicleType", "ThreePointFiveT")
    }
    if (options.paletteCnt > 4) {
      handleOptions("vehicleType", "FiveT")
    }
    if (options.paletteCnt > 5) {
      handleOptions("vehicleType", "EightT")
    }
    if (options.paletteCnt > 16) {
      handleOptions("vehicleType", "ElevenT")
    }
  }, [options.paletteCnt])

  const vehicleType = [
    { title: '차량종류선택', value: '' },
    { title: '1t트럭', value: 'OntT', disabled: options.paletteCnt > 2 },
    { title: '1.4t트럭', value: 'OntPointFourT', disabled: options.paletteCnt > 2 },
    { title: '2.5t트럭', value: 'TwoPointFiveT', disabled: options.paletteCnt > 3 },
    { title: '3.5t트럭', value: 'ThreePointFiveT', disabled: options.paletteCnt > 4 },
    { title: '5t트럭', value: 'FiveT', disabled: options.paletteCnt > 4 },
    { title: '8t트럭', value: 'EightT', disabled: options.paletteCnt > 16 },
    { title: '11t트럭', value: 'ElevenT' },
  ];

  const options2 = [
    { title: '차량종류선택', value: '' },
    { title: '카고(기본)', value: 'Cargo' },
    { title: '호루', value: 'Whistle' },
    { title: '윙바디', value: 'WingBody' },
    { title: '리프트', value: 'Lift' },
    { title: '리프트윙', value: 'LiftWing' },
    { title: '냉장탑', value: 'RefrigerationTower' },
    { title: '탑', value: 'FreezingTower' },
    { title: '플러스 카고', value: 'PlusCargo' },
    { title: '플러스 윙바디', value: 'PlusWingBody' },
    { title: '플러스 축사', value: 'PlusRotor' },
    { title: '플러스 축차 윙바디', value: 'PlusSequentialWingBody' },
  ];

  const upDownTimeItems = [
    { title: '바로 상차', value: 'now'},
    { title: '예약', value: 'book'},
  ];

  const upDownTimeItems2 = [
    { title: '바로 하차', value: 'now'},
    { title: '예약', value: 'book'},
  ];

  const upDownWayItems = [
    { title: '직접', value: 'Direct' },
    { title: '고객과 같이', value: 'WithC', tip: '별도 요금이 발생합니다.' },
    { title: '기사님 혼자', value: 'Alone', tip: '별도 요금이 발생합니다.' },
  ];

  const deliveryItem = [
    { title: '편도', value: 'OneWay'},
    { title: '왕복', value: 'RoundTrip'},
  ];

  const handleChange = async (e, checked) => {
    let name = e.target.name;
    let value = e.target.value;
    const id = e.target.id;


    if (name === "upTime") {
      if (value === 'book') {
        setUpTime(true);
      } else {
        setUpTime(false);
        handleOptions("reservationTime", "");
      }
    } else if (name === "downTime") {
      if (value === 'book') {
        setDownTime(true);
      } else {
        setDownTime(false);
        handleOptions("reservationDownTime", "");
      }
    } else {
      
      params[name] = value;
    }

    if (name === 'minute' || name === 'hour' || name === 'moveDate') {
      let time = moment(options.reservationTime ? options.reservationTime : new Date());
      if (name === 'reservationTime' || id === 'reservationTime') {
        time = moment(options.reservationTime ? options.reservationTime : new Date());
      } else if (name === 'reservationDownTime' || id === 'reservationDownTime') {
        time = moment(options.reservationDownTime ? options.reservationDownTime : new Date());
      }
      
      if (name === 'moveDate') time = moment(value);

      if (params.minute) time.minute(parseInt(params.minute));
      else time.minute(0);

      if (params.hour) time.hour(parseInt(params.hour));
      else time.hour(0);

      if (id === 'reservationTime') {
        name = "reservationTime";
      } else {
        name = "reservationDownTime";
      }
      
      value = new Date(moment(time)).getTime();
    }
    
    if (name === 'isExpress') {
      if (checked) value = 'Y';
      else value = 'N';
    }

    await handleOptions(name, value);
    await orderCalAPI();
  }

  const handleTooltipOpen = () => {
    if (!params.vehicleType) return;
    setGoodsItem(params.vehicleType);
    handleOnOff();
  }

  const handleOnOff = () => {
    setDialog(!dialog);
  }

  return (
    <Grid className={cs.root} container direction="column" justify="flex-start" alignItems="flex-start">
      <VehicleTooltipDialogM open={dialog} item={goodsTemp} handleClose={handleOnOff} />
      <Grid className={cs.title} container direction="column" justify="center" alignItems="center">
        <Typography variant="body1" color="primary">
          <b>차량 및 상하차 시간</b>
        </Typography>
        <Typography variant="body2" color="textPrimary" align="center">
          <span className="pColor"><b>TIP.</b></span>
          선택방법에 따라 비용이 추가될 수 있습니다.
        </Typography>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>차량종류</b></Typography>
          <IconButton
            className={clsx('questionMark', cs.tipIcon)}
            onClick={() => handleTooltipOpen('open')}
          />
        </Grid>
        <Grid item xs={8} className={cs.selbox}>
          <FormControl fullWidth={true}>
            <CustomSelectbox
              variant="filled"
              inputProps={{ 'title': '수량 선택' }}
              name="vehicleType"
              value={options.vehicleType}
              onChange={handleChange}
            >
              {
                vehicleType.map((item) => (
                  <option key={item.value} value={item.value}>{item.title} </option>
                ))
              }
            </CustomSelectbox>
          </FormControl>
        </Grid>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>차량옵션</b></Typography>
        </Grid>
        <Grid item xs={8} className={cs.selbox}>
          <FormControl fullWidth={true}>
            <CustomSelectbox
              variant="filled"
              inputProps={{ 'title': '수량 선택' }}
              name="vehicleOptionType"
              value={options.vehicleOptionType}
              onChange={handleChange}
              // pd={47}
            >
              {
                options2.map((item) => (
                  <option key={item.value} value={item.value}>{item.title} </option>
                ))
              }
            </CustomSelectbox>
          </FormControl>
        </Grid>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>상차시간</b></Typography>
        </Grid>
        <Grid item className={clsx(cs.selbox, cs.path)}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <CustomButtonGroup
              items={upDownTimeItems}
              name="upTime"
              defaultValue="now"
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
          {
            upTime && (
              <>
                <Grid style={{marginTop: 10}} className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
                  <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
                    <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
                      <Typography variant="body2"></Typography>
                    </Grid>
                    <Grid item xs={8} className={cs.selbox}>
                      <Calendar
                        name="moveDate"
                        id="reservationTime"
                        handleChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
                  <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
                  </Grid>
                  <Grid item xs={8} className={cs.selbox}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                      <FormControl className="time" fullWidth={false}>
                        <CustomSelectbox
                          variant="filled"
                          inputProps={{ 'title': '시간 선택' }}
                          name="hour"
                          id="reservationTime"
                          onChange={handleChange}
                        >
                          <option>00</option>
                          {
                            timeOptions('time').map((item) => (
                              <option key={item.value} value={item.value}>{item.title}</option>
                            ))
                          }
                        </CustomSelectbox>
                      </FormControl>
                      <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500, margin: '0 10px' }}>시</Typography>
                      <FormControl className="time" fullWidth={false}>
                        <CustomSelectbox
                          variant="filled"
                          inputProps={{ 'title': '분 선택' }}
                          name="minute"
                          id="reservationTime"
                          onChange={handleChange}
                          inputProps={{ 'title': '분 선택' }}
                        >
                          <option value="00">00</option>
                          {
                            timeOptions('').map((item) => (
                              <option key={item.value} value={item.value}>{item.title}</option>
                            ))
                          }
                        </CustomSelectbox>
                      </FormControl>
                      <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500, margin: '0 10px' }}>분</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )
          }
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>하차시간</b></Typography>
        </Grid>
        <Grid item className={clsx(cs.selbox, cs.path)}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <CustomButtonGroup
              name="downTime"
              defaultValue="now"
              items={upDownTimeItems2} 
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
        {
          downTime && (
            <>
              <Grid style={{marginTop: 10}} className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
                <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
                  <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
                    <Typography variant="body2"></Typography>
                  </Grid>
                  <Grid item xs={8} className={cs.selbox}>
                    <Calendar
                      name="moveDate"
                      handleChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
                <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
                </Grid>
                <Grid item xs={8} className={cs.selbox}>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <FormControl className="time" fullWidth={false}>
                      <CustomSelectbox
                        variant="filled"
                        inputProps={{ 'title': '시간 선택' }}
                        name="hour"
                        onChange={handleChange}
                      >
                        <option>00</option>
                        {
                          timeOptions('time').map((item) => (
                            <option key={item.value} value={item.value}>{item.title}</option>
                          ))
                        }
                      </CustomSelectbox>
                    </FormControl>
                    <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500, margin: '0 10px' }}>시</Typography>
                    <FormControl className="time" fullWidth={false}>
                      <CustomSelectbox
                        variant="filled"
                        inputProps={{ 'title': '분 선택' }}
                        name="minute"
                        onChange={handleChange}
                        inputProps={{ 'title': '분 선택' }}
                      >
                        <option value="00">00</option>
                        {
                          timeOptions('').map((item) => (
                            <option key={item.value} value={item.value}>{item.title}</option>
                          ))
                        }
                      </CustomSelectbox>
                    </FormControl>
                    <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500, margin: '0 10px' }}>분</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )
        }
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>요청사항</b></Typography>
        </Grid>
        <Grid item xs={8} className={cs.selbox}>
          <TextField
            className={clsx(cs.input, "formArea nameText textBox")}
            variant="outlined"
            name="requests"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid className={clsx(cs.title, cs.paddingT)} container direction="column" justify="center" alignItems="center">
        <Typography variant="body1" color="primary">
          <b>상/하차 방법 및 추가 옵션</b>
        </Typography>
        <Typography variant="body2" color="textPrimary" align="center">
          <span className="pColor"><b>TIP.</b></span>
          선택방법에 따라 비용이 추가될 수 있습니다. 
        </Typography>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>상차방법</b></Typography>
        </Grid>
        <Grid item className={clsx(cs.selbox, cs.pathG)}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <CustomButtonGroup
              name="loadingMethod"
              items={upDownWayItems} 
              defaultValue={options.loadingMethod}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>하차방법</b></Typography>
        </Grid>
        <Grid item className={clsx(cs.selbox, cs.pathG)}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <CustomButtonGroup
              name="dropMethod"
              items={upDownWayItems} 
              defaultValue={options.dropMethod}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>배송종류</b></Typography>
        </Grid>
        <Grid item className={clsx(cs.selbox, cs.pathG)}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <CustomButtonGroup
              name="route"
              items={deliveryItem} 
              defaultValue={options.route}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>기타</b></Typography>
        </Grid>
        <Grid item className={clsx(cs.selbox, cs.pathG)}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <FormControlLabel
              control={
                <CustomCheckbox 
                  name="isExpress"
                  onChange={handleChange}
                />
              }
              label={
                <Typography variant="body2" color="textPrimary">
                  급송
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={cs.updownArea} container direction="row" justify="flex-start" alignItems="flex-start">
        <CountUpDown 
          name="passengerCnt"
          title="차량동승" 
          handleChange={handleChange}
          // tip="최대1명 , 별도 요금이 발생합니다."
        />
      </Grid>
      <Grid className={cs.updownArea} container direction="row" justify="flex-start" alignItems="flex-start">
        <CountUpDown 
          name="workerCnt"
          title="인부추가" 
          handleChange={handleChange}
          // tip="별도 요금이 발생합니다."
        />
      </Grid>
    </Grid>
  );
}

export default inject('qsStore')(observer(UpAndDownM));
