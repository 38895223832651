import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, Grid, RadioGroup, Typography } from '@material-ui/core';

import { CustomSelectbox } from '../../button';
import { countOptions } from '../../../lib/util';
import CustomRadio from '../../button/CustomRadio';

const useStyles = makeStyles((theme) => ({
  numberOfFloors: {
    '& .floorSelectbox': {
      width: 150,
      marginRight: 20,
    }
  }
}));

function NumberOfFloors({ style, qsStore, name, subName }) {
  const classes = useStyles();
  const { handleOptions } = qsStore;
  const [radio, setRadio] = useState("");
  const [count, setCount] = useState("");

  const handleChange = (e, checked) => {
    let value = e.target.value;
    let n = e.target.name;
    setRadio(value);
    handleOptions(n, value);
  };

  const handleCount = (e) => {
    let v = e.target.value;
    let n = e.target.name;
    setCount(v);
    handleOptions(n, v);
  }

  
  return (
    <Grid style={style} className={classes.numberOfFloors} container direction="row" justify="flex-start" alignItems="center">
      <Grid item>
        <FormControl fullWidth={false}>
          <CustomSelectbox 
            className="floorSelectbox" 
            variant="filled" 
            name={subName}
            value={count}
            inputProps={{ 'title': '수량 선택' }}
            onChange={handleCount}
          >
            <option value="">층수</option>
            {
              countOptions(200).map((item) => (
                <option key={item.value} value={item.value}>{item.title}</option>
              ))
            }
          </CustomSelectbox>
        </FormControl>
      </Grid>
      <RadioGroup row defaultValue="rdoLabel_1">
        <FormControlLabel
          value="Elevator"
          name={name}
          control={
            <CustomRadio 
              checked={radio === 'Elevator'}
              onChange={handleChange} 
            />
          }
          label={
            <Typography variant="body1" color="textPrimary">엘레베이터</Typography>
          }
          classes={{ label: 'label' }}
          className={classes.formControlLabel}
          style={{ marginRight: 10 }}
        />
        <FormControlLabel
          value="Stairs"
          name={name}
          control={
            <CustomRadio 
              checked={radio === 'Stairs'}
              onChange={handleChange} />
            }
          label={
            <Typography variant="body1" color="textPrimary">계단</Typography>
          }
          classes={{ label: 'label' }}
          className={classes.formControlLabel}
          style={{ marginRight: 10 }}
        />
      </RadioGroup>
    </Grid>
  );
}

export default inject('qsStore')(NumberOfFloors);