import React from 'react';
import clsx from 'clsx';
import { Checkbox, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import { IconCheckL } from '../../static/images/icon';

const useStyles = makeStyles(theme => ({
  root: {
    '& .icoCheckbox': {
      display: 'block',
      border: `1px solid #e4e4e9`,
      borderRadius: 30,
      backgroundColor: '#fff',
      width: 26,
      height: 26,
      boxShadow: 'none',
      '&.icoCheckboxChecked': {
        borderColor: theme.palette.primary.main,
        '& .icon': {
          display: 'flex',
          color: theme.palette.primary.main,
        },
        '& img': {
          width: '100%'
        }
      },
    },
    '&.disabled .icoCheckbox': {
      backgroundColor: theme.palette.bgColor.disabled,
      '&.icoCheckboxChecked': {
        borderColor: theme.palette.border[100],
        '& .icon': {
          color: theme.palette.border[100],
        }
      }
    },
    '&.invert': {
      '& .icoCheckbox': {
        '&.icoCheckboxChecked': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          '& .icon': {
            color: theme.palette.white,
          }
        }
      },
      '&.disabled .icoCheckbox': {
        backgroundColor: theme.palette.bgColor.disabled,
        '&.icoCheckboxChecked': {
          borderColor: theme.palette.border[100],
          backgroundColor: theme.palette.border[100],
          '& .icon': {
            color: theme.palette.bgColor.disabled,
          }
        }
      },
    },
  },
}));

const CustomCheckbox = props => {
  const classes = useStyles();

  const { className, ...others } = props;

  return (
    <Checkbox
      icon={<span className="icoCheckbox"></span>}
      checkedIcon={
        <span className={clsx("icoCheckbox", "icoCheckboxChecked")}>
          <Icon className="icon">
            <img src={IconCheckL}/>
          </Icon>
        </span>
      }
      color="default"
      classes={{ disabled: 'disabled' }}
      className={clsx(classes.root, className)}
      {...others}
    />
  );
};

export default CustomCheckbox;