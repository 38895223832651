import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Input, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textBox: {
    width: '100%',
    height: 54,
    borderRadius: '0 8px 8px 0',
    boxShadow: '0 5px 10px 0 rgba(247, 124, 64, 0.16)',
    backgroundColor: '#ffffff',
    '& .title': {
      float: 'left',
      height: '100%',
      width: 110,
      backgroundColor: '#ff925c',
      borderRadius: '8px 0 0 8px',
      marginRight: 10,
      '& .MuiGrid-root': { height: '100%' },
      '& p': { color: 'white' }
    },
    '& .subTitle': {
      height: '100%',
      display: 'flex',
      '& p': { padding: 13 }
    }
  },
  input: {
    height: '100%',
  },
  sInput: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
}));

function CustomTextBox({ title, subTitle, type, name, handleChange, defaultValue }) {
  const cs = useStyles();
  return (
    <div className={cs.textBox}>
      <div className="title">
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="body2" color="textPrimary" align="center" style={{fontWeight: 'bold'}}>{title}</Typography>
        </Grid>
      </div>
      <div className="subTitle">
        {
          type === 'input'
            ? (
              <Input 
                className={cs.input} 
                placeholder={subTitle} 
                name={name} 
                onChange={handleChange}  
                disableUnderline 
                defaultValue={defaultValue}
                inputProps={{ 'aria-label': 'description' }} 
              />
            )
            : <Typography variant="body2" color="textPrimary" align="left" className={cs.sInput}>{subTitle}</Typography>
        }
      </div>
    </div>
  );
}

export default CustomTextBox;