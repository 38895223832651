import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { CustomButton } from '../components/button';
import GuideMessage from '../components/message/GuideMessage';

function ChangeResult({ title = "완료 되었습니다.", subTitle = "" }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [t, setTitle] = useState(title);
  const [s, setSubTitle] = useState(subTitle);

  useEffect(() => {
    const { state } = location;
    if (state) {
      // console.log({ state });m
      if (state.subTitle) setSubTitle(state.subTitle);
      if (state.title) setTitle(state.title);
    }
    return () => {
      setTitle("");
      setSubTitle("");
    }
  }, []);

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" wrap="nowrap" className={classes.signUpSuccess}>
        <Grid item className={classes.signUpForm}>
          <Grid container direction="column" justify="center" className={clsx("narrowFormArea", classes.textField)}>
            <Grid item>
              <Grid container direction="column" justify="center" alignItems="center">
                <GuideMessage
                  title={t}
                  resultTitle=""
                  subTitle={s}
                />
              </Grid>
            </Grid>
          </Grid>
          <CustomButton
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => { history.push('/') }}
          >
          확인
          </CustomButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  signUpSuccess: {
    paddingTop: 200,
    paddingBottom: 150
  },
  signUpForm: {
    width: 420,
    marginLeft: 20,
    marginRight: 20,
  },
  textField: {
    paddingBottom: 80
  }
}));

export default ChangeResult;
