import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { CustomButtonGroup, CustomSelectbox } from '../button';
import { Calendar } from '../common';
import { timeOptions } from '../../lib/util';
import VehicleTooltipDialogM from '../common/dialog/VehicleTooltipDialogM';

const useStyles = makeStyles((theme) => ({
  tipIcon: {
    marginLeft: 5,
  },
  title: {
    paddingBottom: 30,
  },
  countTitle: {
    width: 87,
  },
  selbox: {
    paddingLeft: 10,
  },
  path: {
    maxWidth: '70%',
    flexBasis: '70%',
    '& button': {
      borderRadius: 8,
      width: 68,
      margin: '0 2px',
    }
  },
  selectAear: {
    paddingBottom: 10,
  },
  input: {
    '& .MuiOutlinedInput-root': {
      marginBottom: 0
    }
  }
}));

const params = {
  vehicleType: 'Bike'
};
function DeliveryOptionM({ qsStore }) {
  const cs = useStyles();
  const [dialog, setDialog] = useState(false);
  const [goodsTemp, setGoodsItem] = useState("");
  const { handleOptions, orderCalAPI, options, smallBoxTotalCnt } = qsStore;
  

  useEffect(() => {
    handleOptions('vehicleType', 'Bike');
  }, [])

  useEffect(() => {

    if (smallBoxTotalCnt > 6 || options.documentCnt >= 10) {
      handleOptions('vehicleType', 'Damas');
      params["vehicleType"] = 'Damas';
    }

    if (smallBoxTotalCnt >= 30 || options.documentCnt >= 70) {
      handleOptions('vehicleType', 'Labo');
      params["vehicleType"] = 'Labo';
    }

  }, [smallBoxTotalCnt])


  const vehicleType = [
    { title: '오토바이', value: 'Bike', disabled: (smallBoxTotalCnt > 6 || options.documentCnt >= 10) },
    { title: '다마스', value: 'Damas', disabled: (smallBoxTotalCnt >= 30 || options.documentCnt >= 70) },
    { title: '라보', value: 'Labo' },
  ];

  const pathItem = [
    { title: '편도', value: 'OneWay' },
    { title: '왕복', value: 'RoundTrip' },
    { title: '경유', value: 'LayOver', tip: '상담 후 결제가 이뤄집니다.' },
  ];

  const etcItems = [
    { title: '없음', value: null },
    { title: '급송', value: 'Y' },
    { title: '예약', value: 'N', tip: '<span class="pColor">운영시간</span> 월~금: 08:00 ~ 21:00 / 토,일,공휴일 : 09:00 ~ 18:00' },
  ];

  const handleChange = async (e) => {
    let name = e.target.name;
    let value = e.target.value;
    params[name] = value;

    if (name === 'minute' || name === 'hour' || name === 'moveDate') {
      let time = moment(options.reservationTime ? options.reservationTime  : new Date());
      if (name === 'moveDate') time = moment(value);

      if (params.minute) time.minute(parseInt(params.minute));
      else time.minute(0);

      if (params.hour) time.hour(parseInt(params.hour));
      else time.hour(0);

      name = "reservationTime";
      value = new Date(moment(time)).getTime();
      // console.log('%c##### debug: ', 'color: #058FD7', moment(value).format('YYYY-MM-DD HH:mm'));
    } else if (name === 'isExpress') {
      if (value === 'Y') handleOptions("reservationTime", "");
    }

    await handleOptions(name, value);
    await orderCalAPI();
  }

  const handleTooltipOpen = () => {
    if (!params.vehicleType) return;
    setGoodsItem(params.vehicleType);
    handleOnOff();
  }

  const handleOnOff = () => {
    setDialog(!dialog);
  }

  return (
    <React.Fragment>
      <Grid className={cs.root} container direction="column" justify="flex-start" alignItems="flex-start">
        <VehicleTooltipDialogM type="quick" open={dialog} item={goodsTemp} handleClose={handleOnOff} />
        <Grid className={cs.title} container direction="column" justify="center" alignItems="center">
          <Typography variant="body1" color="primary">
            <b>배송 옵션</b>
          </Typography>
          <Typography variant="body2" color="textPrimary" align="center">
            <span className="pColor"><b>TIP.</b></span>
          로그인하시면 자주 쓰는 경로와 최근 이용 경로<br></br>조회가 가능합니다.
        </Typography>
        </Grid>
        <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
          <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
            <Typography variant="body2"><b>배송수단</b></Typography>
            <IconButton 
              className={clsx('questionMark', cs.tipIcon)} 
              onClick={() => handleTooltipOpen('open')}
            />
          </Grid>
          <Grid item xs={8} className={cs.selbox}>
            <FormControl fullWidth={true}>
              <CustomSelectbox 
                variant="filled" 
                name="vehicleType"
                inputProps={{ 'title': '수량 선택' }}
                onChange={handleChange}
                options={vehicleType}
                value={options.vehicleType}
              >
                {
                  vehicleType.map((item) => (
                    <option key={item.value} value={item.value} disabled={item.disabled}>{item.title} </option>
                  ))
                }
              </CustomSelectbox>
            </FormControl>
          </Grid>
        </Grid>
        <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
          <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
            <Typography variant="body2"><b>배송경로</b></Typography>
          </Grid>
          <Grid item className={clsx(cs.selbox, cs.path)}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <CustomButtonGroup 
                items={pathItem} 
                name="route"
                items={pathItem}
                defaultValue={options.route}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
          <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
            <Typography variant="body2"><b>기타</b></Typography>
          </Grid>
          <Grid item className={clsx(cs.selbox, cs.path)}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <CustomButtonGroup 
                name="isExpress"
                items={etcItems}
                defaultValue={""}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        {
          options.isExpress === 'N' && (
            <>
              <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
                <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
                  <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
                    <Typography variant="body2"></Typography>
                  </Grid>
                  <Grid item xs={8} className={cs.selbox}>
                    <Calendar
                      name="moveDate"
                      handleChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
                <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
                </Grid>
                <Grid item xs={8} className={cs.selbox}>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <FormControl className="time" fullWidth={false}>
                      <CustomSelectbox
                        variant="filled"
                        inputProps={{ 'title': '시간 선택' }}
                        name="hour"
                        onChange={handleChange}
                      >
                        <option>00</option>
                        {
                          timeOptions('time').map((item) => (
                            <option key={item.value} value={item.value}>{item.title}</option>
                          ))
                        }
                      </CustomSelectbox>
                    </FormControl>
                    <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500, margin: '0 10px' }}>시</Typography>
                    <FormControl className="time" fullWidth={false}>
                      <CustomSelectbox
                        variant="filled"
                        inputProps={{ 'title': '분 선택' }}
                        name="minute"
                        onChange={handleChange}
                        inputProps={{ 'title': '분 선택' }}
                      >
                        <option value="00">00</option>
                        {
                          timeOptions('').map((item) => (
                            <option key={item.value} value={item.value}>{item.title}</option>
                          ))
                        }
                      </CustomSelectbox>
                    </FormControl>
                    <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500, margin: '0 10px' }}>분</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )
        }
        <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
          <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
            <Typography variant="body2"><b>요청사항</b></Typography>
          </Grid>
          <Grid item xs={8} className={cs.selbox}>
            <TextField
              className={clsx(cs.input, "formArea nameText textBox")}
              variant="outlined"
              name="requests"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject('qsStore')(observer(DeliveryOptionM));
