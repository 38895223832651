import React from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, IconButton, Typography } from '@material-ui/core';
import { CustomButton } from '../button';
import { ImgStar } from '../../static/images';

const useStyles = makeStyles((theme) => ({
  tableArea: {
    position: 'relative',
    marginBottom: 16,
    paddingTop: 10,
    paddingBottom: 20,
    minHeight: 288,
    borderRadius: 10,
    boxShadow: '0 5px 15px 0 rgba(247, 124, 64, 0.2)',
    backgroundColor: '#ffffff',
  },
  table: {
    paddingTop: 20,
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  tableRow: {
    display: 'table-row',
  },
  tableCell: {
    display: 'table-cell',
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 20,
    // paddingRight: 20,
  },
  tableCaption: {
    paddingBottom: 11,
    color: '#000',
  },
  gap: {
    width: 16,
    padding: 0,
    '&$tableCell': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  th: {
    fontWeight: 700,
  },
  alignLeft: {
    textAlign: 'left',
    paddingRight: '25px !important;'
  },
  title: {
    width: '23%'
  },
  btnBox: {
    padding: '0 30px',
    '& button': { height: 40 }
  },
  line: {
    position: 'absolute',
    width: '100%',
    '& .MuiDivider-root': {
      display: 'flex',
      margin: '0 20px',
    }
  },
  start: {
    position: 'absolute',
    width: '100%',
    '& .startBtn': {
      right: '-90%',
      backgroundColor: '#A4A4AC',
      background: `url(${ImgStar}) no-repeat center`,
    },
    '& .isStart': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

function AddressCard({ item, handleLike, cmStore, history }) {
  const cs = useStyles();
  const { setEtcAddress } = cmStore;
  const testData = [
    { title: '출발지', value: item.startNm},
    { title: '주소', value: `${item.startAddr} ${item.startAddrDtl}`},
    { title: '연락처', value: item.startTel},
    { title: '도착지', value: `${item.endAddr} ${item.endAddrDtl}`},
    { title: '주소', value: item.endNm},
    { title: '연락처', value: item.endTel},
  ]

  const handlePage = (path) => {
    setEtcAddress("each", item);
    history.push("/quickservice");
  }

  return (
    <div className={cs.tableArea}>
      <div className={cs.start}>
        <IconButton 
          className={clsx('startBtn', item.myAddressKey && 'isStart')}
          onClick={() => handleLike(item)}
        />
      </div>
      <div className={cs.table}>
        {
          testData.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className={cs.tableRow}>
                  <div className={clsx(cs.tableCell, cs.title)}>
                    <Typography variant="body2" color="primary">{item.title}</Typography>
                  </div>
                  <div className={clsx(cs.tableCell, cs.alignLeft)}>
                    {item.value}
                  </div>
                </div>
                { index === 2 && <div className={cs.line}><Divider /> </div>}
              </React.Fragment>
            )
          })
        }
      </div>
      <Box height={24} />
      <div className={cs.btnBox}>
        <CustomButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={handlePage}
        >접수/예약하기
        </CustomButton>
      </div>
    </div>
  );
}

export default inject('cmStore')(withRouter(AddressCard));