import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { AddressCard } from '../components/common';
import GuideMessageM from '../components/message/GuideMessageM';
import { IconBackG } from '../static/images/icon';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  inputBox: {
    maxWidth: 500,
    padding: '0 30px',
  },
  noMsg: {
    height: 50,
    padding: 10,
    textAlign: 'center',
  },
  backBtn: {
    '&:hover': {
      background: 'transparent'
    }
  },
  title: {
    width: '100%',
    textAlign: 'center'
  },
}));

function ShippingListPage({ myPageStore }) {
  const cs = useStyles();
  const history = useHistory();
  const { latestAddressSel, latestAddress, latestAddressListMod } = myPageStore;

  useEffect(() => {
    latestAddressSel();
  }, []);

  const handleLike = (item) => {
    latestAddressListMod(item);
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <Grid className="mainLayout" container direction="column" justify="center" alignItems="center">
        <Grid className={cs.inputBox} container direction="column" justify="center" alignItems="flex-start">
          {
            !matches && (
              <IconButton className={cs.backBtn} onClick={() => { history.goBack() }}>
                <img src={IconBackG} alt="" />
                <Typography variant="body1" style={{ color: '#ACACB2', marginBottom: 3 }} align="left" gutterBottom>뒤로가기</Typography>
              </IconButton>
            )
          }
          <Grid item className={cs.title}>
            <GuideMessageM 
              title="최근 이용 경로"
              subTitle="현재 진행중인 배송상태를 알려드립니다."
            />
          </Grid>
        </Grid>
        <Box height={60} />
        <Grid className={cs.inputBox} container direction="column" justify="center" alignItems="flex-start">
          {
            latestAddress.length > 0 
              ? (
                latestAddress.map((item, index) => (
                  <AddressCard 
                    key={index} 
                    item={item} 
                    handleLike={handleLike}
                  />
                ))
              )
              : (
                <div style={{ width: '100%' }}>
                  <Paper className={cs.noMsg} elevation={0} >
                    <Typography variant="body2" color="primary">최근 이용 경로가 존재하지 않습니다.</Typography>
                  </Paper>
                </div>
              )
          }
          
        </Grid>
      </Grid>
    </React.Fragment>

  );
}

export default inject('myPageStore')(observer(ShippingListPage));