import { createMuiTheme } from '@material-ui/core';

import palette from './palette.js';
import typography from './typography.js';
import overrides from './overrides.js';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
});

export default theme;