import React from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { CustomVacantButton } from '../../button';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 30,
  },
  btn: {
    marginTop: 10,
    width: 135,
    height: 45,
    fontSize: 16,
  },
  loginBtn: { marginRight: 10 }
}));

function ServiceTitleM({ loginStore, myPageStore }) {
  const cs = useStyles();
  const history = useHistory();
  const { userInfo } = loginStore;
  const { myAddress, latestAddress } = myPageStore;

  const handlePage = (path) => {
    if (path === '/myaddress') {
      if (!myAddress || myAddress.length === 0) {
        alert("자주쓰는 경로가 존재하지 않습니다.");
        return;
      }
    } else if (path === '/shippinglist') {
      if (!latestAddress || latestAddress.length === 0) {
        alert("최근이용 경로가 존재하지 않습니다.");
        return;
      }
    }


    history.push(path);
  }

  return (
    <Grid className={cs.root} container direction="column" justify="center" alignItems="center">
      <Typography variant="body1" color="primary">
        <b>출발지와 도착지를 선택해주세요.</b>
      </Typography>
      <Typography variant="body2" color="textPrimary" align="center">
        <span className="pColor"><b>TIP.</b></span>
          로그인하시면 자주 쓰는 경로와 최근 이용
          경로<br></br> 조회가 가능합니다.
      </Typography>
      <Grid container direction="row" justify="center" alignItems="center" >
        {
          userInfo && userInfo.userKey
            ? (
              <>
                <Grid item>
                  <CustomVacantButton
                    className={clsx(cs.btn, cs.loginBtn)}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handlePage('/myaddress')}
                  >
                    자주쓰는
                  </CustomVacantButton>
                </Grid>
                <Grid item>
                  <CustomVacantButton
                    className={cs.btn}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handlePage('/shippinglist')}
                  >
                    최근이용
                  </CustomVacantButton>
                </Grid>
              </>
            )
            : (
              <>
                <Grid item>
                  <CustomVacantButton
                    className={clsx(cs.btn, cs.loginBtn)}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handlePage('/login')}
                  >
                    로그인
                  </CustomVacantButton>
                </Grid>
                {/* <Grid item>
                  <CustomVacantButton
                    className={cs.btn}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    비회원 이용
                  </CustomVacantButton>
                </Grid> */}
              </>
            )
        }
      </Grid>
    </Grid>
  );
}

export default inject('loginStore', 'myPageStore')(observer(ServiceTitleM));
