import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { AddressM, GoodsAndCountM, PayBoxM, ServiceTitleM, TotalBoxM } from '../common/eachService';
import { ImgFurniture, ImgOther, ImgPallet, ImgBoxSmall } from '../../static/images';
import { Container } from '@material-ui/core';
import UpAndDownM from './UpAndDownM';

const useStyles = makeStyles((theme) => ({
  contentone: {
    width: '100%',
    background: '#ffffff',
    margin: '20px 0',
    borderRadius: 10,
    padding: '40px 10px',
  }
}));

function CargoServiceViewM({ cmStore }) {
  const cs = useStyles();
  const { setMobileTitle } = cmStore;

  const goodsItem = [
    { 
      idx: 0,
      title: '팔레트',
      tip: '팔레트',
      icon: ImgPallet ,
      name: 'paletteCnt',
      total: '',
      tipTitle: '<span class="pColor">다마스, 라보 이용 불가</span>',
      tipContext: '<span class="">T11(1100*1100), T12(1200*10000)</span>'
    },
    { 
      idx: 1,
      title: '박스',
      tip: '박스',
      icon: ImgBoxSmall,
      name: 'boxCnt',
      total: '',
      tipTitle: '<span class="pColor">중박스 기준</span>',
      tipContext: '<span class="pColor">3변의 합이 91cm ~ 120cm</span> 미만의 물품'
    },
    { 
      idx: 2,
      title: '가구/집기',
      tip: '가구/집기',
      icon: ImgFurniture,
      name: 'fuCnt',
      total: '',
      tipTitle: '<span class="pColor">차량 적재규격 확인</span> ',
      tipContext: '<span class="">컴퓨터, 모니터, 컴퓨터책상, 의자, 쇼파,<br/> 침대, 소형가전 등</span> '
    },
    { 
      idx: 3,
      title: '기타',
      tip: '기타',
      icon: ImgOther,
      name: 'etcCnt',
      total: '',
      tipTitle: '<span class="pColor">직접 기재</span> ',
      tipContext: '<span class="">케이크, 꽃다발(꽃바구니), 화분, 곡식, 대봉 및 원단 등</span>'
    },
  ]

  useEffect(() => {
    setMobileTitle('');
  }, []);

  return (
    <React.Fragment>
      <Container style={{ padding: '0 10px'}}>
        <div className={cs.contentone}>
          <ServiceTitleM />
          <AddressM type="cargo" />
          <GoodsAndCountM goodsItem={goodsItem} />
        </div>
        <div className={cs.contentone}>
          <UpAndDownM />
        </div>
        <div className={cs.contentone}>
          <PayBoxM pageType="cargo"/>
        </div>
      </Container>
      <TotalBoxM />
    </React.Fragment>
  );
}

export default inject('cmStore')(CargoServiceViewM);