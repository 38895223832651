import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { FormControl, Grid, InputBase, NativeSelect } from '@material-ui/core';

import { ImgMainSlideM, ImgMainSlideTwo } from '../../static/images';
import { withStyles } from '@material-ui/styles';
import Slider from 'react-slick';


const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& svg': {
      color: '#ffffff',
      marginRight: 10,
    },
    // '& .sliderBtnAera': {
    //   marginTop: 40,
    //   position: 'absolute',
    //   bottom: 100,
    // }
  },
  input: {
    textAlignLast: 'center',
    height: 35,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#ff925c',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#ffffff',
    padding: '10px 26px 10px 12px',
    borderRadius: '15px 15px 0 15px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      backgroundColor: '#ff925c',
      fontSize: 16,
      color: '#ffffff',
      borderRadius: '15px 15px 0 15px',
      fontSize: 20,
      fontWeight: 'bold',
    },
  },
}))(InputBase);



const useStyles = makeStyles((theme) => ({
  img: {
    paddingTop: 30,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 360
    }
  },
  paddingLR: {
    // padding: '0 10px'
  },
  selectBox: {
    '& select': {
      '& option': {
        color: '#000000'
      }
      // padding: '10px 35%'
    }
  }
}));

function MainSlidersM({ match, history, qsStore }) {
  const cs = useStyles();
  const { banner, bannerSelCall } = qsStore;
  const [selValue, setSelValue] = useState('/quickservice');

  useEffect(() => {
    bannerSelCall();
  }, []);

  const handleLink = (item) => {
    if (item.linkUrl) {
      window.open(item.linkUrl);
    }
  }


  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
  };
  
  useEffect(() => {
    const { path } = match;
    setSelValue(path);
  }, [])

  const handleChange = (e) => {
    const path = e.target.value;
    history.push(path);
  }

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid container>
        <Grid item xs={12}>
          <Slider
            className={cs.slider}
            {...settings}
          >
            {
              (banner && banner.length > 0) && (
                banner.map((item, index) => {
                  if (!item.imageMobileUrl) return null;
                  return <div key={index}><img className={cs.img} src={item.imageMobileUrl} alt="" onClick={() => handleLink(item) }/></div>
                })
              )
            }
          </Slider>
        </Grid>
      </Grid>
      <FormControl className={cs.paddingLR} fullWidth>
        <NativeSelect
          className={cs.selectBox}
          value={selValue}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <option value={"/quickservice"}>퀵서비스</option>
          <option value={"/cargoservice"}>화물서비스</option>
          <option value={"/moveservice"}>이사서비스</option>
        </NativeSelect>
      </FormControl>
    </Grid>
  );
}

export default inject('qsStore')(withRouter(observer(MainSlidersM)));
