import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import CustomVacantButton from './CustomVacantButton';
import { Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 132,
    height: 40,
    margin: '0 5px',
    color: "#a4a4ac",
    backgroundColor: '#ffffff',
    borderColor: '#e4e4e9',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: '#a4a4ac',
      '& .MuiButton-label': { display: 'contents' }
    },
    fontSize: 16,
    lineHeight: 7.5,
    letterSpacing: -0.8,
    '& .MuiButton-label': { height: '100%'}
  },
  selected: {
    backgroundColor: "#ff925c",
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ff925c',
      boxShadow: 'none',
      color: '#ffffff',
    },
  },
  tooltipText: {
    color: '#555558',
    fontSize: 16,
    textAlign: 'center',
    maxWidth: 'none !important;',
    // height: 30,
    padding: 15
  }
}));
function CustomButtonGroup({ items = [], name, defaultValue, handleChange, tip }) {

  const classes = useStyles();
  const [value, setValue] = useState(defaultValue ? defaultValue : '');

  const handleClick = (value) => {
    setValue(value);
    handleChange({target: { name, value }});
  }

  React.useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])
  
  return (
    <React.Fragment>
      {
        items.map((item) => (
          !item.tip
            ? (
              <CustomVacantButton
                key={item.value}
                className={clsx(classes.root, value === item.value ? classes.selected : '')}
                variant="contained"
                color="primary"
                value={value}
                onClick={() => handleClick(item.value)}
              >
                {/* <span>{item.title}</span> */}
                <Typography variant="body2">{item.title}</Typography>
              </CustomVacantButton>
            )
            : (
              <Tooltip 
                classes={{ tooltip: classes.tooltipText }} 
                key={item.value} 
                title={
                  <span dangerouslySetInnerHTML={{ __html: item.tip }} />
                }
                placement="top-end">
                <CustomVacantButton
                  className={clsx(classes.root, value === item.value ? classes.selected : '')}
                  variant="contained"
                  color="primary"
                  value={value}
                  onClick={() => handleClick(item.value)}
                >
                  {/* <span>{item.title}</span> */}
                  <Typography variant="body2">{item.title}</Typography>
                </CustomVacantButton>
              </Tooltip>
            )
        ))
      }
    </React.Fragment>
  );
}

export default CustomButtonGroup;