import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { PolicyService, PolicyPrivacy, PolicyMain } from '../components/PolicyPage';

function PolicyPage(props) {
  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = useState('main');

  const handleClick = (page) => {
    const { state } = location;
    // console.log({ state });
    if (!state || !state.type) {
      setPage(page);
    } else {
      history.goBack();

    }

  }

  useEffect(() => {
    const { state } = location;
    // console.log({ state });
    if (!state || !state.type) return;
    setPage(state.type);
  }, [])

  return (
    <React.Fragment>
      <Grid className="mainLayout" container direction="column" justify="center" alignItems="center">
        {page === 'main' && <PolicyMain handleClick={handleClick}/>}
        {page === 'service' && <PolicyService handleClick={handleClick}/> }
        {page === 'privacy' && <PolicyPrivacy handleClick={handleClick}/> }
      </Grid>
    </React.Fragment>
  );
}

export default PolicyPage;
