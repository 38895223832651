import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomWhiteButton = withStyles((theme) => ({
  root: {
    height: 50,
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 28,
    fontSize: 18,
    color: '#555558',
    '& .MuiButton-endIcon': {
      right: 0,
      position: 'absolute',
      width: 26,
      height: 26,
      margin: '12px 20px',
    }
  },
  containedPrimary: {
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: '#555558',
    },
  },
  disabled: {
    color: '#ffff !important;',
    backgroundColor: '#e4e4e9 !important;',
  },
  startIcon: {
    '& span': {
      width: 32,
      height: 32,
    }
  }
}))(Button);

export default CustomWhiteButton;