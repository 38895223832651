import moment from 'moment';

// 시간, 분 options
export const timeOptions = (type) => {
  const times = [];
  let text = '00';
  if (type === 'time') {
    for(let i = 1; i <= 23; i++) {
      if (i < 10) text = `0${i}`;
      else text = i;
      times.push({ title: text, value: i });
    }
  } else {
    for (let i = 1; i <= 59; i++) {
      if (i < 10) text = `0${i}`
      else text = i;
      times.push({ title: text, value: i });
    }
  }
  return times;
}

// count options
export const countOptions = (count) => {
  const counts = [];
  let text = '00';
  for (let i = 1; i <= count; i++) {
    if (i < 10) text = `0${i}`;
    else text = i;
    counts.push({ title: text, value: i });
  }

  return counts
}

// 요일 
export const dayString = () => {
  const day = moment().day();
  if (day === 0) return '일';
  else if (day === 1) return '월';
  else if (day === 2) return '화';
  else if (day === 3) return '수';
  else if (day === 4) return '목';
  else if (day === 5) return '금';
  else if (day === 6) return '토';
}

//금액 콤마
export const numberComma = (num) => {
  if (num) {
    return String(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '0';
  }
};

// 갯수
export const optionCount = (max) => {
  const counts = [];
  for (let i = 0; i <= max; i++) {
    counts.push(i);
  }

  return counts;
}

export const EmailRegExp = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const PwdRegExp = (pwd) => {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()_+])[A-Za-z\d~!@#$%^&*()_+]{8,16}$/.test(pwd);
}

export const PhoneRegExp = (phone) => {
  return /^0[0-9]{1,2}[0-9]{3,4}[0-9]{4}$/.test(phone) || /^0[0-9]{1,2}-[0-9]{3,4}-[0-9]{4}$/.test(phone);
}

/**
 * 날짜 그룹 버튼 날짜 세팅
 * @param value 날짜 type {
 *  1M: 1개월, 2M: 2개월, 3M: 3개월, 6M: 6가월, 1y: 1년
 * }
 */
export const dateGroupSet = (value) => {
  let result = new Date();
  const temp = value.split('');
  const date = temp[0];
  const type = temp[1];
  if (!temp || temp.length === 0) return result;

  result = new Date(moment().subtract(Number(date), type).format())
  return result;
};