import React, { useState } from 'react';
import { ClickAwayListener, FormControl, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';

import { CustomSelectbox } from '../button';
import TooltipBox from './eachService/TooltipBox';
import { TooltipVehicleType } from './eachService';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '&. MuiTooltip-poppe': {
      // top: -50,
    },
    '& .MuiTooltip-tooltip': {
      width: 700,
      display: 'inline-table'
    }
  }
}));

function CustomSelectGroup({ type, title, name, options = [], tip, value, handleChange, pd = 20 }) {
  const cs = useStyles();
  const [tipOpen, setTipOpen] = useState(false);

  const handleTooltipOpen = (type) => {
    const result = type === 'close' ? false : !tipOpen;
    setTipOpen(result);
  }

  return (
    <Grid className={cs.root}  container direction="row" justify="flex-start" alignItems="center">
      <Typography style={{ paddingRight: tip ? 0 : pd }} variant="subtitle2"><b>{title}</b></Typography>
      {
        tip &&
        (
          <ClickAwayListener onClickAway={() => handleTooltipOpen('close')}>
            <Tooltip
              arrow
              placement="top-start"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => handleTooltipOpen('close')}
              open={tipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                name === 'vehicleType'
                  ? <TooltipVehicleType type={type} />
                  : <TooltipBox />
              }
            >
              <IconButton
                style={{ marginLeft: 4, marginRight: 20 }}
                className={tipOpen ? 'questionMarkOn' : 'questionMark'}
                onClick={() => handleTooltipOpen('open')}
              />
            </Tooltip>
          </ClickAwayListener>
        )
      }
      <Grid item>
        <FormControl className="csgSelectbox" fullWidth={false}>
          <CustomSelectbox 
            name={name}
            variant="filled" 
            inputProps={{ 'title': '옵션 선택' }}
            onChange={handleChange}
            value={value ? value : ''}
          >
            {
              options.map((item) => (
                <option 
                  key={item.value} 
                  value={item.value}
                  disabled={item.disabled}
                >{item.title}</option>
              ))
            }
          </CustomSelectbox>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default CustomSelectGroup;