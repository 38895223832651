import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import GuideMessage from '../message/GuideMessage';
import { CustomButton, CustomVacantButton } from '../button';

function IDInquiryResult({ loginStore }) {
  const { findId } = loginStore;
  const classes = useStyles();
  const history = useHistory();
  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" wrap="nowrap" className={classes.idInquiryWrap}>
        <Grid item className="loginFormArea">
          <Grid container direction="column" justify="center" className="narrowFormArea">
            <Grid item>
              <Grid container direction="column" justify="center" alignItems="center">
                <GuideMessage
                  title="가입하신 아이디 입니다."
                  mTitle="아이디찾기"
                  resultTitle="가입하신 아이디 입니다."
                  subTitle=""
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.resultText}>
                {findId ? findId : ''}
              </Typography>
            </Grid>
            <Grid item>
              <CustomVacantButton
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.findPsssword}
                onClick={() => history.push('/pwInquiry')}
              >비밀번호 찾기
              </CustomVacantButton>
              <CustomButton
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => history.push('/login')}
              >로그인
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  idInquiryWrap: {
    paddingTop: 100,
    paddingBottom: 506
  },
  resultText: {
    padding: '80px 0',
    fontSize: 32,
    color: '#555558',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
  findPsssword: {
    marginBottom: 20,
  }
}));

export default inject("loginStore")(observer(IDInquiryResult));
