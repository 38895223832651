import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { TextField, InputAdornment } from '@material-ui/core';
import { ButtonCalendar } from '../button';

import ko from 'date-fns/locale/ko';

function Calendar({ isDate, name, id, handleChange, defaultValue }) {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    if (isDate) {
      setStartDate(null);
    }
  }, [isDate])

  const onChange = (date) => {
    setStartDate(date);

    handleChange({ target: { name, id, value: date }});
  }

  return (
    <ReactDatePicker
      locale={ko}
      selected={startDate}
      disabled={isDate}
      dateFormat="yyyy-MM-dd"
      onChange={(date) => onChange(date)}
      value={startDate}
      customInput={
        <TextField
          disabled
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ButtonCalendar disabled={isDate} />
              </InputAdornment>
            ),
            inputProps: { 'title': '시작일 입력' },
            className: 'pickDate',
          }}
        />
      }
    />
  );
};

export default Calendar;