import React from 'react';
import { Box, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconBackG } from '../../static/images/icon';
import GuideMessageM from '../message/GuideMessageM';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  inputBox: {
    maxWidth: 800,
    padding: '0 30px',
  },
  title: {
    width: '100%',
    textAlign: 'center'
  },
  box: {
    overflowY: 'scroll',
    width: 'auto',
    height: 600,
    padding: '0 40px',
    boxShadow: '0 5px 15px 0 rgba(247, 124, 64, 0.2)',
    backgroundColor: '#ffffff',
  },
  backBtn: {
    '&:hover': {
      background: 'transparent'
    }
  }
}));

function PolicyPrivacy({ handleClick }) {
  const cs = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <React.Fragment>
      <Grid className={cs.inputBox} container direction="column" justify="center" alignItems="center">

        {
          !matches && (
            <>
              <div style={{ width: '100%' }}>
                <IconButton className={cs.backBtn}>
                  <img src={IconBackG} alt="" />
                  <Typography
                    variant="body1"
                    style={{ color: '#ACACB2', marginBottom: 3 }}
                    align="left"
                    gutterBottom
                    onClick={() => handleClick('main')}
                  >뒤로가기</Typography>
                </IconButton>
              </div>
              <Grid item className={cs.title}>
                <GuideMessageM title="개인정보처리방침" />
              </Grid>
              <Box height={40} />
            </>
          )
        }
        
        <Box className={cs.box}>
          <Box height={40} />
          <Typography variant="body2" style={{ color: '#cacacf' }} align="left" gutterBottom>June 2, 2017</Typography>
          <Box height={30} />
          <Typography variant="body2" color="textPrimary" align="left" gutterBottom>
            1. 개인정보의 처리 목적 [신한로직스](‘http://www.quinors.com/’이하 ‘퀴노스’) 은(는) 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.<br></br>
            - 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급.배송 등<br></br>
            2. 개인정보의 처리 및 보유 기간<br></br>
            ① [신한로직스](‘http://www.quinors.com/’이하 ‘퀴노스’) 은(는) 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유․이용기간 또는 법령에 따른 개인정보 보유․이용기간 내에서 개인정보를 처리․보유합니다.<br></br>
            ② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.<br></br>
            ☞ 아래 예시를 참고하여 개인정보 처리업무와 개인정보 처리업무에 대한 보유기간 및 관련 법령, 근거 등을 기재합니다.<br></br>
            (예시)- 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지<br></br>
            - 전자상거래에서의 계약․청약철회, 대금결제, 재화 등 공급기록 : 5년<br></br>
            3. 개인정보의 제3자 제공에 관한 사항<br></br>
            ① [신한로직스]('http://www.quinors.com/'이하 '퀴노스')은(는) 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br></br>
            ② [신한로직스]('http://www.quinors.com/')은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.<br></br>
            1. [고객]<br></br>
            - 개인정보를 제공받는 자 : 고객<br></br>
            - 제공받는 자의 개인정보 이용목적 : 핸드폰번호, 비밀번호, 로그인ID, 이름, 회사전화번호, 직책, 부서, 회사명, 직업, 주민등록번호<br></br>
            - 제공받는 자의 보유.이용기간: 준영구<br></br>
            4. 개인정보처리 위탁<br></br>
            ① [신한로직스]('퀴노스')은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br></br>
            1. [퀴노스]<br></br>
            - 위탁받는 자 (수탁자) : 퀴노스<br></br>
            - 위탁하는 업무의 내용 : 구매 및 요금 결제, 물품배송 또는 청구서 등 발송, 본인인증(금융거래, 금융서비스), 요금추심, 회원제 서비스 이용에 따른 본인확인, 불만처리 등 민원처리, 고지사항 전달, 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공<br></br>
            - 위탁기간 : 준영구<br></br>
            ② [신한로직스]('http://www.quinors.com/'이하 '퀴노스')은(는) 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br></br>
            ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.<br></br>
            5. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.<br></br>
            ① 정보주체는 신한로직스(‘http://www.quinors.com/’이하 ‘퀴노스) 에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br></br>
            1. 개인정보 열람요구<br></br>
            2. 오류 등이 있을 경우 정정 요구<br></br>
            3. 삭제요구<br></br>
            4. 처리정지 요구<br></br>
            6. 처리하는 개인정보의 항목 작성<br></br>
            ① [신한로직스]('http://www.quinors.com/'이하 '퀴노스')은(는) 다음의 개인정보 항목을 처리하고 있습니다.<br></br>
            1[홈페이지 회원가입 및 관리, 배송주문 및 조회]<br></br>
            - 필수항목 : 이메일, 핸드폰번호, 비밀번호, 로그인ID, 이름, 회사전화번호, 직책, 부서, 회사명<br></br>
            - 선택항목 : 이메일, 자택주소, 자택전화번호, 비밀번호 질문과 답, 비밀번호, 성별, 생년월일, 직업, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록<br></br>
            7. 개인정보의 파기[신한로직스]('퀴노스')은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.<br></br>
            -파기절차<br></br>
            이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.<br></br>
            -파기기한<br></br>
            이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.<br></br>
            8. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항<br></br>
            신한로직스 은 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키’를 사용하지 않습니다.<br></br>
            9. 개인정보 보호책임자 작성<br></br>
            ① 신한로직스(‘http://www.quinors.com/’이하 ‘퀴노스) 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br></br>
            ▶ 개인정보 보호책임자<br></br>
            성명 :유재영<br></br>
            직책 :대표<br></br>
            직급 :대표<br></br>
            연락처 : yjy@quinors.com, 02-6008-5480<br></br>
            ※ 개인정보 보호 담당부서로 연결됩니다.<br></br>
            ▶ 개인정보 보호 담당부서<br></br>
            부서명 :디자인팀<br></br>
            담당자 :박슬기<br></br>
            연락처 : seulki0710@quinors.com, 02-6008-5480<br></br>
            ② 정보주체께서는 신한로직스(‘http://www.quinors.com/’이하 ‘퀴노스) 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 신한로직스(‘http://www.quinors.com/’이하 ‘퀴노스) 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br></br>
            10. 개인정보 처리방침 변경<br></br>
            ①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.<br></br>
            11. 개인정보의 안전성 확보 조치 [신한로직스]('퀴노스')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.<br></br>
            1. 개인정보 취급 직원의 최소화 및 교육<br></br>
            개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br></br>
            2. 내부관리계획의 수립 및 시행<br></br>
            개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br></br>
            3. 해킹 등에 대비한 기술적 대책<br></br>
            [신한로직스]('퀴노스')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br></br>
            4. 개인정보의 암호화<br></br>
            이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br></br>
            5. 접속기록의 보관 및 위변조 방지<br></br>
            개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.<br></br>
            6. 개인정보에 대한 접근 제한<br></br>
            개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단<br></br>
            개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br></br>
          </Typography>
          <Box height={30} />
        </Box>
      </Grid>
    </React.Fragment>
  );
}

export default PolicyPrivacy;