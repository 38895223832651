import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { inject } from 'mobx-react';
import { ClickAwayListener, FormControlLabel, Grid, IconButton, RadioGroup, Tooltip, Typography } from '@material-ui/core';

import CustomRadio from '../../button/CustomRadio';
import TooltipBox from './TooltipBox';
import MovingTooltipDialog from '../dialog/MovingTooltipDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
  }
}));
function MovingSize({ items, name, qsStore }) {
  const classes = useStyles();
  const { handleOptions } = qsStore;
  const [dialog, setDialog] = useState(false);
  const [value, setValue] = useState();
  const [goodsTemp, setGoodsItem] = useState(null);

  const handleChange = (e) => {
    const v = e.target.value;
    setValue(v);
    handleOptions(name, v);
  }

  const handleTooltipOpen = (item) => {
    setGoodsItem(item);
    handleOnOff();
  }


  const handleOnOff = () => {
    setDialog(!dialog);
  }


  return (
    <Grid className={classes.root} container direction="column" justify="flex-start" alignItems="flex-start">
      <MovingTooltipDialog  open={dialog} item={goodsTemp} handleClose={handleOnOff} />
      <RadioGroup row defaultValue="rdoLabel_1">
        {
          items.map((item) => (
            <Grid key={item.idx} container direction="row" justify="flex-start" alignItems="flex-start" style={{ marginBottom: 60 }}>
              <Grid item>
                <FormControlLabel
                  value={item.value}
                  name={name}
                  control={<CustomRadio checked={value === item.value}  onChange={handleChange}/>}
                  label={
                    <Typography variant="subtitle2" color="textPrimary"><b>{item.title}</b></Typography>
                  }
                  classes={{ label: 'label' }}
                  className={classes.formControlLabel}
                  style={{ marginRight: 10 }}
                />
                <IconButton 
                  // className={tooltip[item.idx] ? 'questionMarkOn' : 'questionMark'} 
                  className="questionMark"
                  onClick={() => handleTooltipOpen(item, 'open')} 
                />
              </Grid>
            </Grid>
          ))
        }
      </RadioGroup>
    </Grid>
  );
}

export default inject('qsStore')(MovingSize);