import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, TextField, Box, FormControlLabel, Divider } from '@material-ui/core';
import { CustomButton, CustomCheckbox } from '../button';
import GuideMessageM from '../message/GuideMessageM';

const useStyles = makeStyles((theme) => ({
  inputBox: {
    maxWidth: 500,
    margin: '60px 0',
    padding: '0 30px',
  },
  divider: {
    width: '100%',
    margin: '40px 0 70px 0'
  },
  checkbox: {
    marginTop: -4,
  }
}));

function OpinionForm({ handleCheck, loginStore }) {
  const classes = useStyles();
  const { leaveCall } = loginStore;
  const [params, setParams] = useState({
    noAsk: false,
    etcText: '',
    quitReason: '',
  });

  const onSubmit = () => {
    leaveCall(params, () => {
      handleCheck('result')
    });
  }

  const handleChange = (e, checked) => {
    // quitReason
    let temp = params;
    const name = e.target.name;
    const value = e.target.value;
    
    if (name === "noAsk") {
      temp[name] = checked;
    } else {
      temp[name] = value;
      
      if (name === "etc" && checked) {
        temp["quitReason"] = temp.etcText
      } else {
        temp["quitReason"] = value;
      }
      
    }

    setParams({...temp});
  }

  return (
    <React.Fragment>
      <Grid item className={classes.title}>
        <GuideMessageM
          title="탈퇴하기"
          subTitle="다시 만나는 그날까지 더 좋은 서비스가 되겠습니다.</br>
          서비스 중단의 이유를 알려주신다면 더 고민하여</br>
          개선하겠습니다. (복수선택)"
        />
      </Grid>
      <Grid className={classes.inputBox} container direction="column" justify="center" alignItems="flex-start">
        <FormControlLabel
          name="noAsk"
          onChange={handleChange}
          control={<CustomCheckbox name="noAsk" onChange={handleChange}/>}
          label={
            <Typography variant="body1" color="textPrimary" gutterBottom><b>응답하지않기</b></Typography>
          }
        />
        {
          !params.noAsk && (
            <>
              <Divider className={classes.divider}/>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  <FormControlLabel
                    className={classes.checkbox}
                    name="quitReason"
                    value="서비스 이용에 대한 불편한  - 버튼 위치, 불필요한 이용 단계 등"
                  control={<CustomCheckbox onChange={handleChange}/>}
                  />
                  <Typography variant="body1" color="textPrimary">
                    서비스 이용에 대한 불편한<br></br>
                    - 버튼 위치, 불필요한 이용 단계 등
                  </Typography>
                </Grid>
                <Box height={30} />
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  <FormControlLabel
                    className={classes.checkbox}
                    name="quitReason"
                    value="선택정보 부족으로 필요성 감소"
                  control={<CustomCheckbox onChange={handleChange}/>}
                  />
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    선택정보 부족으로 필요성 감소
                  </Typography>
                </Grid>
                <Box height={30} />
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  <FormControlLabel
                    className={classes.checkbox}
                    name="quitReason"
                    value="서비스 안내자의 불친절함"
                  control={<CustomCheckbox onChange={handleChange}/>}
                  />
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    서비스 안내자의 불친절함
                  </Typography>
                </Grid>
                <Box height={30} />
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  <FormControlLabel
                    className={classes.checkbox}
                    name="etc"
                    control={<CustomCheckbox onChange={handleChange}/>}
                  />
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    기타
                  </Typography>
                  <TextField
                    fullWidth
                    style={{ marginTop: 10 }}
                    name="etcText"
                    className="formArea phoneText textBox"
                    placeholder="탈퇴 이유를 적어주세요."
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
            </>
          )
        }
        <Box height={60} />
        <CustomButton
          // disabled
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >탈퇴하기</CustomButton>
      </Grid>
    </React.Fragment>
  );
}

export default inject('loginStore')(OpinionForm);