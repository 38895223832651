import React, { useState } from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Grid, RadioGroup, Typography } from '@material-ui/core';

import ServiceTitle from './ServiceTitle';
import CustomRadio from '../../button/CustomRadio';
import { CustomButton } from '../../button';
import { IconNotice } from '../../../static/images/icon';
import { ImgKakaoPay, ImgNaverPay } from '../../../static/images';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    marginBottom: 120,
    padding: '60px 80px 60px 80px',
    '& .title': {
      paddingTop: 50,
    },
    '& .cardSelectbox': {
      width: 240,
    },
    '& .accountBox': {
      width: 390,
      height: 78,
      marginTop: 10,
      padding: '13px 20px',
      borderRadius: 6,
      backgroundColor: '#fef7f5',
    },
    '& .radiobox': {
      marginRight: 45,
    },
    '& .hideBox': {
      marginRight: 60,
      marginLeft: 20
    },
    '& .succesBtn': {
      width: 340
    }
  },
  img: {
    width: 80,
  },
  kakaoImg: {
    width: 60,
    paddingTop: 5
  },
  
}));
function PayBox({ qsStore, loginStore, pageType }) {
  const classes = useStyles();
  const { userInfo } = loginStore;
  const { quickerReg, orderResult, handleOptions, options } = qsStore;
  const [payType, setPayType] = useState(options.paymentType);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setPayType(value);
    handleOptions(name, value);
  }

  const payItems = [
    { title: '신용/체크카드', value: 'Card', dim: false },
    { title: '계좌 이체', value: 'Account', dim: false },
    { title: '법인후불', value: 'CorporateAfter', dim: userInfo?.userType !== "Company" },
    { title: '네이버페이', value: 'NaverPay', dim: false },
    { title: '카카오페이', value: 'KakaoPay', dim: false },
  ]


  return (
    <Grid className={clsx('serviceBox', classes.root)} container direction="column" justify="flex-start" alignItems="flex-start">
      <Grid item >
        <ServiceTitle
          title="결제"
          subTitle="기업회원의 경우 등록된 카드 또는 월단위 결제로 진행됩니다."
        />
      </Grid>
      <Grid item style={{width: '100%'}}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <RadioGroup row defaultValue="rdoLabel_1" className={payType === 'Account' ? 'title' : ''}>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                {
                  payItems.map((item) => {
                    
                    let dom = (
                      <Grid key={item.value} item className="radiobox">
                        <FormControlLabel
                          value={item.value}
                          disabled={item.dim}
                          control={<CustomRadio name="paymentType" checked={payType === item.value} onChange={handleChange} />}
                          label={
                            <Typography variant="body1" color="textPrimary">{item.title}</Typography>
                          }
                          classes={{ label: 'label' }}
                          style={{ marginRight: 10 }}
                        />
                      </Grid>
                    )
                    if (item.value === 'NaverPay' || item.value === 'KakaoPay') {
                      dom = (
                        <Grid key={item.value} item className="radiobox">
                          <FormControlLabel
                            value={item.value}
                            control={<CustomRadio name="paymentType" checked={payType === item.value} onChange={handleChange} />}
                            label={
                              item.value === "NaverPay"
                                ? <img className={classes.img} alt="" src={ImgNaverPay} />
                                : <img className={classes.kakaoImg} alt="" src={ImgKakaoPay} />
                            }
                            classes={{ label: 'label' }}
                            // style={{ marginRight: 10 }}
                          />
                        </Grid>
                      )
                    }
                    return dom;
                  })
                }
              </Grid>
              <div style={{paddingLeft: 190}}>
                {
                  payType === 'Account' &&
                  (
                    <Grid item >
                      <div className="accountBox">
                        <Typography variant="body2" color="primary">
                          입금자 성명은 보내는 분으로 해주세요.<br></br>
                          신한 <b>100-031-364849</b>(예금주 : 주식회사신한로직스)
                        </Typography>
                      </div>
                    </Grid>
                  )
                }
              </div>
            </Grid>
          </RadioGroup>
          <Grid item >
            <Grid container direction="column" justify="flex-start" alignItems="center">
              {
                (
                  orderResult.isPayment === '' 
                  || orderResult.isPayment === 'N' 
                  || options.paymentStatus === 'AfterConsult'
                  || pageType === 'move'
                ) && (
                  <Typography variant="body1" color="primary" style={{marginBottom: 20}}>
                    <b>상담 후 결제를 진행해주세요.</b>
                  </Typography>
                )
              }
              <CustomButton
                className="succesBtn"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => quickerReg(pageType)}
              >접수/예약완료</CustomButton>
              <Grid container direction="row" justify="center" alignItems="center" style={{marginTop: 10}}>
                <img style={{marginRight: 10}} src={IconNotice}/>
                <Typography variant="body2" color="primary">
                  선택정보 외 추가 사항이 발생할 경우 추가요금이 발생됩니다.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default inject('qsStore', 'loginStore')(observer(PayBox));