import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { CustomButtonGroup, CustomSelectbox } from '../button';
import { Calendar, CountUpDown } from '../common';
import { timeOptions } from '../../lib/util';
import VehicleTooltipDialogM from '../common/dialog/VehicleTooltipDialogM';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 30,
  },
  selectAear: {
    paddingBottom: 10,
  },
  selbox: {
    paddingLeft: 10,
  },
  countTitle: {
    width: 87,
  },
  paddingT: {
    paddingTop: 50
  },
  pathG: {
    maxWidth: '70%',
    flexBasis: '70%',
    '& button': {
      borderRadius: 8,
      width: 105,
      margin: '0 2px',
      marginBottom: 8,
      '&  p': {
        fontSize: 14
      }
    }
  },
  updownArea: {
    marginBottom: 10,
    '& .countUpDownTitle': {
      width: 78
    },
    '& button': {
      width: '28px !important;',
      height: '28px !important;'
    },
    '& p': {
      fontWeight: 'bold !important'
    }
  }
}));

const params = {};
let rt = ["", "", ""];
function UpAndDownM({ qsStore }) {
  const cs = useStyles();
  const [dialog, setDialog] = useState(false);
  const [goodsTemp, setGoodsItem] = useState(null);
  const { handleOptions, options } = qsStore;

  useEffect(() => {
    handleOptions('vehicleType', '');
    handleOptions("loadingMethod", "Direct");
    handleOptions("dropMethod", "Direct");
  }, []);

  

  const vehicleType = [
    { title: '차량선택', value: '' },
    { title: '상담 후 선택', value: 'dd' },
    { title: '다마스', value: 'Damas' },
    { title: '라보', value: 'Labo' },
    { title: '1t', value: 'OntT' },
    { title: '1.4t', value: 'OntPointFourT' },
    { title: '2.5t', value: 'TwoPointFiveT' },
    { title: '3.5t', value: 'ThreePointFiveT' },
    { title: '5t', value: 'FiveT' },
  ];


  const upDownWayItems = [
    { title: '직접', value: 'Direct' },
    { title: '고객과 같이', value: 'WithC' },
    { title: '기사님 혼자', value: 'Alone' },
  ];


  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    params[name] = value;
    
    if (name === 'minute' || name === 'hour' || name === 'moveDate') {
      let time = moment(options.reservationTime ? options.reservationTime : new Date());
      if (name === 'moveDate') time = moment(value);

      if (params.minute) time.minute(parseInt(params.minute));
      else time.minute(0);

      if (params.hour) time.hour(parseInt(params.hour));
      else time.hour(0);

      name = "reservationTime";
      value = new Date(moment(time)).getTime();
    }

    // if (rt[0] && rt[1] && rt[2]) {
    //   name = "reservationTime";
    //   value = new Date(moment(`${rt[0]} ${rt[1]}:${rt[2]}`).format('YYYY-MM-DD HH:mm'));
    // } 
    handleOptions(name, value);
  }

  const handleTooltipOpen = () => {
    if (!params.vehicleType) return;
    setGoodsItem(params.vehicleType);
    handleOnOff();
  }

  const handleOnOff = () => {
    setDialog(!dialog);
  }

  return (
    <Grid className={cs.root} container direction="column" justify="flex-start" alignItems="flex-start">
      <VehicleTooltipDialogM open={dialog} item={goodsTemp} handleClose={handleOnOff} />
      <Grid className={cs.title} container direction="column" justify="center" alignItems="center">
        <Typography variant="body1" color="primary">
          <b>차량 및 상하차 시간</b>
        </Typography>
        <Typography variant="body2" color="textPrimary" align="center">
          <span className="pColor"><b>TIP.</b></span>
          선택방법에 따라 비용이 추가될 수 있습니다.
        </Typography>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>차량종류</b></Typography>
          <IconButton
            className={clsx('questionMark', cs.tipIcon)}
            onClick={() => handleTooltipOpen('open')}
          />
        </Grid>
        <Grid item xs={8} className={cs.selbox}>
          <FormControl fullWidth={true}>
            <CustomSelectbox
              variant="filled"
              inputProps={{ 'title': '수량 선택' }}
              value={options.vehicleType}
              name="vehicleType"
              onChange={handleChange}
            >
              {
                vehicleType.map((item) => (
                  <option key={item.value} value={item.value}>{item.title} </option>
                ))
              }
            </CustomSelectbox>
          </FormControl>
        </Grid>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>이사날짜</b></Typography>
        </Grid>
        <Grid item xs={8} className={cs.selbox}>
          <Calendar
            name="moveDate"
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
        </Grid>
        <Grid item xs={8} className={cs.selbox}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <FormControl className="time" fullWidth={false}>
              <CustomSelectbox
                variant="filled"
                inputProps={{ 'title': '시간 선택' }}
                name="hour"
                onChange={handleChange}
              >
                <option>00</option>
                {
                  timeOptions('time').map((item) => (
                    <option key={item.value} value={item.value}>{item.title}</option>
                  ))
                }
              </CustomSelectbox>
            </FormControl>
            <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500, margin: '0 10px' }}>시</Typography>
            <FormControl className="time" fullWidth={false}>
              <CustomSelectbox
                variant="filled" 
                name="minute"
                onChange={handleChange}
                inputProps={{ 'title': '분 선택' }}
              >
                <option value="00">00</option>
                {
                  timeOptions('').map((item) => (
                    <option key={item.value} value={item.value}>{item.title}</option>
                  ))
                }
              </CustomSelectbox>
            </FormControl>
            <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500, margin: '0 10px' }}>분</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="center">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>요청사항</b></Typography>
        </Grid>
        <Grid item xs={8} className={cs.selbox}>
          <TextField
            className={clsx(cs.input, "formArea nameText textBox")}
            variant="outlined"
            name="requests"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid className={clsx(cs.title, cs.paddingT)} container direction="column" justify="center" alignItems="center">
        <Typography variant="body1" color="primary">
          <b>상/하차 방법 및 추가 옵션</b>
        </Typography>
        <Typography variant="body2" color="textPrimary" align="center">
          <span className="pColor"><b>TIP.</b></span>
          선택방법에 따라 비용이 추가될 수 있습니다.
        </Typography>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>상차방법</b></Typography>
        </Grid>
        <Grid item className={clsx(cs.selbox, cs.pathG)}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <CustomButtonGroup
              name="loadingMethod"
              items={upDownWayItems}
              defaultValue={options.loadingMethod}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={cs.selectAear} container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
          <Typography variant="body2"><b>하차방법</b></Typography>
        </Grid>
        <Grid item className={clsx(cs.selbox, cs.pathG)}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <CustomButtonGroup
              name="dropMethod"
              items={upDownWayItems}
              defaultValue={options.dropMethod}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={cs.updownArea} container direction="row" justify="flex-start" alignItems="flex-start">
        <CountUpDown
          name="passengerCnt"
          title="차량동승"
          handleChange={handleChange}
        />
      </Grid>
      <Grid className={cs.updownArea} container direction="row" justify="flex-start" alignItems="flex-start">
        <CountUpDown
          name="workerCnt"
          title="인부추가"
          handleChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}

export default inject('qsStore')(observer(UpAndDownM));
