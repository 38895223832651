import palette from './palette';
import { IconQuestion, IconQuestionOn } from '../images/icon';
import { createMuiTheme } from '@material-ui/core';


let theme = createMuiTheme();
export default {
  MuiCssBaseline: {
    '@global': {
      '.mainLayout': {
        paddingTop: 160,
        paddingBottom: 140,
        background: '#fef7f5',
        [theme.breakpoints.down('sm')]: {
          paddingTop: 100,
        },
      },
      '.pColor': {
        color: palette.primary.dark
      },
      '.divider': {
        display: 'inline-block',
        width: 1,
        height: 12,
        marginLeft: 20,
        marginRight: 20,
        backgroundColor: palette.border[100],
        verticalAlign: 'middle',
      },
      '.slideBtn': {
        width: 20,
        height: 4,
        margin: '0 6px 0 0',
        padding: '0 4px 0 0',
        objectFit: 'contain',
        borderRadius: 10,
        backgroundColor: '#e4e4e9',
      },
      '.slideBtnAct': {
        backgroundColor: '#ff925c',
      },
      '.loginFormArea': {
        width: 422,
        marginLeft: '20px !important',
        marginRight: '20px !important',
      },
      '.link': {
        color: palette.text.primary,
        textDecoration: 'none',
        // borderBottom: `1px solid ${palette.text.secondary}`,
      },
      '.narrowFormArea': {
        width: 420,
        margin: '0 auto',
      },
      '.noboardShow': {
        '& .MuiInputBase-root': {
          boxShadow: 'none',
        },
        '& input': {
          border: 'none !important;',
        }
      },
      '.mainFormGroup': {
        width: '100%',
        position: 'relative',
        '& .formArea + .formArea': { marginTop: 14 },
        '& input': {
          height: 'auto',
          padding: '16px 14px 15px',
          /* border: 'none', */
          fontSize: 16,
          /* boxSizing: 'content-box', */
          '& .MuiInputBase-root': { height: 54 },
          '&.MuiOutlinedInput-inputAdornedStart': { paddingLeft: 0 },
          '& ~ .MuiOutlinedInput-notchedOutline': { borderRadius: 4 },
        },
        '& select': {
          height: 50,
          padding: '15px 38px 14px 14px',
          borderRadius: 4,
          fontSize: 16,
          '&:focus': {
            borderRadius: 4,
          },
        },
        '& .loginAppbar': {
          backgroundColor: palette.background.default,
        },
        '& .MuiButton-sizeLarge:not(.MuiButtonGroup-grouped)': {
          borderRadius: 4,
        },
      },
      '.miniBox': {
        width: 120,
        height: 120,
        // margin: '0 120px 40px 0',
        marginRight: 40,
        padding: 36,
        borderRadius: 30,
        boxShadow: '0 15px 30px 0 rgba(255, 146, 92, 0.16)',
        backgroundColor: '#ffffff',
        '& img': {
          width: 48,
          height: 48,
        },
        [theme.breakpoints.down('md')]: {
          width: 80,
          height: 80,
          padding: 15,
          borderRadius: 20,
          marginRight: 20,
        },
        
      },
      '.autoBox': {
        width: 'auto',
        height: 60,
        // padding: '16px 30px 15',
        marginRight: 16,
        borderRadius: 30,
        boxShadow: '0 10px 20px 0 rgba(255, 146, 92, 0.16)',
        backgroundColor: '#ffffff',
        '& .textArea': {
          height: '100%',
          padding: '0 30px'
        },
        [theme.breakpoints.down('md')]: {
          height: 50,
        }
      },
      '.rowBox': {
        width: 260,
        height: 60,
        marginRight: 20,
        padding: '13px 35px 15px',
        borderRadius: 30,
        boxShadow: '0 10px 20px 0 rgba(255, 146, 92, 0.16)',
        backgroundColor: '#ffffff',
        '& h6': {
          lineHeight: 1.7,
          letterSpacing: -1
        },
        [theme.breakpoints.down('md')]: {
          marginRight: 0,
        }
      },
      '.serviceBox': {
        borderRadius: 30,
        boxShadow: '0 15px 30px 0 rgba(255, 146, 92, 0.16)',
        backgroundColor: '#ffffff',
      },
      '.addressRoot': {
        marginTop: 30,
        marginBottom: 20,
        borderRadius: 30,
        boxShadow: '0 15px 30px 0 rgba(255, 146, 92, 0.16)',
        backgroundColor: '#ffffff',
        '& .addrs': {
          width: 969,
          paddingBottom: 60,
          '& .left': {
            paddingTop: 60,
            paddingLeft: 80,
            paddingRight: 80,
          }
        },
        '& .count': {
          width: 471,
          paddingBottom: 60,
          '& .right': {
            paddingTop: 60,
          }
        },
      },
      '.questionMark': {
        background: `url(${IconQuestion}) no-repeat center`,
      },
      '.questionMarkOn': {
        background: `url(${IconQuestionOn}) no-repeat center`,
      },
      '.pickDate': {
        boxShadow: 'none !important;',
        height: 50,
        backgroundColor: '#f6f7f9',
        '& input': {
          color: '#f77c40',
          paddingRight: 0,
          height: 50,
          backgroundColor: '#f6f7f9 !important',
          border: 'none !important;',
        }
      },
      '.servicePageArea': {
        width: 1440,
        minWidth: 1440,
      },
      '.textBox': {
        '& .MuiInputBase-root': {
          boxShadow: 'none',
          marginBottom: 16,
          width: 'auto',
          // height: 50,
          padding: '0 16px',
          borderRadius: 8,
          border: 'solid 1px #e4e4e9',
          backgroundColor: '#ffffff',
          '& input': {
            height: 50,
            '&::-webkit-input-placeholder': {
              color: '#cacacf'
            }
          },
          '& textarea': {
            '&::-webkit-input-placeholder': {
              color: '#cacacf'
            }
          },
          '& .MuiOutlinedInput-input': { padding: 0 },
        },
      },
    }
  },
  MuiAppBar: {
    root: {
      height: 110,
      boxShadow: 'none',

    },
    colorPrimary: {
      backgroundColor: 'transparent',
    }
  },
  MuiOutlinedInput: {
    root: {
      boxShadow: '0 5px 10px 0 rgba(247, 124, 64, 0.16);',
      borderRadius: 8,
      '&$focused $notchedOutline': {
        borderWidth: 1,
        borderColor: 'transparent',
      },
      '&$error $notchedOutline': {
        // borderWidth: 1,
      },
      '&$disabled': {
        background: '#f9f9f9',
        color: palette.text.disabled,
        '& $notchedOutline': {
          borderColor: palette.border[200],
        },
      },
    },
    multiline: {
      padding: '8px 15px 9px',
    },

    input: {
      // height: 32,
      padding: '8px 15px 9px',
      borderColor: 'transparent',
      borderRadius: 8,
      boxSizing: 'border-box',
      '&:not(:disabled)': {
        backgroundColor: '#fff',
      },
      '&::-webkit-input-placeholder': {
        fontSize: 16,
        color: '#ff925c',
        opacity: 1,
      },
      '&::-ms-clear': {
        display: 'none',
      },
    },

    notchedOutline: {
      top: 0,
      borderColor: 'transparent',
      borderRadius: 3,
      '& legend': {
        display: 'none',
      }
    },
    adornedEnd: {
      paddingRight: 0,
    },
    inputAdornedEnd: {
      paddingRight: 35,
    },
  },
  MuiFormLabel: {
    root: {
      display: 'block',
      transform: 'scale(1) !important',
      color: palette.text.primary,
      '&$focused': {
        borderColor: 'transparent',
        // color: palette.tertiary.main,
      },
      '& + .MuiInputBase-root': {
        marginTop: 0,
      },
      '&.horizontalLabel': {
        position: 'absolute',
        top: 14,
        left: -20,
        fontSize: 16,
        textAlign: 'right',
        lineHeight: 1.5,
        transform: 'translate(-100%, 0) scale(1) !important',
        '&.multiLine': {
          top: 2,
        },
      },
    },
  },
  MuiFormHelperText: {
    root: {
      color: palette.tertiary.main,
    },
    contained: {
      marginLeft: 0,
      marginRight: 0,
      // background: `url(${IconInfoMsg}) no-repeat 0 1px`,
      fontSize: 16,
      lineHeight: '1.88',
      color: '#ff0000',
    }
  },
  MuiFormControlLabel: {
    root: {
      marginTop: -9,
      marginBottom: -9,
      marginRight: 0,
      marginLeft: -8,
      '&.allAgree': {
        marginBottom: 4,
        '& .MuiFormControlLabel-label': {
          fontWeight: '700',
          fontSize: 16,
          color: '#000',
        },
      },
    },
  },
  MuiTabs: {
    root: {
      minWidth: 1440,
      height: 90,
      boxShadow: '0 15px 30px 0 rgba(255, 146, 92, 0.16)',
      backgroundColor: '#ffffff',
    },
    flexContainer: {
      height: '100%'
    },
    indicator: {
      display: 'none'
    },
  },
  MuiTab: {
    textColorPrimary: {
      fontSize: 32,
      lineHeight: 0.88,
      letterSpacing: -1.6,
      textAlign: 'center',
      color: '#cacacf',
      '&$selected': {
        fontWeight: 'bold',
        backgroundColor: '#ff925c',
        borderRadius: '25px 25px 0 25px',
        color: '#ffffff',
      },
    },
  },
  MuiNativeSelect: {
    root: {
      '&$disabled': {
        borderColor: palette.border[200],
        backgroundColor: palette.bgColor.disabled,
        color: palette.text.disabled,
        '& + $icon': {
          color: palette.text.disabled,
        },
        '&:hover, &:focus': {
          background: palette.bgColor.disabled,
          borderColor: palette.border[200],
        }
      },
    },
    select: {
      height: 21,
      paddingLeft: 10,
      borderRadius: 4,
      backgroundColor: '#fff',
      color: palette.black,
    },
    filled: {
      height: 19,
      paddingRight: 29,
      // border: `1px solid ${palette.bgColor[100]}`,
      borderRadius: 6,
      padding: '13px 16px',
      backgroundColor: '#f6f7f9',
      fontWeight: '500',
      color: '#161617',
      '&:hover': {
        borderColor: palette.tertiary.main,
        backgroundColor: palette.bgColor[100],
      },
      '&:focus': {
        borderColor: palette.tertiary.main,
        borderRadius: 4,
        backgroundColor: palette.bgColor[100],
      },
      '&$disabled': {
        borderColor: palette.bgColor.disabled,
        '&:hover': {
          borderColor: palette.bgColor.disabled,
        },
        '&:focus': {
          borderColor: palette.bgColor.disabled,
        },
      },
    },
    outlined: {
      height: 32,
      padding: '0 32px 0 13px',
      border: `1px solid ${palette.border[100]}`,
      borderRadius: 3,
      boxSizing: 'border-box',
      '&:hover': {
        borderColor: palette.tertiary.main,
      },
      '&:focus': {
        borderColor: palette.tertiary.main,
        backgroundColor: '#fff',
      },
    },
    icon: {
      color: palette.black
    },
    iconFilled: {
      right: 2,
    },
  },
  MuiTooltip: {
    tooltip: {
      // width: 333,
      // height: 130,
      objectFit: 'contain',
      boxShadow: '0 5px 15px 0 rgba(202, 202, 207, 0.36)',
      backgroundColor: '#ffffff',
    },
    arrow: {
      color: '#ffffff',
      boxShadow: '0 5px 15px 0 rgba(202, 202, 207, 0.36)',
    }
  },
  MuiTypography: {
    colorSecondary: {
      color: "#ffffff"
    },
  }
};