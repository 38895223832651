import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Icon, Typography } from '@material-ui/core';
import { CustomButton } from '../button';
import { IconDropdownMore } from '../../static/images/icon';
import GuideMessageM from '../message/GuideMessageM';

const useStyles = makeStyles((theme) => ({
  inputBox: {
    maxWidth: 500,
    padding: '0 30px',
    margin: '60px 0',
  },
  imgPin: { width: 30, height: 30 },
}));
function PolicyMain({ handleClick }) {
  const cs = useStyles();
  return (
    <React.Fragment>
      <Grid item>
        <GuideMessageM 
          title="약관/정책 보기"
          subTitle="퀴노스 이용약관과 개인정보처리방침을 확인하세요."
        />
      </Grid>
      <Grid className={cs.inputBox} container direction="column" justify="center" alignItems="flex-start">
        <CustomButton
          // disabled
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => handleClick('service')}
          endIcon={
            <Icon className={cs.imgPin}><img alt="" src={IconDropdownMore} /></Icon>
          }
        >서비스 이용약관</CustomButton>
        <Box height={20} />
        <CustomButton
          // disabled
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => handleClick('privacy')}
          endIcon={
            <Icon className={cs.imgPin}><img alt="" src={IconDropdownMore} /></Icon>
          }
        >개인정보처리방침</CustomButton>
      </Grid>
    </React.Fragment>
  );
}

export default PolicyMain;