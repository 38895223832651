export const OPTIONS = {
  "Document": "서류",
  "SmallBox": "소박",
  "HeavyBox": "중박스",
  "Etc": "기타",
  "OneRoom": "원룸",
  "SmallFamilyMoving": "가정이사(작은)",
  "HeavyFamilyMoving": "가정이사(많)",
  "Office": "사무",
  "Palette": "팔레트",
  "Box": "소박",
  "Fixtures": "가구,집기",
  "Etc": "기타",
  "Bike": "바이크",
  "Damas": "다마스",
  "Labo": "라보",
  "Ben": "벤",
  "OntT": "1t 트럭",
  "OntPointFourT": "1.4t 트럭",
  "TwoPointFiveT": "2.5t 트럭",
  "ThreePointFiveT": "3.5t 트럭",
  "FiveT": "5t 트럭",
  "EightT": "8t 트럭",
  "ElevenT": "11t 트럭",
  "Cargo": "카고(기본)",
  "Whistle": "호루",
  "WingBody": "윙바디",
  "Lift": "리프트",
  "LiftWing": "리프트윙",
  "RefrigerationTower": "냉장탑",
  "FreezingTower": "탑",
  "PlusCargo": "플러스 카고",
  "PlusWingBody": "플러스 윙바디",
  "PlusRotor": "플러스 축차",
  "PlusSequentialWingBody": "플러스 축차 윙바디",
  "Bike": "오토바이",
  "Damas": "다마스",
  "Labo": "라보",
  "Ben": "라보",
  "OntT": "1t",
  "OntPointFourT": "1.4t",
  "TwoPointFiveT": "2.5t",
  "ThreePointFiveT": "3.5t",
  "ThreeT": "5t",
  "EightT": "8t",
  "ElevenT": "11t",
  "Stairs": "계단",
  "AloneHandmade": "수작업(기사만)",
  "WithHandmade": "수작업(같이)",
  "Morning": "조조",
  "Night1": "야간1",
  "Night2": "야간2",
  "LateNight1": "심야1",
  "LateNight2": "심야2",
  "Holiday": "휴일",
  "Express1": "급송1",
  "Express2": "급송2",
  "Reservation": "예약",
  "SnowRain": "눈비",
  "NationalHoliday": "명절",
  "Overnight": "1박",
  "OneWay": "편도",
  "RoundTrip": "왕복",
  "LayOver": "경유",
  "Surcharge": "할증",
  "Worker": "인부",
  "Passenger": "동승",
  "Lift": "리프트",
  "WingBody": "윙바디",
  "RefrigerationTower": "냉장탑",
  "FreezingTower": "탑",
  "WithC": "고객과같이 상하차",
  "Alone": "기사님혼자 상하차",
  "Direct": "직접",
  "WithC": "고객과같이",
  "Alone": "기사님혼자",
  "Elevator": "엘리베이터",
  "Stairs": "계단",
  "Private": "개인",
  "Public": "법인",
  "QuickBikeHeavyBox": "중박스",
  "QuickBikeGreateBox": "대박스"
};