import React from 'react';
import { Box, Grid, Button, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { LogoG } from '../../static/images';
import { 
  IconSnsBlog, IconSnsFacebook, 
  IconSnsInstagram, IconSnsKakao 
} from '../../static/images/icon';

function Footer({ type }) {
  const classes = useStyles();

  const handlePage = (path) => {
    window.open(path, '_blank');
  }
  return (
    <Box component="footer" className={classes.footer}>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.notice_area}>
        {
          type === 'loginAppbar'
            ? (
              <Grid container direction="row" justify="space-between" alignItems="center" className="notice_root">
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  <Typography className="txtDesc">
                    <a href="#" className="link">접수하기</a>
                  </Typography>
                  <Typography className="txtDesc">
                    <a href="#" className="link">서비스 소개</a>
                  </Typography>
                  <Typography className="txtDesc">
                    <a href="#" className="link">마이페이지</a>
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-start"
                  className="sns_btn"
                >
                  <IconButton className="btn_facebook" onClick={() => handlePage("https://www.facebook.com/quinors5480/?")}/>
                  <IconButton className="btn_kakao" onClick={() => handlePage("https://pf.kakao.com/_AsExjC")} />
                  <IconButton className="btn_blog" onClick={() => handlePage("https://blog.naver.com/shinhan5480")} />
                  <IconButton className="btn_instagram" onClick={() => handlePage("https://www.instagram.com/quinors_sh/")} />
                </Grid>
              </Grid>

            )
            : (
              <Grid className="sns_btn main_foot" container direction="row" justify="center" alignItems="center">
                <IconButton className="btn_facebook" onClick={() => handlePage("https://www.facebook.com/quinors5480/?")} />
                <IconButton className="btn_kakao" onClick={() => handlePage("https://pf.kakao.com/_AsExjC")} />
                <IconButton className="btn_blog" onClick={() => handlePage("https://blog.naver.com/shinhan5480")} />
                <IconButton className="btn_instagram" onClick={() => handlePage("https://www.instagram.com/quinors_sh/")} />
              </Grid>
            )
        }
      </Grid>
      
      <Grid container direction="column" justify="center" alignItems="center" className={classes.footer_inner}>
        <Grid container direction="row" justify="space-between" alignItems="center" className="footer_inner_root">
          <Typography component="div" color="textSecondary" align="center" className="footer_text">
            <div >
              <b>주식회사 신한로직스</b>
              <span className="divider" />
              <b>대표</b> 유재영
              <span className="divider" />
              <b>사업자등록번호</b> 436-86-00203
              <span className="divider" />
              <b>통신판매업</b> 제2021-서울중구-1038호
              <span className="divider" />
              <b>화물자동차운송주선사업허가증</b> 제020134호
            </div>
            <div>
              <span className="pColor"><b>02-1588-5480</b></span>
              <span className="divider" />
              <b>주소</b> 서울시 중구 통일로 86 바비엥3 407호
              <span className="divider" />
              quinors@quinors.com
            </div>
          </Typography>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
            className="footer_logo"
          >
            <img src={LogoG} alt="" />
          </Grid>
        </Grid>
        <Grid style={{paddingTop: 30}} container direction="row" justify="space-between" alignItems="center" className="footer_inner_root">
          <Typography className="footer_text" color="textSecondary" >Copyright©QUINORS All rights reserved</Typography>
        </Grid>
        
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  footer: {
    left: 0,
    bottom: 0,
    width: '100%',
    minWidth: 1067,
    height: 431,
    // borderTop: `1px solid #e4e4e9`,
    backgroundColor: '#fff',
    // position: 'fixed'
  },
  notice_area: {
    height: 80,
    width: '100%',
    backgroundColor: '#fafafb',
    padding: '29px 0',
    '& .notice_root': {
      maxWidth: 1064,
      flexWrap: 'unset'
    },
    '& .txtDesc': {
      width: 142,
      fontSize: 15,
      fontWeight: 'bold',
      padding: '0 !important',
      '& .MuiButton-label': {
        alignItems: 'start',
        justifyContent: 'start'
      }
    },
    '& .main_foot': {
      '& button': {
        width: 30,
        height: 30,
      },
      '& .btn_kakao': {
        margin: '0 100px',
      },
      '& .btn_blog': {
        marginRight: 100,
      }
    },
    '& .sns_btn': {
      '& button': {
        width: 26,
        height: 26,
      },
      '& .btn_facebook': { background: `url(${IconSnsFacebook}) no-repeat center`, },
      '& .btn_kakao': { background: `url(${IconSnsKakao}) no-repeat center`, margin: '0 30px'},
      '& .btn_blog': { background: `url(${IconSnsBlog}) no-repeat center`, marginRight: 30},
      '& .btn_instagram': { background: `url(${IconSnsInstagram}) no-repeat center`},
    },
  },
  footer_inner: {
    paddingTop: 46,
    '& .footer_inner_root': {
      maxWidth: 1140,
    },
    '& .footer_text': {
      textAlign: 'start',
      fontSize: 14,
      '& div': {
        paddingBottom: 12
      }
    },
    '& .footer_logo': {
      width: 128,
      marginTop: 16,
      marginRight: 30
    }
  },
  
}));

export default Footer;