import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
// import moment from 'moment';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { CustomSelectbox } from '../components/button';

import { IconCalendarW, IconBackG } from '../static/images/icon';
import { UseCard } from '../components/common';
import GuideMessageM from '../components/message/GuideMessageM';
import { dateGroupSet } from '../lib/util';
import { useTheme } from '@material-ui/styles';

import ko from 'date-fns/locale/ko';

const useStyles = makeStyles((theme) => ({
  inputBox: {
    maxWidth: 500,
    padding: '0 30px',
  },
  selectbox: {
    width: '85%',
    marginRight: 18,
    borderRadius: 8,
    boxShadow: '0 5px 10px 0 rgba(247, 124, 64, 0.16)',
    '& select': { height: 34 },
    [theme.breakpoints.down('sm')]: {
        width: '80%',
    },
  },
  calendar: {
    background: `url(${IconCalendarW}) no-repeat center`, 
    width: 45,
    height: 45,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0 5px 10px 0 rgb(247 124 64 / 16%)',
    },
  },
  dateIcon: {
    '& .react-datepicker-popper': {
      left: '-250px !important'
    }
  },
  backBtn: {
    '&:hover': {
      background: 'transparent'
    }
  },
  title: {
    width: '100%',
    textAlign: 'center'
  },
  selectboxt: {
    '& select': {
      color: '#ff925c'
    }
  }
}));

function DetailsOfUsagePage({ myPageStore }) {
  const cs = useStyles();
  const history = useHistory();
  const { useListSel: listSel, useList } = myPageStore;
  const [startDate, setStartDate] = useState(new Date());
  const dateItem = [
    { title: '1일', value: '1d' },
    { title: '60일', value: '2M' },
    { title: '90일', value: '3M' },
  ];

  useEffect(() => {

    const result = dateGroupSet('1d');
    listSel({
      startDate: result.getTime(),
      endDate: new Date().getTime(),
    });
  }, []);

  const handleDateChange = (date) => {
    setStartDate(date);
    
    onSubmit(new Date(date), new Date(date));
  }
  
  const handleChange = (e) => {
    const value = e.target.value;
    const result = dateGroupSet(value);
    onSubmit(result)
    
  }

  const onSubmit = (date, eDate) => {
    listSel({
      startDate: date.getTime(),
      endDate: eDate ? eDate.getTime() : new Date().getTime(),
    });
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <Grid className={clsx("mainLayout", cs.root)} container direction="column" justify="center" alignItems="center">
        <Grid className={cs.inputBox} container direction="column" justify="center" alignItems="flex-start">
          {
            !matches && (
              <IconButton className={cs.backBtn} onClick={() => { history.goBack() }}>
                <img src={IconBackG} alt="" />
                <Typography variant="body1" style={{ color: '#ACACB2', marginBottom: 3 }} align="left" gutterBottom>뒤로가기</Typography>
              </IconButton>
            )
          }
          <Grid item className={cs.title}>
            <GuideMessageM
              title="사용내역 조회"
              subTitle="조회기간은 90일까지 제공합니다."
            />
          </Grid>
        </Grid>
        <Box height={60} />
        <Grid className={cs.inputBox} container direction="column" justify="center" alignItems="flex-start">
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <FormControl fullWidth className={cs.selectbox} fullWidth={false}>
              <CustomSelectbox 
                variant="standard" 
                onChange={handleChange}
                className={cs.selectboxt}
                inputProps={{ 'title': '분 선택' }}
              >
                {
                  dateItem.map((item) => (
                    <option key={item.value} value={item.value}>{item.title}</option>
                  ))
                }
              </CustomSelectbox>
            </FormControl>
            <Grid item className={cs.dateIcon}>
              <DatePicker
                locale={ko}
                selected={startDate}
                onChange={date => handleDateChange(date)}
                customInput={<IconButton className={cs.calendar} />}
              />
              
            </Grid>
          </Grid>
          <Box height={40} />
          {
            useList.map((item, index) => (
              <React.Fragment key={index}>
                <UseCard item={item}/>
                <Box height={30} />
              </React.Fragment>
            ))
          }
          
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject('myPageStore')(observer(DetailsOfUsagePage));