import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 1436,
    position: 'relative',
    '& .sliderBtnAera': {
      marginTop: 40,
      position: 'absolute',
      bottom: 50,
      paddingLeft: '50%'
    },
    '& .slick-prev:before, .slick-next:before': {
      color: '#ff925c',
      fontSize: 30
    },
    '& .slick-next': {
      right: '0 !important;',
      zIndex: 10
    },
    '& .slick-prev': {
      left: '0 !important;',
      zIndex: 10
    }
  },
  slideTextArea: {
    paddingTop: 120,
  }
}));

function MainSliders({ qsStore }) {
  const classes = useStyles();
  const { banner, bannerSelCall } = qsStore;
  const [sliderIdx, setSliderIdx] = useState(0);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: true,
  };

  const handleChange = (index) => {
    setSliderIdx(index);
  }

  useEffect(() => {
    bannerSelCall();
  }, []);

  const handleLink = (item) => {
    if (item.linkUrl) {
      window.open(item.linkUrl);
    }
  }

  if (!banner || banner.length === 0 ) return null;

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <div className={classes.root} >
        <Grid container>
          <Grid item xs={12}>
           
            <Slider 
              className={classes.slider} 
              afterChange={handleChange}
              {...settings}
            >
              {
                banner && banner.length > 0 && (
                  banner.map((item, index) => (
                    <React.Fragment  key={index}>
                      {/* <div style={{ float: 'left', marginTop: 100, maxWidth: 500 }}>
                        <Typography variant="h1" color="textPrimary" align="left">
                          빠르게<br></br>
                        그리고 안전하게
                      </Typography>
                        <Typography variant="body1" color="textSecondary">
                          기업 전무 스마트 퀵서비스 퀴노스입니다. 맡겨만주세요 안전하게 배송하겠습니다.
                      </Typography>
                      </div> */}
                      <img alt="" src={item.imageUrl} onClick={() => handleLink(item)} />
                    </React.Fragment>
                  ))
                )
              }
            </Slider>
          </Grid>
        </Grid>
        <Grid className="sliderBtnAera" container direction="row" justify="flex-start" alignItems="center">
          {
            banner && banner.length > 0 && (
              banner.map((item, index) => (
                <div key={index} className={clsx("slideBtn", sliderIdx === index && "slideBtnAct")} />
              ))
            )
          }
        </Grid>
      </div>
    </Grid>
  );
}

export default inject('qsStore')(observer(MainSliders));