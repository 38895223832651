import React, { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { FormControlLabel, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';

import { ImgArrowDown } from '../../../static/images';
import { ButtonClearInput, CustomCheckbox } from '../../button';

import { makeStyles } from '@material-ui/core/styles';
import NumberOfFloors from './NumberOfFloors';
import DaumPostcodeDialog from '../dialog/DaumPostcodeDialog';

const useStyles = makeStyles((theme) => ({
  name: { 
    width: 120,
    '& .MuiInputBase-root': {
      padding: '0 12px !important'
    }
  },
  phone: {
    // width: 210,
    marginLeft: 5
  },
  arrowIcon: {
    width: '100%',
    textAlign: 'center',
    '& img': { height: 50 }
  },
  requesterPhone: {
    '& .MuiFormControlLabel-root': {
      width: 245,
      marginLeft: 0,
    }
  },
  termsText: {
    '& a': {
      paddingLeft: 6,
      color: '#f77c40',
    },
  },
  floors: {
    marginBottom: 15,
    '& .floorSelectbox': { width: '100px !important;' }
  },
  textfieldMul: {
    '& textarea': {
      paddingTop: '10px !important;',
    }
  }
}));

const params = {};

function AddressM({ type, cmStore, qsStore, loginStore }) {
  const cs = useStyles();
  const history = useHistory();
  const [same, setSame] = useState(false);
  const [addrOpen, setAddrOpen] = useState(false);
  const [requestTel, setStartTel] = useState("");
  const [addresstype, setAddressType] = useState("to");
  const { userInfo } = loginStore;
  const { handleOptions, orderCalAPI } = qsStore;
  const { toAddress, fromAddress, etcAddress, setEtcAddress, initialAddress } = cmStore;

  useEffect(() => {
    let orderType = "";
    if (type === 'quick') orderType = 'Quick';
    else if (type === 'cargo') orderType = 'Freight';
    else orderType = 'Move';
    handleOptions("orderType", orderType);

    if (!etcAddress.startAddrDtl) initialAddress();
  }, []);

  useEffect(() => {
    if (toAddress && fromAddress) {
      orderCalAPI();
    }
  }, [toAddress, fromAddress])


  const handleAddrOnOff = useCallback((type) => {
    setAddressType(type);
    setAddrOpen(!addrOpen)
  }, [addrOpen]);

  const handleChange = (e, checked) => {
    let value = e.target.value;
    const name = e.target.name;
    params[name] = value;

    if (name === "agree") {
      if (checked) value = "Y"
      else value = "N"
    }
    setEtcAddress(name, value);
    handleOptions(name, value);


    if (name === "startTel" && same) {
      setStartTel(value);

    } else if (name === "same") {
      if (!same) {
        setStartTel(etcAddress.startTel);
        handleOptions('requestTel', etcAddress.startTel);
      }
      setSame(!same);

    } else if (name === "requestTel") {
      setStartTel(value);
    }

  }

  const handlePage = (e, path) => {
    e.preventDefault();
    if (path === '/policy') {
      history.push({
        pathname: path,
        state: { type: 'privacy' }
      })
    } else {
      history.push(path);
    }
  }
  
  return (
    <Grid container direction="column" justify="center" alignItems="flex-start">
      <DaumPostcodeDialog type={addresstype} open={addrOpen} handleClose={handleAddrOnOff} />
      <Typography variant="body2" color="textPrimary" gutterBottom><b>출발지</b></Typography>
      <TextField
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end"><ButtonClearInput /></InputAdornment>
          ),
        }}
        placeholder="주소를 검색하세요."
        variant="outlined"
        className="formArea textBox"
        value={toAddress}
        onClick={() => handleAddrOnOff('to')}
      />
      <TextField
        fullWidth
        className={clsx("formArea textBox", cs.textfieldMul)}
        placeholder={`상세 주소를 입력하세요.
(상호나 부서명이 있으면 함께 기록해주세요)`}
        multiline
        // rowsMax={2}
        rows={3}
        variant="outlined"
        name="startAddrDtl"
        value={etcAddress.startAddrDtl}
        onChange={handleChange}
      />
      {
        (type === 'quick' || type === 'cargo') && (
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={0}> 
            <Grid item xs={5}>
              <TextField
                className={clsx(cs.name, "formArea nameText textBox")}
                placeholder="이름(보내는분)"
                variant="outlined"
                name="startNm"
                value={etcAddress.startNm}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                className={clsx(cs.phone, "formArea phoneText textBox")}
                placeholder="연락처"
                variant="outlined"
                name="startTel"
                value={etcAddress.startTel}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )
      }
      {
        type === 'move' && (
          <div className={cs.floors}>
            <NumberOfFloors name="moveStartType" subName="startFloor" />
          </div>
        )
      }
      <div className={cs.arrowIcon}>
        <img alt="" src={ImgArrowDown} />
      </div>
      <Typography variant="body2" color="textPrimary" gutterBottom><b>도착지</b></Typography>
      <TextField
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end"><ButtonClearInput /></InputAdornment>
          ),
        }}
        placeholder="주소를 검색하세요."
        variant="outlined"
        className="formArea textBox"
        value={fromAddress}
        onClick={() => handleAddrOnOff('from')}
      />
      <TextField
        fullWidth
        className={clsx("formArea textBox", cs.textfieldMul)}
        placeholder={`상세 주소를 입력하세요.
(상호나 부서명이 있으면 함께 기록해주세요)`}
        multiline
        // rowsMax={2}
        rows={3}
        variant="outlined"
        name="endAddrDtl"
        value={etcAddress.endAddrDtl}
        onChange={handleChange}
      />
      {
        (type === 'quick' || type === 'cargo') && (
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item xs={5}>
              <TextField
                className={clsx(cs.name, "formArea nameText textBox")}
                placeholder="이름(받는분)"
                variant="outlined"
                name="endNm"
                value={etcAddress.endNm}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                className={clsx(cs.phone, "formArea phoneText textBox")}
                placeholder="연락처"
                variant="outlined"
                name="endTel"
                value={etcAddress.endTel}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )
      }
      {
        type === 'move' &&
          (
            <div className={cs.floors}>
              <NumberOfFloors name="moveEndType" subName="endFloor" />
            </div>
          )
      }
      {
        type === 'quick' && (
          <>
            <TextField
            fullWidth
            name="requestTel"
            value={requestTel}
            onChange={handleChange}
            className={clsx(cs.requesterPhone, "textBox")}
            InputProps={{
              endAdornment: (
                type === 'quick'
                  ? (
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <CustomCheckbox 
                          name="same"
                          onChange={handleChange}
                        />
                      }
                      label={
                        <Typography variant="body2" color="primary">
                          보내는분 동일
                        </Typography>
                      }
                    />
                  )
                  : (
                    <></>
                  )
              ),
            }}
            placeholder="요청자 연락처"
            variant="outlined"
          />
        </>
        )
      }
      {
        (type === 'move') && (
          <>
            <TextField
              fullWidth
              name="startTel"
              onChange={handleChange}
              className={clsx(cs.requesterPhone, "textBox")}
              placeholder="요청자 연락처"
              variant="outlined"
            />
          </>
        )
      }
      {
        !userInfo && (
          <Grid item>
            <FormControlLabel
              control={
                <CustomCheckbox
                  name="agree"
                  onChange={handleChange}
                />
              }
              label={
                <Typography className={cs.termsText} variant="body2" color="textPrimary">
                  개인정보 수집 이용 동의
                      <a href="#" onClick={(e) => handlePage(e, '/policy')}>상세보기</a>
                </Typography>
              }
            />
          </Grid>
        )
      }
    </Grid>
  );
}

export default inject('cmStore', 'qsStore', 'loginStore')(observer(AddressM));
