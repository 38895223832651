import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { useMediaQuery, useTheme } from '@material-ui/core';

import QuickServiceView from '../components/QuickServicePage/QuickServiceView';
import QuickServiceViewM from '../components/QuickServicePage/QuickServiceViewM';


function QuickServicePage({ qsStore }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { addressSet, initialOption } = qsStore;
  
  useEffect(() => {
    addressSet();

    return () => {
      initialOption();
    }
  }, [])

  return (
    <React.Fragment>
      {
        matches ? <QuickServiceViewM /> : <QuickServiceView />
      }
    </React.Fragment>
  );
}

export default inject('qsStore')(QuickServicePage);