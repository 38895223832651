import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Grid, Typography } from '@material-ui/core';

import CustomRadio from '../../button/CustomRadio';
import { ImgKakaoPay, ImgNaverPay } from '../../../static/images';
import { CustomButton } from '../../button';
import { IconNotice } from '../../../static/images/icon';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 30,
  },
  radioTitle: {
    lineHeight: 1.2
  },
  img: {
    width: 80,
  },
  kakaoImg: {
    width: 60,
    paddingTop: 5
  },
  marginB: {
    marginBottom: 10,
  },
  accountBox: {
    width: '100%',
    height: 107,
    marginTop: 10,
    padding: '13px 20px',
    borderRadius: 6,
    backgroundColor: '#fef7f5',
  },
  succesBtn: {
    marginTop: 10,
  },
  noticeImg: {
    marginLeft: 5,
    marginTop: 5,
    width: 20
  }
}));

function PayBoxM({ qsStore, loginStore, pageType }) {
  const cs = useStyles();
  const { userInfo } = loginStore;
  const { quickerReg, orderResult, handleOptions, options } = qsStore;
  const [payType, setPayType] = useState(options.paymentType);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setPayType(value);
    handleOptions(name, value);
  }

  const payItems = [
    { title: '신용/체크카드', value: 'Card', dim: false },
    { title: '계좌 이체', value: 'Account', dim: false },
    { title: '법인후불', value: 'CorporateAfter', dim: userInfo?.userType !== "Company" },
    { title: '네이버페이', value: 'NaverPay', dim: false },
    { title: '카카오페이', value: 'KakaoPay', dim: false },
  ]

  return (
    <Grid className={cs.root} container direction="column" justify="flex-start" alignItems="flex-start">
      <Grid className={cs.title} container direction="column" justify="center" alignItems="center">
        <Typography variant="body1" color="primary">
          <b>결제</b>
        </Typography>
        <Typography variant="body2" color="textPrimary" align="center">
          <span className="pColor"><b>TIP.</b></span>
          기업회원의 경우 등록된 카드 또는 월단위 결제로<br></br>진행됩니다.
        </Typography>
      </Grid>
      <Grid container>
          {
            payItems.map((item, index) => {
              let dom = (
                <Grid key={index} className={cs.marginB} item xs={6} sm={3}>
                  <FormControlLabel
                    value={item.value}
                    disabled={item.dim}
                    control={<CustomRadio name="paymentType" checked={payType === item.value} onChange={handleChange} />}
                    label={
                      <Typography className={cs.radioTitle} variant="body2" color="textPrimary">
                        <b>{item.title}</b>
                      </Typography>
                    }
                    classes={{ label: 'label' }}
                    style={{ marginRight: 10 }}
                  />
                </Grid>
              )
              if (item.value === 'NaverPay' || item.value === 'KakaoPay') {
                dom = (
                  <Grid key={index} className={cs.marginB} item xs={6} sm={3}>
                    <FormControlLabel
                      value={item.value}
                      control={<CustomRadio name="paymentType" checked={payType === item.value} onChange={handleChange} />}
                      label={
                        item.value === "NaverPay"
                          ? <img className={cs.img} alt="" src={ImgNaverPay} />
                          : <img className={cs.kakaoImg} alt="" src={ImgKakaoPay} />
                      }
                      classes={{ label: 'label' }}
                      style={{ marginRight: 10 }}
                    />
                  </Grid>
                )
              }
              return dom;
            })
          }
      </Grid>
      {
        payType === 'Account' && (
          <div className={cs.accountBox}>
            <Typography variant="body2" color="primary">
              입금자 성명은 보내는 분으로 해주세요.<br></br>
              신한 <b>100-031-364849</b>(예금주 : 주식회사신한로직스)
            </Typography>
          </div>
        )
      }
      <Grid container direction="row" justify="center" alignItems="center" style={{marginTop: 20}}>
        {
          (
            orderResult.isPayment === ''
            || orderResult.isPayment === 'N'
            || options.paymentStatus === 'AfterConsult'
            || pageType === 'move'
          ) && (
            <Typography variant="caption" color="primary" align="center">
              <b>상담 후 결제를 진행해주세요.</b>
            </Typography>
          )
        }
      </Grid>
      <CustomButton
        className={cs.succesBtn}
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => quickerReg(pageType)}
      >접수/예약완료</CustomButton>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ marginTop: 10 }}>
        <img className={cs.noticeImg} style={{ marginRight: 10 }} src={IconNotice} />
        <Typography variant="caption" color="primary">
          선택정보 외 추가 사항이 발생할 경우 추가요금이<br></br> 발생됩니다.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default inject('qsStore', 'loginStore')(observer(PayBoxM));
