import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import GuideMessage from '../message/GuideMessage';
import { CustomButton } from '../button';

function Success(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" wrap="nowrap" className={classes.signUpSuccess}>
        <Grid item className={classes.signUpForm}>
          <Grid container direction="column" justify="center" className={clsx("narrowFormArea", classes.textField)}>
            <Grid item>
              <Grid container direction="column" justify="center" alignItems="center">
                <GuideMessage
                  title="환영합니다."
                  resultTitle="환영합니다."
                  subTitle="맡겨만 주세요. 안전하게 배송하겠습니다."
                />
              </Grid>
            </Grid>
          </Grid>
          <CustomButton
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => { history.push('/login')}}
          >
            로그인 하기
          </CustomButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  signUpSuccess: {
    paddingTop: 200,
    paddingBottom: 150
  },
  signUpForm: {
    width: 420,
    marginLeft: 20,
    marginRight: 20,
  },
  textField: {
    paddingBottom: 80
  }
}));

export default Success;