import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';

import { ServiceTitle, SelectTime } from '../common/eachService';
import CustomSelectGroup from '../common/CustomSelectGroup';
import { CustomButtonGroup, CustomCheckbox } from '../button';
import CountUpDown from '../common/CountUpDown';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '60px 80px 60px 80px',
    '& .csgSelectbox': {
      width: 449,
    },
    '& .mainFormGroup': {
      width: 449,
      '& .MuiInputBase-root': {
        boxShadow: 'none',
      },
      '& input': {
        border: 'solid 1px #e4e4e9',
        backgroundColor: '#ffffff',
      }
    },
    '& .upmargin': {
      marginTop: 50,
      marginBottom: 20,
    }
  },
  mb30: {
    marginBottom: 30
  },
  mb20: {
    marginBottom: 20
  }
}));

const params = {};
function UpAndDown({ qsStore }) {
  const classes = useStyles();
  const { handleOptions, options, orderCalAPI } = qsStore;
  const [upTime, setUpTime] = useState(false);
  const [downTime, setDownTime] = useState(false);

  useEffect(() => {
    handleOptions('vehicleType', 'OntT');
    handleOptions("loadingMethod", "Direct");
    handleOptions("dropMethod", "Direct");
    handleOptions("route", "OneWay");
    handleOptions("vehicleOptionType", "Cargo");
  }, []);

  useEffect(() => {
    if (options.paletteCnt > 2) {
      handleOptions("vehicleType", "TwoPointFiveT")
    }
    if (options.paletteCnt > 3) {
      handleOptions("vehicleType", "ThreePointFiveT")
    }
    if (options.paletteCnt > 4) {
      handleOptions("vehicleType", "FiveT")
    }
    if (options.paletteCnt > 5) {
      handleOptions("vehicleType", "EightT")
    }
    if (options.paletteCnt > 16) {
      handleOptions("vehicleType", "ElevenT")
    }
  }, [options.paletteCnt])

  const vehicleType = [
    { title: '차량종류선택', value: '' },
    { title: '1t트럭', value: 'OntT', disabled: options.paletteCnt > 2 },
    { title: '1.4t트럭', value: 'OntPointFourT', disabled: options.paletteCnt > 2 },
    { title: '2.5t트럭', value: 'TwoPointFiveT', disabled: options.paletteCnt > 3 },
    { title: '3.5t트럭', value: 'ThreePointFiveT', disabled: options.paletteCnt > 4 },
    { title: '5t트럭', value: 'FiveT', disabled: options.paletteCnt > 4 },
    { title: '8t트럭', value: 'EightT', disabled: options.paletteCnt > 16 },
    { title: '11t트럭', value: 'ElevenT' },
  ];

  const options2 = [
    { title: '차량종류선택', value: '' },
    { title: '카고(기본)', value: 'Cargo' },
    { title: '호루', value: 'Whistle' },
    { title: '윙바디', value: 'WingBody' },
    { title: '리프트', value: 'Lift' },
    { title: '리프트윙', value: 'LiftWing' },
    { title: '냉장탑', value: 'RefrigerationTower' },
    { title: '탑', value: 'FreezingTower' },
    { title: '플러스 카고', value: 'PlusCargo' },
    { title: '플러스 윙바디', value: 'PlusWingBody' },
    { title: '플러스 축사', value: 'PlusRotor' },
    { title: '플러스 축차 윙바디', value: 'PlusSequentialWingBody' },
  ];

  const upDownTimeItems = [
    { title: '바로 상차', value: 'now'},
    { title: '예약', value: 'book'},
  ];

  const upDownTimeItems2 = [
    { title: '바로 하차', value: 'now'},
    { title: '예약', value: 'book'},
  ];

  const upDownWayItems = [
    { title: '직접', value: 'Direct' },
    { title: '고객과 같이', value: 'WithC', tip: '별도 요금이 발생합니다.' },
    { title: '기사님 혼자', value: 'Alone', tip: '별도 요금이 발생합니다.' },
  ];

  const deliveryItem = [
    { title: '편도', value: 'OneWay'},
    { title: '왕복', value: 'RoundTrip'},
  ];

  const handleChange = async (e, checked) => {
    let name = e.target.name;
    let value = e.target.value;
    const id = e.target.id;

    if (name === "upTime") {
      if (value === 'book') {
        setUpTime(true);
      } else {
        setUpTime(false);
        handleOptions("reservationTime", "");
      }
    } else if (name === "downTime") {
      if (value === 'book') {
        setDownTime(true);
      } else {
        setDownTime(false);
        handleOptions("reservationDownTime", "");
      }
    } else {
      params[name] = value;
    }

    if (name === 'minute' || name === 'hour' || name === 'moveDate') {
      let time = moment(options.reservationTime ? options.reservationTime : new Date());
      if (name === 'reservationTime' || id === 'reservationTime') {
        time = moment(options.reservationTime ? options.reservationTime : new Date());
      } else if (name === 'reservationDownTime' || id === 'reservationDownTime') {
        time = moment(options.reservationDownTime ? options.reservationDownTime : new Date());
      }
      
      if (name === 'moveDate') time = moment(value);

      if (params.minute) time.minute(parseInt(params.minute));
      else time.minute(0);

      if (params.hour) time.hour(parseInt(params.hour));
      else time.hour(0);

      if (id === 'reservationTime') {
        name = "reservationTime";
      } else {
        name = "reservationDownTime";
      }
      
      value = new Date(time.format('YYYY-MM-DD HH:mm')).getTime();
    }
    
    if (name === 'isExpress') {
      if (checked) value = 'Y';
      else value = 'N';
    }

    await handleOptions(name, value);
    await orderCalAPI();
  }

  return (
    <Grid className={clsx('serviceBox', classes.root)} container direction="row" justify="flex-start" alignItems="flex-start">
      <Grid item xs={6}>
        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
          <Grid item>
            <ServiceTitle
              title="차량 및 상하차 시간"
              subTitle="선택방법에 따라 추가될 수 있습니다."
            />
          </Grid>
          <Grid item className={clsx('selectBox', 'upmargin')} >
            <CustomSelectGroup 
              title="차량 종류" 
              name="vehicleType"
              options={vehicleType}
              value={options.vehicleType}
              handleChange={handleChange}
              tip={true}
            />
          </Grid>
          <Grid item className={clsx('selectBox', classes.mb30)} >
            <CustomSelectGroup 
              title="차량 옵션" 
              name="vehicleOptionType"
              options={options2}
              value={options.vehicleOptionType}
              handleChange={handleChange}
              pd={47}
            />
          </Grid>
          <Grid item>
            <Grid className={classes.mb30} container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 40 }} variant="subtitle2"><b>상차시간</b></Typography>
              <CustomButtonGroup
                name="upTime"
                items={upDownTimeItems}
                defaultValue="now"
                handleChange={handleChange}
              />
            </Grid>
            {
              upTime && (
                <SelectTime 
                  name="reservationTime"
                  handleChange={handleChange}
                />
              )
            }
          </Grid>
          <Grid item>
            <Grid className={classes.mb30}  container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 40 }} variant="subtitle2"><b>하차시간</b></Typography>
              <CustomButtonGroup 
                name="downTime"
                defaultValue="now"
                items={upDownTimeItems2} 
                handleChange={handleChange}
              />
            </Grid>
            {
              downTime && (
                <SelectTime 
                  name="reservationDownTime"
                  handleChange={handleChange}
                />
              )
            }
          </Grid>
          <Grid item>
            <Grid className="" container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 45 }} variant="subtitle2"><b>요청사항</b></Typography>
              <Box className="mainFormGroup">
                <TextField
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  name="requests"
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
          <Grid item>
            <ServiceTitle
              title="상/하차 방법 및 추가 옵션"
              subTitle="선택방법에 따라 추가될 수 있습니다."
            />
          </Grid>
          <Grid item>
            <Grid className="upmargin" container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 20 }} variant="subtitle2"><b>상차방법</b></Typography>
              <CustomButtonGroup 
                name="loadingMethod"
                items={upDownWayItems} 
                defaultValue={options.loadingMethod}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid className={classes.mb20} container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 20 }} variant="subtitle2"><b>하차방법</b></Typography>
              <CustomButtonGroup 
                name="dropMethod"
                items={upDownWayItems} 
                defaultValue={options.dropMethod}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid className={classes.mb30} container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 20 }} variant="subtitle2"><b>배송종류</b></Typography>
              <CustomButtonGroup
                name="route"
                items={deliveryItem} 
                defaultValue={options.route}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.mb30} >
            <Grid className="" container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 55 }} variant="subtitle2"><b>기타</b></Typography>
              <FormControlLabel
                control={
                  <CustomCheckbox 
                    name="isExpress"
                    onChange={handleChange}
                  />
                }
                label={
                  <Typography variant="body1" color="textPrimary">
                    급송
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Grid item className={classes.mb30}>
            <Grid className="" container direction="row" justify="flex-start" alignItems="flex-start">
              <Typography style={{ paddingRight: 20 }} variant="subtitle2"><b>추가옵션</b></Typography>
              <Grid item>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                  <Grid item style={{ marginBottom: 20 }}>
                    <CountUpDown 
                      name="passengerCnt"
                      title="차량동승" 
                      handleChange={handleChange}
                      tip="최대1명 , 별도 요금이 발생합니다."
                    />
                  </Grid>
                  <Grid item>
                    <CountUpDown 
                      name="workerCnt"
                      title="인부추가" 
                      handleChange={handleChange}
                      tip="상/하차 별도 요금이 발생하며 경우에 따라 상담이 진행됩니다."
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default inject('qsStore')(observer(UpAndDown));