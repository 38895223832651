import React, { useEffect } from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import CustomVacantButton from '../../button/CustomVacantButton';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: -1.4,
    textAlign: 'left',
    color: '#f77c40',
  },
  subTitle: {
    fontSize: 18,
    lineHeight: 1.56,
    letterSpacing: -0.9,
    textAlign: 'left',
    color: '#555558',
  },
  btn: {
    width: 130,
    height: 40,
    fontSize: 16,
    borderRadius: 10,
  },
  loginBtn: {
    marginLeft: '2.7rem',
    marginRight: '0.625rem',
  }
}));

function ServiceTitle({ title, subTitle, isBtn, loginStore, myPageStore, history }) {
  const classes = useStyles();
  const { userInfo } = loginStore;
  const { myAddress, latestAddress } = myPageStore;

  const handlePage = (path) => {
    // console.log({ myAddress, latestAddress });
    if (path === '/myaddress') {
      if (!myAddress || myAddress.length === 0) {
        alert("자주쓰는 경로가 존재하지 않습니다.");
        return;
      }
    } else if (path === '/shippinglist') {
      if (!latestAddress || latestAddress.length === 0) {
        alert("최근이용 경로가 존재하지 않습니다.");
        return;
      }
    }


    history.push(path);
  }
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid style={{width: 'auto'}} container direction="column" justify="center" alignItems="flex-start">
        <Typography className={classes.title} gutterBottom>{title}</Typography>
        <Typography className={classes.subTitle}>
          <span className="pColor"><b>TIP.</b></span> {subTitle}
        </Typography>
      </Grid>
      {
        isBtn &&
        (
          userInfo && userInfo.userKey
            ? (
              <>
                <CustomVacantButton
                  className={clsx(classes.btn, classes.loginBtn)}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => handlePage('/myaddress')}
                >
                  자주쓰는
                </CustomVacantButton>
                <CustomVacantButton
                  className={classes.btn}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => handlePage('/shippinglist')}
                >
                  최근이용
                </CustomVacantButton>
              </>
            )
            : (
              <>
                <CustomVacantButton
                  className={clsx(classes.btn, classes.loginBtn)}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => handlePage('/login')}
                >
                  로그인
                </CustomVacantButton>
                {/* <CustomVacantButton
                  className={classes.btn}
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={() => handlePage('/login')}
                >
                  비회원 이용
                </CustomVacantButton> */}
              </>
            )
        )
      }
    </Grid>
  );
}

export default inject('loginStore', 'myPageStore')(withRouter(observer(ServiceTitle)));
