import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
// import { Grid, Typography, TextField, Box, Icon } from '@material-ui/core';
import { Grid, Typography, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { CustomButton } from '../components/button';
import GuideMessageM from '../components/message/GuideMessageM';
import { IconCheckLW } from '../static/images/icon';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 60,
  },
  inputBox: {
    maxWidth: 500,
    padding: '0 30px',
    '& .MuiBox-root': {
      marginBottom: 40,
    },
    marginBottom: 30,
  },
  alink: {
    color: theme.palette.primary.main
  }
}));

function MyPage({ loginStore }) {
  const classes = useStyles();
  const history = useHistory();
  const { phoneAuth, phoneInitial, phoneCheck, phoneChangeCall, leaveCall } = loginStore;

  useEffect(() => {
    phoneInitial();
  }, [])

  useEffect(() => {
    if (phoneCheck) {
      phoneChangeCall(() => {
        history.push({
          pathname: '/changeresult',
          state: {
            subTitle: '핸드폰 번호가 변경되었습니다.'
          }
        })
      });
    }
  }, [phoneCheck])


  const handlePage = () => {
    history.push('/leaveId');
  }

  return (
    <React.Fragment>
      <Grid className="mainLayout" container direction="column" justify="center" alignItems="center">
        <Grid item className={classes.title}>
          <GuideMessageM 
            title="마이페이지"
            resultTitle="마이페이지"
            subTitle="사용자 정보를 수정합니다."
          />
        </Grid>
        <Grid className={classes.inputBox} container direction="column" justify="center" alignItems="flex-start">
          <CustomButton
            fullWidth 
            variant="contained" 
            color="primary"
            onClick={phoneAuth}
            endIcon={
              phoneCheck && (
                <Icon>
                  <img alt="" src={IconCheckLW}/>
                </Icon>
              )
            }
            >핸드폰 번호 변경 인증하기
          </CustomButton>
          {/* <Typography variant="body1" color="primary" gutterBottom><b>인증받은 핸드폰번호</b></Typography>
          <Box className="mainFormGroup">
            <TextField
              placeholder="핸드폰 번호"
              variant="outlined"
              fullWidth
              className="formArea"
            />
          </Box>
          <CustomButton
            variant="contained"
            fullWidth
            color="primary"
          >핸드폰번호 변경하기
          </CustomButton> */}
        </Grid>
        <Grid item>
          <a href="#" className={classes.alink} onClick={handlePage}>
            <Typography variant="body2" color="primary">탈퇴하기</Typography>
          </a>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject('loginStore')(observer(MyPage));