import React, { useState } from 'react';
import clsx from 'clsx';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, Grid, IconButton, Typography } from '@material-ui/core';
import CustomRadio from '../../button/CustomRadio';
import MovingTooltipDialog from '../dialog/MovingTooltipDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50
  },
  title: {
    paddingBottom: 30,
  },
  type: {
    marginBottom: 20,
  }
}));


function MovingSizeM({ items, name, qsStore }) {
  const cs = useStyles();
  const { handleOptions } = qsStore;
  const [value, setValue] = useState();
  const [dialog, setDialog] = useState(false);
  const [goodsTemp, setGoodsItem] = useState(null);

  const handleChange = (e) => {
    const v = e.target.value;
    setValue(v);
    handleOptions(name, v);
  };

  const handleTooltipOpen = (item) => {
    setGoodsItem(item);
    handleOnOff();
  }

  const handleOnOff = () => {
    setDialog(!dialog);
  }

  return (
    <React.Fragment>
      <MovingTooltipDialog open={dialog} item={goodsTemp} handleClose={handleOnOff} />
      <Grid className={cs.root} container direction="column" justify="center" alignItems="center">
        <Grid className={cs.title} container direction="column" justify="center" alignItems="center">
          <Typography variant="body1" color="primary">
            <b>이사규모</b>
          </Typography>
          <Typography variant="caption" color="textPrimary" align="center">
            <span className="pColor"><b>TIP.</b></span>
            물음표를 누르시면, 크기와 무게기준을 알려드려요.
          </Typography>
        </Grid>
        {
          items.map((item) => (
            <Grid className={cs.type} key={item.value} container direction="row" justify="flex-start" alignItems="flex-start">
              <Grid item>
                <FormControlLabel
                  value={item.value}
                  name={name}
                  control={<CustomRadio checked={value === item.value} onChange={handleChange} />}
                  label={
                    <Typography variant="body2" color="textPrimary"><b>{item.title}</b></Typography>
                  }
                  classes={{ label: 'label' }}
                  className={cs.formControlLabel}
                  style={{ marginRight: 10 }}
                />
                <IconButton className={clsx('questionMark')} onClick={() => handleTooltipOpen(item)} />
              </Grid>
            </Grid>
          ))
        }
      </Grid>
    </React.Fragment>
  );
}

export default inject('qsStore')(MovingSizeM);
