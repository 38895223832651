import React, { useState, useCallback, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { FormControlLabel, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';

import { ButtonClearInput, CustomCheckbox} from '../../button';

import { ImgArrowL } from '../../../static/images';
import NumberOfFloors from './NumberOfFloors';
import DaumPostcodeDialog from '../dialog/DaumPostcodeDialog';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 50,
    '& .textBox': {
      '& .Mui-disabled': {
        borderColor: 'transparent !important',
        
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px !important'
      },
      '& .MuiInputBase-root': {
        boxShadow: 'none',
        marginBottom: 16,
        width: 370,
        // height: 50,
        padding: '0 16px',
        borderRadius: 8,
        border: 'solid 1px #e4e4e9',
        backgroundColor: '#ffffff',
        '& input': {
          height: 50,
          '&::-webkit-input-placeholder': {
            color: '#cacacf'
          }
        },
        '& textarea': {
          '&::-webkit-input-placeholder': {
            color: '#cacacf'
          }
        },
        '& .MuiOutlinedInput-input': { padding: 0 },
      },
    },
   
    '& .MuiTextField-root': {
      '& .MuiInputBase-multiline': {
        padding: '12px 16px !important;',
      }
    },
    '& .nameText': {
      '& .MuiInputBase-root': {
        width: '130px !important;',
        marginRight: 10,
      },
    },
    '& .phoneText': {
      '& .MuiInputBase-root': {
        width: '233px !important;',
      },
    },
    '& .startAddr': { width: 'auto'},
    '& .endAddr': { width: 'auto'},
    '& .arrowIcon': {
      width: 60,
      height: 60,
      marginTop: 114,
    },
    '& .requesterPhone': {
      '& .MuiInputBase-root': {
        width: 373,
        '& label': {
          width: '100% !important;'
        }
      },
      
    }
  },
  tremDetBtn: {
    cursor: 'pointer',
    marginLeft: 10,
    borderBottom: '1px solid #ff925c'
  }
}));

const params = {};

function Address({ type, cmStore, qsStore, loginStore, history }) {
  const classes = useStyles();
  const { handleOptions, orderCalAPI } = qsStore;
  const { userInfo } = loginStore;
  const { toAddress, fromAddress, etcAddress, setEtcAddress, initialAddress } = cmStore;

  const [same, setSame] = useState(false);
  const [addrOpen, setAddrOpen] = useState(false);
  const [requestTel, setStartTel] = useState("");
  const [addresstype, setAddressType] = useState("to");

  useEffect(() => {
    let orderType = "";
    if (type === 'quick') orderType = 'Quick';
    else if (type === 'cargo') orderType = 'Freight';
    else orderType = 'Move';
    handleOptions("orderType", orderType);

    if (!etcAddress.startAddrDtl) initialAddress();
  }, [])

  useEffect(() => {
    if (toAddress && fromAddress ) {
      orderCalAPI();
    }
  }, [toAddress, fromAddress])

  const handleAddrOnOff = useCallback((type) => {
    setAddressType(type);
    setAddrOpen(!addrOpen)
  }, [addrOpen]);

  const handleChange = (e, checked) => {
    let value = e.target.value;
    const name = e.target.name;
    params[name] = value;

    if (name === "agree") {
      if (checked) value = "Y"
      else value = "N"
    }
    
    setEtcAddress(name, value);
    handleOptions(name, value);
    

    if (name === "startTel" && same) {
      setStartTel(value);

    } else if (name === "same") {
      if (!same) {
        setStartTel(etcAddress.startTel);
        handleOptions('requestTel', etcAddress.startTel);
      }
      setSame(!same);

    } else if (name === "requestTel") {
      setStartTel(value);
    }

  }

  const handlePage = (path) => {
    if (path === '/policy') {
      history.push({
        pathname: path,
        state: { type: 'privacy' }
      })
    } else {
      history.push(path);
    }
  }

  return (
    <React.Fragment>
      <DaumPostcodeDialog type={addresstype} open={addrOpen} handleClose={handleAddrOnOff} />
      <Grid className={classes.root} container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid className="startAddr" container direction="column" justify="flex-start" alignItems="flex-start">
          <Typography variant="subtitle2" color="textPrimary" gutterBottom><b>출발지</b></Typography>
          <TextField
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end"><ButtonClearInput /></InputAdornment>
              ),
            }}
            placeholder="주소를 검색하세요."
            variant="outlined"
            className="formArea textBox"
            value={toAddress}
            onClick={() => handleAddrOnOff('to')}
          />
          <TextField
            className="formArea textBox"
            placeholder={`상세 주소를 입력하세요.
(상호나 부서명이 있으면 함께 기록해주세요)`}
            multiline
            // rowsMax={2}
            rows={2}
            variant="outlined"
            name="startAddrDtl"
            value={etcAddress.startAddrDtl}
            onChange={handleChange}
          />
          {
            type === 'quick' || type === 'cargo'
              ? (
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <TextField
                    className="formArea nameText textBox"
                    placeholder="이름(보내는분)"
                    variant="outlined"
                  // className="formArea"
                    name="startNm"
                    value={etcAddress.startNm}
                    onChange={handleChange}
                  />
                  <TextField
                    className="formArea phoneText textBox"
                    placeholder="연락처"
                    variant="outlined"
                    name="startTel"
                    value={etcAddress.startTel}
                    onChange={handleChange}
                  // className="formArea"
                  />
                </Grid>
              )
              : (
                <NumberOfFloors name="moveStartType" subName="startFloor"/>
              )
          }

        </Grid>
        <div className="arrowIcon">
          <img alt="" src={ImgArrowL} />
        </div>
        <Grid className="endAddr" container direction="column" justify="flex-start" alignItems="flex-start">
          <Typography variant="subtitle2" color="textPrimary" gutterBottom><b>도착지</b></Typography>
          <TextField
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end"><ButtonClearInput /></InputAdornment>
              ),
            }}
            placeholder="주소를 검색하세요."
            variant="outlined"
            className="formArea textBox"
            value={fromAddress}
            onClick={() => handleAddrOnOff('from')}
          />
          <TextField
            className="formArea textBox"
            placeholder={`상세 주소를 입력하세요.
(상호나 부서명이 있으면 함께 기록해주세요)`}
            multiline
            // rowsMax={2}
            rows={2}
            variant="outlined"
            name="endAddrDtl"
            value={etcAddress.endAddrDtl}
            onChange={handleChange}
          />
          {
            type === 'quick' || type === 'cargo'
              ? (
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <TextField
                    className="formArea textBox nameText "
                    placeholder="이름(받는분)"
                    variant="outlined"
                    name="endNm"
                    value={etcAddress.endNm}
                    onChange={handleChange}
                  // className="formArea"
                  />
                  <TextField
                    className="formArea textBox phoneText"
                    placeholder="연락처"
                    variant="outlined"
                    name="endTel"
                    value={etcAddress.endTel}
                    onChange={handleChange}
                  // className="formArea"
                  />
                </Grid>
              )
              : (
                <NumberOfFloors name="moveEndType" subName="endFloor" style={{ marginBottom: 16 }} />
              )
          }
          {
            (type === 'quick') && (
              <>
                <TextField
                  name="requestTel"
                  value={requestTel}
                  onChange={handleChange}
                  className="requesterPhone textBox"
                  InputProps={{
                    endAdornment: (
                      type === 'quick'
                        ? (
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <CustomCheckbox
                                name="same"
                                onChange={handleChange}
                              />
                            }
                            label={
                              <Typography variant="body2" color="primary">
                                보내는분 동일
                              </Typography>
                            }
                          />
                        )
                        : (
                          <></>
                        )
                    ),
                  }}
                  placeholder="요청자 연락처"
                  variant="outlined"
                />
              </>
            )
          }
          {
            (type === 'move') && (
              <>
                <TextField
                  name="startTel"
                  onChange={handleChange}
                  className="requesterPhone textBox"
                  placeholder="요청자 연락처"
                  variant="outlined"
                />
              </>
            )
          }
          {
            !userInfo && (
              <Grid item>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        name="agree"
                        onChange={handleChange}
                      />
                    }
                    label={
                      <Typography variant="body2" color="textPrimary">
                        개인정보 수집 이용 동의
                            </Typography>
                    }
                  />
                  <Typography
                    className={classes.tremDetBtn}
                    variant="caption"
                    color="primary"
                    onClick={() => handlePage('/policy')}
                  >
                    상세보기
                  </Typography>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject('cmStore', 'qsStore', 'loginStore')(withRouter(observer(Address)));