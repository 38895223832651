import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Grid, Icon, TextField } from '@material-ui/core';

import GuideMessage from '../message/GuideMessage';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButton, CustomVacantButton } from '../button';
import { IconCheckLW } from '../../static/images/icon';
import { EmailRegExp } from '../../lib/util';

let params = {};
function PWInquiryForm({ handleIdentifyBtn, loginStore }) {
  const history = useHistory();
  const classes = useStyles();
  const { findPwdCall, phoneCheck, phoneInitial, phoneAuth } = loginStore;

  useEffect(() => {
    phoneInitial();
  }, [])

  useEffect(() => {
    if (phoneCheck) {
      findPwdCall(params, () => handleIdentifyBtn('change'))
    }
  }, [phoneCheck]);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    params[name] = value;
  }

  const handlePhoneCheck = () => {
    if (!params.email) {
      alert("이메일을 입력하세요.");
      return;
    } else if (!EmailRegExp(params.email)) {
      alert("이메일을 정확히 입력하세요.");
      return;
    }
    phoneAuth();
  }
  
  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" wrap="nowrap" className={classes.pwInquiryWrap}>
        <Grid item className="loginFormArea">
          <Grid container direction="column" justify="center" className="narrowFormArea">
            <Grid item>
              <Grid container direction="column" justify="center" alignItems="center">
                <GuideMessage
                  title="비밀번호를 잊어버리셨나요?"
                  resultTitle="비밀번호를 잊어버리셨나요?"
                  mTitle="비밀번호찾기"
                  subTitle="간단한 정보 확인 후 안내를 도와드릴게요."
                />
              </Grid>
            </Grid>
            <Grid item className={classes.inputForm}>
              <Box className="mainFormGroup">
                <TextField
                  placeholder="가입한 아이디(e-mail)"
                  variant="outlined"
                  fullWidth
                  className="formArea"
                  name="email"
                  onChange={handleChange}
                />
                {/* <TextField
                  placeholder="가입 시 인증받은 핸드폰번호"
                  variant="outlined"
                  fullWidth
                  className="formArea"
                  name="phone"
                  onChange={handleChange}
                />
                <TextField
                  placeholder="인증번호 입력"
                  variant="outlined"
                  fullWidth
                  className="formArea"
                  InputProps={{
                    endAdornment: (
                      <Button variant="contained" className={classes.button}>
                        인증확인
                      </Button>
                    ),
                    inputProps: { 'title': '인증번호 입력' },
                  }}
                /> */}
              </Box>
            </Grid>
            <Grid item>
              <CustomButton
                fullWidth 
                variant="contained" 
                color="primary"
                onClick={handlePhoneCheck}
                endIcon={
                  phoneCheck && (
                    <Icon>
                      <img alt="" src={IconCheckLW}/>
                    </Icon>
                  )
                }
                >핸드폰 번호 인증하기
              </CustomButton>
              <CustomVacantButton
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.signupBtn}
                onClick={() => history.push("/signup")}
              >초간단 회원가입
              </CustomVacantButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  pwInquiryWrap: {
    paddingTop: 100,
    paddingBottom: 120
  },
  pwInquiryForm: {
    width: 420,
    marginLeft: 20,
    marginRight: 20,
  },
  inputForm: {
    padding: '60px 0'
  },
  signupBtn: {
    marginTop: 20,
  },
  button: {
    width: 96,
    height: 53,
    borderRadius: 8,
    padding: 0,
    backgroundColor: '#e4e4e9',
    position: 'absolute',
    right: 0,
    color: '#fff',
    fontSize: 16,
    boxShadow: 'none'
  },
}));

export default inject('loginStore')(observer(PWInquiryForm));