import { client } from '../lib/client';
// 로그인 정보 me
export const meAPI = () => (
  client({
    url: '/v1/me',
  }, true)
);

export const snsJoinAPI = (data) => (
  client({
    url: '/v1/join/sns',
    method: 'post',
    data
  })
);

export const snsLoginAPI = (data) => (
  client({
    url: '/v1/login/sns',
    method: 'post',
    data
  })
);

export const emailJoinAPI = (data) => (
  client({
    url: '/v1/join/email',
    method: 'post',
    data
  })
);

export const emailLoginAPI = (data) => (
  client({
    url: '/v1/login/email',
    method: 'post',
    data
  })
);

// 아이디 찾기
export const findIdAPI = (data) => (
  client({
    url: '/v1/find/id',
    method: 'post',
    data
  })
);

// 비밀번호 찾기
export const findPwdAPI = (data) => (
  client({
    url: '/v1/find/pwd',
    method: 'post',
    data
  })
);

// 비밀번호 변경
export const changePwdAPI = (data) => (
  client({
    url: '/v1/find/pwd',
    method: 'put',
    data
  })
);

// 회원탈퇴
export const leaveAPI = (params) => (
  client({
    url: '/v1/quit',
    method: 'put',
    data: params
  }, true)
);

// 핸드폰 인증 결과 (아임포트)
export const phoneChcekResultAPI = (imp_uid) => {
  client({
    url: `https://api.iamport.kr/certifications/${imp_uid}`
  })
}

// 핸드폰 번호 변경
export const phoneChangeAPI = (data) => (
  client({
    url: `/v1/user/phone`,
    method: 'put',
    data
  }, true)
);
