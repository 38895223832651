import React, { useState, useCallback } from 'react';
import { PWInquiryForm, PWChangeForm } from '../components/PWInquiryPage';

function PWInquiryPage(props) {
  const [result, setResult] = useState('find');

  const handleIdentifyBtn = useCallback((type) => {
    setResult(type);
  }, [result]);

  return (
    <React.Fragment>
      { result === 'find' && <PWInquiryForm handleIdentifyBtn={handleIdentifyBtn} /> }
      { result === 'change' && <PWChangeForm handleIdentifyBtn={handleIdentifyBtn} />}
    </React.Fragment>
  );
}

export default PWInquiryPage;