import React, { useEffect } from 'react';
import clsx from 'clsx';
import { inject } from 'mobx-react';
import { Box, Grid, Icon, Typography } from '@material-ui/core';
import GuideMessageM from '../message/GuideMessageM';

import { makeStyles } from '@material-ui/core/styles';
import { IconService1, IconService2, IconService3, IconService4, IconService5, IconPinG } from '../../static/images/icon';
import { ImgAsOne, ImgAsTwo, ImgPin, ImgRider, ImgServiceTransport } from '../../static/images';

const useStyles = makeStyles((theme) => ({
  root: {
    // background: '#ffffff'
    paddingBottom: 0,
  },
  contentOne: {
    backgroundColor: '#fef7f5',
    backgroundImage: 'linear-gradient(180deg, #fef7f5 0%, #ffffff 40%)',
    paddingBottom: 100
  },
  text: {
    paddingRight: 10,
    textAlign: 'center',
    lineHeight: 1.37
  },
  miniText: {
    textAlign: 'center',
    paddingTop: 20,
    paddingRight: 10,
  },
  lastPadding: {
    paddingRight: 0
  },
  miniBoxlast: { marginRight: 0},
  contentTwo: {
    backgroundColor: '#f8fbff',
    paddingBottom: 80,
    '& .timeLineIcon': {
      // paddingTop: 70,
    },
    '& .last': {
      marginRight: '0 !important;'
    },
    '& .imgPin': {
      width: 30,
      height: 30,
      marginRight: 35,
      '& img': { width: '100% ', height: '100%' }
    },
    '& .time': {
      fontSize: 20,
      fontWeight: 'bold',
      lineHeight: 1.21,
      letterSpacing: -1.4,
      textAlign: 'center',
      color: '#f77c40',
      marginRight: 35,
    },
    '& .imgRider': {
      width: 44,
      height: 44,
      marginRight: 35,
      '& img': { width: '100% ', height: '100%' }
    },
    '& .line': {
      width: 300,
      height: 8,
      margin: '4px 25px 10px 27px',
      borderRadius: 30,
      boxShadow: '0 5px 15px 0 rgba(224, 234, 248, 0.8)',
      backgroundColor: '#ffffff',
    },
    '& .rowBox': {
      width: 320,
      height: 45,
      borderRadius: 60,
      marginTop: 20,
      padding: 8,
      boxShadow: '0 5px 15px 0 rgba(224, 234, 248, 0.8)',
      backgroundColor: '#ffffff',
      '& h6': {
        // lineHeight: 1.2,
        letterSpacing: -1.6,
      }
    },
  },
  lineTitle: { width: 300},
  contentThree: {
    backgroundColor: '#ffffff',
    paddingBottom: 80,
  },
  imgTransport: {
    width: 300,
    height: 220,
    marginRight: 25
  },
  box: {
    width: 335,
    // padding: '30px 42px 50px',
    borderRadius: 30,
    boxShadow: '0 15px 30px 0 rgba(255, 146, 92, 0.16)',
    backgroundColor: '#ffffff',
      '& .textCenter': {
      paddingTop: 30,
      paddingBottom: 20,
    },
    padding: 20
  },
  boxImg: {
    width: 100,
    height: 100,
  }
}));

function ServiceViewM({ cmStore }) {
  const cs = useStyles();
  const { setMobileTitle } = cmStore;
  useEffect(() => {
    setMobileTitle('서비스 소개');
  }, [])
  
  return (
    <React.Fragment>
      <div className={clsx("mainLayout", cs.root)}>
        <Grid className={cs.contentOne} container direction="column" justify="center" alignItems="center">
          <Grid item>
            <GuideMessageM
              resultTitle="접수부터</br>배송이 끝나는 순간까지"
              subTitle="믿고 맡기는 스마트 배송 퀴노스와 시작하세요."
            />
            <Box height={80} />
            <Grid className="subContent subTitle" container direction="row" justify="center" alignItems="center">
              <Typography className={cs.text} variant="subtitle2" color="textPrimary">
                <b>고객 클레임 발생률</b>
              </Typography>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                <b>0.028%</b>
              </Typography>
            </Grid>
            <Box height={40} />
            <Grid className="miniboxArea" container direction="row" justify="center" alignItems="center">
              <Grid item>
                <div className="miniBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <img alt="" src={IconService1} />
                  </Grid>
                </div>
                <Typography className={cs.miniText} variant="body2" color="primary">
                  <b>배송 건 별<br></br>
                  프로세스 관리</b>
                </Typography>
              </Grid>
              <Grid item>
                <div className="miniBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <img alt="" src={IconService2} />
                  </Grid>
                </div>
                <Typography className={cs.miniText} variant="body2" color="primary">
                  <b>실시간 배송<br></br>
                  모니터링</b>
                </Typography>
              </Grid>
              <Grid item>
                <div className={clsx("miniBox", cs.miniBoxlast)}>
                  <Grid container direction="row" justify="center" alignItems="center">
                    <img alt="" src={IconService3} />
                  </Grid>
                </div>
                <Typography className={clsx(cs.miniText, cs.lastPadding)} variant="body2" color="primary">
                  <b>전문 상담원의<br></br>
                  즉각 응대</b>
                </Typography>
              </Grid>
            </Grid>
            <Box height={30} />
            <Grid className="miniboxArea" container direction="row" justify="center" alignItems="center">
              <Grid item>
                <div className="miniBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <img alt="" src={IconService4} />
                  </Grid>
                </div>
                <Typography className={cs.miniText} variant="body2" color="primary" style={{paddingRight: 15}}>
                  <b>신속 정확한<br></br>
                  업무 처리</b>
                </Typography>
              </Grid>
              <Grid item>
                <div className={clsx("miniBox", cs.miniBoxlast)} style={{margin: '0 auto'}}>
                  <Grid container direction="row" justify="center" alignItems="center">
                    <img alt="" src={IconService5} />
                  </Grid>
                </div>
                <Typography className={clsx(cs.miniText, cs.lastPadding)} variant="body2" color="primary">
                  <b>배송 변수 발생 시<br></br>
                  즉각 처리</b>
                </Typography>
              </Grid>
            </Grid>
            <Box height={80} />
            <Typography variant="h3" align="center">
              <b><span style={{ color: '#f77c40' }}>중앙 관제 센터</span> 운영으로<br></br>
            더욱 안심하고 사용하실 수 있습니다.</b>
            </Typography>
            <Box height={40} />
            <Grid container direction="column" justify="center" alignItems="center">
              <div className="rowBox">
                <Grid container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>3 STEP 배송 모니터링</b></Typography>
                </Grid>
              </div>
              <Box height={10} />
              <Typography variant="body1" color="textPrimary" align="center">
                실시간으로 배송 진행 상황을 확인해<br></br>
                <b>변수에 즉각 대응합니다.</b>
              </Typography>
            </Grid>
            <Box height={30} />
            <Grid container direction="column" justify="center" alignItems="center">
              <div className="rowBox">
                <Grid container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>CS 교육</b></Typography>
                </Grid>
              </div>
              <Box height={10} />
              <Typography variant="body1" color="textPrimary" align="center">
                퀵배송 전문 상담팀의 노하우를 바탕으로<br></br>
                <b>지속적인 업무 교육을 실시합니다.</b>
              </Typography>
            </Grid>
            <Box height={30} />
            <Grid container direction="column" justify="center" alignItems="center">
              <div className="rowBox">
                <Grid container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>안전한 배송</b></Typography>
                </Grid>
              </div>
              <Box height={10} />
              <Typography variant="body1" color="textPrimary" align="center">
                파손·분실 전담팀이 배송 시 일어날 수 있는<br></br>
                <b>각종 사고를 미연에 방지합니다.</b><br></br>
                <Typography variant="body2" color="primary">
                  *메리츠화재 파손·분실 보험 가입, 최대 5천만 원 보장.
              </Typography>
              </Typography>
            </Grid>
            <Box height={80} />
            <Typography variant="h3" align="center">
              <b>친절한 퀴노스 <span className="pColor">전문 라이더</span>와<br></br>
             함께하세요!</b>
            </Typography>
            <Box height={40} />
            <Grid container direction="column" justify="center" alignItems="center">
              <div className="rowBox">
                <Grid container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>기사 신용 보증 제도</b></Typography>
                </Grid>
              </div>
              <Box height={30} />
              <Typography variant="body1" color="textPrimary" align="center">
                보증된 배송 기사를 채용하여<br></br>
                <b>지속적인 친절 교육과 업무 교육을 실시합니다.</b>
              </Typography>
            </Grid>
            <Box height={30} />
            <Grid container direction="column" justify="center" alignItems="center">
              <div className="rowBox">
                <Grid container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>ONE-OUT</b></Typography>
                </Grid>
              </div>
              <Box height={30} />
              <Typography variant="body1" color="textPrimary" align="center">
                <b>무책임하고 불친절한 배송 기사는 <br></br>
              퀴노스의 배송을 담당할 수 없습니다.</b>
              </Typography>
            </Grid>
            <Box height={30} />
            <Grid container direction="column" justify="center" alignItems="center">
              <div className="rowBox">
                <Grid container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>근무복 지급</b></Typography>
                </Grid>
              </div>
              <Box height={30} />
              <Typography variant="body1" color="textPrimary" align="center">
                퀴노스 유니폼을 무제한 지급하여<br></br>
                <b>늘 단정한 모습으로 서비스합니다.</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={cs.contentTwo} container direction="column" justify="center" alignItems="center">
          <Box height={80} />
          <Grid className="subContent subTitle" container direction="row" justify="center" alignItems="center">
            <Typography className={cs.text} variant="subtitle2" color="textPrimary">
              <b>평균 배송 시간</b>
            </Typography>
          </Grid>
          <Box height={50} />
          <Grid className="timeLineIcon" container direction="row" justify="center" alignItems="center">
            <Icon className="imgPin"><img alt="" src={IconPinG} /></Icon>
            <Typography className="time">5분</Typography>
            <Icon className="imgRider"><img alt="" src={ImgRider} /></Icon>
            <Typography className="time">40분</Typography>
            <Icon className="imgPin last"><img alt="" src={ImgPin} /></Icon>
          </Grid>
          <div className="line"></div>
          <Grid className={cs.lineTitle} container direction="row" justify="space-between" alignItems="center">
            <Typography variant="body2"><b>서울 출발</b></Typography>
            <Typography variant="body2" style={{ paddingRight: 10 }}><b>픽업</b></Typography>
            <Typography variant="body2" color="primary"><b>서울 도착</b></Typography>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <div className="rowBox">
              <Grid container direction="row" justify="center" alignItems="center">
                <Typography variant="body1" color="textPrimary" >
                  <b className="pColor">평균 45분</b> 타사 대비 1시간 단축
                </Typography>
              </Grid>
            </div>
          </Grid>
          <Box height={40} />
          <Grid className="timeLineIcon" container direction="row" justify="center" alignItems="center">
            <Icon className="imgPin"><img alt="" src={IconPinG} /></Icon>
            <Typography className="time">5분</Typography>
            <Icon className="imgRider"><img alt="" src={ImgRider} /></Icon>
            <Typography className="time">80분</Typography>
            <Icon className="imgPin last"><img alt="" src={ImgPin} /></Icon>
          </Grid>
          <div className="line"></div>
          <Grid className={cs.lineTitle} container direction="row" justify="space-between" alignItems="center">
            <Typography variant="body2"><b>서울 출발</b></Typography>
            <Typography variant="body2" style={{ paddingRight: 10 }}><b>픽업</b></Typography>
            <Typography variant="body2" color="primary"><b>경기 도착</b></Typography>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <div className="rowBox">
              <Grid container direction="row" justify="center" alignItems="center">
                <Typography variant="body1" color="textPrimary" >
                  <b className="pColor">평균85분</b> 타사 대비 1시간 단축
                </Typography>
              </Grid>
            </div>
          </Grid>
          <Box height={30} />
          <Typography variant="caption" color="textSecondary" align="center">
            * 위 사례는 교통 상황이 좋은 경우의 빠른 배송 사례로 <br></br>
            성수기, 기상 악화 시 지연이 발생할 수 있으며<br></br>
            서울 지역별 배송시간의 차이가 발생할 수 있습니다.
          </Typography>
        </Grid>
        <Grid className={cs.contentThree} container direction="column" justify="center" alignItems="center">
          <Box height={80} />
          <Typography variant="h3" align="center" style={{ lineHeight: 1.37 }}>
            <b>연계 배송을 통한 전국 배송까지!<br></br>
            <span className="pColor">모든 운송 수단 연계</span>가 가능합니다!</b>
          </Typography>
          <Box height={30} />
          <Grid container direction="column" justify="center" alignItems="center">
            <img className={cs.imgTransport} alt="" src={ImgServiceTransport} />
            <Grid container direction="row" justify="center" alignItems="center">
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="body2" color="primary"><b>오토바이</b></Typography>
                </Grid>
              </div>
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="body2" color="primary"><b>라보/다마스</b></Typography>
                </Grid>
              </div>
            </Grid>
            <Box height={10} />
            <Grid container direction="row" justify="center" alignItems="center">
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="body2" color="primary"><b>1톤 이상 차량</b></Typography>
                </Grid>
              </div>
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="body2" color="primary"><b>공항물류 탁송</b></Typography>
                </Grid>
              </div>
            </Grid>
            <Box height={10} />
            <Grid container direction="row" justify="center" alignItems="center">
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="body2" color="primary"><b>KTX 탁송</b></Typography>
                </Grid>
              </div>
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="body2" color="primary"><b>고속버스 탁송</b></Typography>
                </Grid>
              </div>
            </Grid>
            <Box height={80} />
            <Typography variant="h3" align="center" style={{ lineHeight: 1.37 }}>
              <b>안전 배송, 안심 배송!<br></br>
                <span className="pColor">배송 A/S</span>까지 확실히 보장합니다.
              </b>
            </Typography>
            <Box height={30} />
            <div className={cs.box}>
              <Grid container direction="column" justify="center" alignItems="center">
                <img className={cs.boxImg} alt="" src={ImgAsOne} />
              </Grid>
              <Typography className="textCenter" variant="body2" color="primary" align="center">
                <b>배송 물품 분실 및 파손<br></br> 시 업계 최고 금액 보장</b>
              </Typography>
              <Typography variant="body2" color="textPrimary" align="center">
                메리츠화재 적재물 보험 보상<br></br>
                오토바이 기준<span className="pColor">최대 2,000만원</span> / <br></br>
                차량 기준 <span className="pColor">최대 5,000만원</span>
              </Typography>
            </div>
            <Box height={10} />
            <div className={cs.box}>
              <Grid container direction="column" justify="center" alignItems="center">
                <img className={cs.boxImg} alt="" src={ImgAsTwo} />
              </Grid>
              <Typography className="textCenter" variant="body2" color="primary" align="center">
                <b>배송시간 지연</b>
              </Typography>
              <Typography variant="body2" color="textPrimary" align="center">
                정상 배송 시간 초과 시 요금 <span className="pColor">50% 할인</span><br></br>
                (성수기, 기상 악화 시 제외)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default inject('cmStore')(ServiceViewM);