import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { AddressRegForm, CustomTextBox } from '../components/common';
import { IconBackG } from '../static/images/icon';
// import { CustomButton } from '../components/button';
import GuideMessageM from '../components/message/GuideMessageM';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  inputBox: {
    maxWidth: 500,
    padding: '0 30px',
  },
  title: {
    width: '100%',
    textAlign: 'center'
  },
  backBtn: {
    '&:hover': {
      background: 'transparent'
    }
  }
}));

function AddressRegPage(props) {
  const cs = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <Grid className="mainLayout" container direction="column" justify="center" alignItems="center">
        <Grid className={cs.inputBox} container direction="column" justify="center" alignItems="flex-start">
          {
            !matches && (
              <IconButton className={cs.backBtn} onClick={() => { history.goBack() }}>
                <img src={IconBackG} alt="" />
                <Typography variant="body1" style={{ color: '#ACACB2', marginBottom: 3 }} align="left" gutterBottom>뒤로가기</Typography>
              </IconButton>
            )
          }
          <Grid item className={cs.title}>
            <GuideMessageM 
              title="신규등록"
            />
          </Grid>
          <Box height={40} />
          <AddressRegForm />
        </Grid>
      </Grid>
  </React.Fragment>
  );
}

export default AddressRegPage;