import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { Provider } from 'mobx-react';
import { BrowserRouter as Router } from 'react-router-dom';

import RootStore from './stores';
const root = new RootStore();

ReactDOM.render(
  <React.Fragment>
    <Router>
      <Provider {...root}>
        <App />
      </Provider>
    </Router>
    
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
