import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Slider from "react-slick";
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { AddressRegForm } from '../common';
import { IconPrev, IconNext } from '../../static/images/icon';
import { useTheme } from '@material-ui/styles';
import { CustomButton } from '../button';

const useStyles = makeStyles((theme) => ({
  sliderMain: {
    width: '100%'
  },
  companySlider: {
    '& .slick-list': {
      // padding: '0 30px',
    },
    '& img': {
      width: '200px !important;'
    },
    // '& button': {
    //   width: 40,
    //   height: 40,
    //   borderRadius: 60,
    //   // boxShadow: '0 5px 10px 0 rgba(247, 124, 64, 0.2)',
    //   backgroundColor: '#ffffff',
    //   // display: 'none !important;'
    // }
  },
  btn: {
    width: 40,
    height: 40,
    borderRadius: 60,
    boxShadow: '0 5px 10px 0 rgba(247, 124, 64, 0.2)',
    backgroundColor: '#ffffff',
  },
  prevBtn:  {
    position: 'absolute',
    top: '50%',
    left: -45,
    background: `url(${IconPrev}) no-repeat center`,
  },
  nextBtn: {
    position: 'absolute',
    top: '50%',
    right: -45,
    background: `url(${IconNext}) no-repeat center`,
  },
  modBtn: {
    '& .MuiGrid-item': {
      padding: '0 5px',
      width: '50%'
    }
  },
  noMsg: {
    marginTop: 10,
    height: 50,
    padding: 10,
    textAlign: 'center',
  }
}));

function SamplePrevArrow(props) {
  const { className, style, cs, onClick } = props;
  return (
    <IconButton 
      onClick={onClick} 
      style={{ ...style, display: "block" }}
      className={clsx(cs.prevBtn, cs.btn)} 
    />
  )
}

function SampleNextArrow(props) {
  const { className, style, cs, onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      style={{ ...style, display: "block" }}
      className={clsx(cs.nextBtn, cs.btn)}
    />
  )
}


function AddressSlider({ myPageStore, cmStore }) {
  const cs = useStyles();
  const { myAddress } = myPageStore;
  const { setDaumAddress } = cmStore;
  const [actCnt, setActCnt] = useState(1);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: !matches,
    nextArrow: <SampleNextArrow cs={cs}/>,
    prevArrow: <SamplePrevArrow cs={cs}/>,
    beforeChange: (current, next) => {
      setActCnt((old) => next + 1)
      if (myAddress.length > 0) {
        const item = myAddress[next];
        addressDetail(item);
      }
    }
  };

  const onClick = () => {}
  const addressDetail = (item) => {
    setDaumAddress('to', item.startAddr, item.startPointCode);
    setDaumAddress('from', item.endAddr, item.endPointCode);
  }

  useEffect(() => {
    if (myAddress.length > 0) {
      addressDetail(myAddress[0]);
    }
  }, [myAddress]);

  // if (myAddress.length === 0) return null;

  return (
    <React.Fragment>
      
        {
          myAddress.length === 0
            ? (
              <div style={{ width: '100%' }}>
                <Paper className={cs.noMsg} elevation={0} >
                  <Typography variant="body2" color="primary">자주쓰는 경로가 존재하지 않습니다.</Typography>
                </Paper>
              </div>
            )
          : (
            <Box className={cs.sliderMain}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="body1" color="primary" align="center" gutterBottom>자주쓰는 경로</Typography>
                <Typography variant="body1" color="textPrimary" align="center" gutterBottom>
                  <span style={{ fontSize: 24, color: '#f77c40' }}>{actCnt}</span>
                  <span style={{ color: '#a4a4ac' }}>{` /${myAddress.length}`}</span>
                </Typography>
              </Grid>
              <Slider className={cs.companySlider} {...settings}>
              {
                myAddress.map((item, index) => (
                  <div key={index}>
                    <AddressRegForm type="det" item={item}/>
                  </div>
                ))
              }
              </Slider>
            </Box>
          )
        }
    </React.Fragment>
  );
}

export default inject('myPageStore', 'cmStore')(observer(AddressSlider));