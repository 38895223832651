import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { Button, Grid, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { numberComma } from '../../../lib/util';
import { CustomVacantButton } from '../../button';
import TotalDetDialog from '../dialog/TotalDetDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    height: 120,
    padding: '0 200px',
    backgroundImage: 'linear-gradient(to right, #ff925c 0%, #f77c40 75%, #f77c40 100%);',
    // padding: '0 550px',
  },
  fix: {
    position: 'fixed',
    bottom: 0
  },
  btn: {
    backgroundColor: 'transparent',
    borderColor: '#ffffff',
    color: '#ffffff',
    marginRight: 20
  },
  popovertest: {
    color: 'blue'
  }
}));

// let fix = true;
function TotalBox({ qsStore, pageType }) {

  const classes = useStyles();
  const { orderResult, options } = qsStore;
  const [fix, setFix] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const scrollEvent = () => {
    const target = document.body;
    const targetOffset = Math.ceil(target.getBoundingClientRect().top);
    if (targetOffset < -1500) {
      setFix(false);
    } else {
      setFix(true);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent);
    return () => {
      window.removeEventListener("scroll", scrollEvent);
    }
  }, []);

  const handlePayInfo = (e) => {
    setAnchorEl(e.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  

  return (
    <Grid className={clsx(classes.root, fix ? classes.fix : '')} container direction="row" justify="space-between" alignItems="center">
      <Typography variant="h2" style={{ color: '#ffffff' }}><b>TOTAL</b></Typography>
      {
        (orderResult.isPayment === 'N' || options.paymentStatus === 'AfterConsult' || pageType === "move")
         ? (
            <Typography variant="h3" style={{ color: '#ffffff' }}><b>접수/예약 완료 시 상담이 진행됩니다.</b></Typography>
         )  
          : (
            <Grid style={{width: 'auto'}} container direction="row" justify="flex-end" alignItems="center">
              {
                (orderResult && orderResult.orderDetails) && (
                  <div>
                    <CustomVacantButton
                      aria-describedby={id}
                      variant="contained"
                      color="secondary"
                      className={classes.btn}
                      onClick={handlePayInfo}
                      >세부내역
                    </CustomVacantButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <TotalDetDialog />
                    </Popover>
                  </div>
                )
              }
              <Typography variant="h2" style={{ color: '#ffffff' }}><b>{numberComma(orderResult.totalValue)} 원</b></Typography>
            </Grid>
         )
      }
      
    </Grid>
  );
}

export default inject('qsStore')(observer(TotalBox));