import React from 'react';
import clsx from 'clsx';
import { Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .icoRadio': {
      display: 'block',
      position: 'relative',
      border: `1px solid ${theme.palette.border[100]}`,
      borderRadius: '50%',
      width: 20,
      height: 20,
      boxShadow: 'none',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: 0,
        height: 0,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        transition: 'all 0.25s ease-in-out',
        transform: 'translate(-50%, -50%)',
      },
      '&.icoRadioChecked': {
        borderColor: theme.palette.primary.main,
        '&:before': {
          width: 10,
          height: 10,
        },
      }
    },
    '&.disabled .icoRadio': {
      backgroundColor: theme.palette.bgColor.disabled,
      '&.icoRadioChecked': {
        borderColor: theme.palette.border[100],
        '&:before': {
          backgroundColor: theme.palette.border[100],
        },
      },
    },
  }
}));

function CustomRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      icon={<span className="icoRadio" />}
      checkedIcon={<span className={clsx("icoRadio", "icoRadioChecked")} />}
      color="default"
      classes={{ disabled: 'disabled' }}
      className={classes.root}
      {...props}
    />
  );
}

export default CustomRadio;