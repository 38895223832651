import React,  { useEffect } from 'react';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { AddressM, MovingSizeM, PayBoxM, ServiceTitleM, TotalBoxM } from '../common/eachService';
import UpAndDownM from './UpAndDownM';

const useStyles = makeStyles((theme) => ({
  contentone: {
    width: '100%',
    background: '#ffffff',
    margin: '20px 0',
    borderRadius: 10,
    padding: '40px 10px',
  }
}));

function MoveServiceViewM({ cmStore }) {
  const cs = useStyles();
  const { setMobileTitle } = cmStore;

  const moveItem = [
    { 
      idx: 0,
      value: 'OneRoom',
      title: '원룸이사',
      tip: '' ,
    },
    { 
      idx: 1,
      value: 'SmallFamilyMoving',
      title: '가정이사(작은 짐)',
      tip: '' 
    },
    { 
      idx: 2,
      value: 'HeavyFamilyMoving',
      title: '가정이사(많은 짐)',
      tip: '' 
    },
    { 
      idx: 3,
      value: 'Office',
      title: '사무실이사',
      tip: '' 
    },
  ]

  useEffect(() => {
    setMobileTitle('');
  }, []);

  return (
    <React.Fragment>
      <Container style={{ padding: '0 10px' }}>
        <div className={cs.contentone}>
          <ServiceTitleM />
          <AddressM type="move" />
          <MovingSizeM items={moveItem} name="moveType"/>
        </div>
        <div className={cs.contentone}>
          <UpAndDownM />
        </div>
        <div className={cs.contentone}>
          <PayBoxM pageType="move"/>
        </div>
      </Container>
      <TotalBoxM pageType="move"/>
    </React.Fragment>
  );
}

export default inject('cmStore')(MoveServiceViewM);