import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
// import { CustomButton, CustomVacantButton } from '../components/button';
import { CustomVacantButton } from '../components/button';
import AddressSlider from '../components/MyAddressPage/AddressSlider';
import GuideMessageM from '../components/message/GuideMessageM';

const useStyles = makeStyles((theme) => ({
  inputBox: {
    maxWidth: 500,
    padding: '0 30px',
  },
  btnBox: {
    width: 260,
    '& button': { 
      '&:hover': {
        background: 'transparent',
      },
    }
    
  },
  bth: {
    '&:hover': {
      background: 'transparent',
    },
  }
}));

function MyAddressPage({ myPageStore, cmStore, history }) {
  const cs = useStyles();
  const { myAddressSel } = myPageStore;
  const { initialAddress } = cmStore;
  
  useEffect(() => {
    myAddressSel();

    return () => {
      // initialAddress();
    }
  }, []);

  const handlePage = (path) => {
    initialAddress();
    history.push(path)
  }
  return (
    <React.Fragment>
      <Grid className="mainLayout" container direction="column" justify="center" alignItems="center">
        <GuideMessageM 
          title="자주쓰는 경로"
          subTitle="자주 이용하는 경로는 설정해두고</br> 편리하게 이용하세요."
        />
        <Box height={30} />
        <div className={cs.btnBox}>
          <CustomVacantButton
            className={cs.btn}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => handlePage('/addressreg')}
          >배송지 신규 등록
        </CustomVacantButton>
        </div>
        <Box height={40} />
        <Grid className={cs.inputBox} container direction="column" justify="center" alignItems="flex-start">
          <AddressSlider />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject("myPageStore", "cmStore")(MyAddressPage);