import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Icon, Typography } from '@material-ui/core';

import {
  IconService1, IconService2, IconPinG,
  IconService3, IconService4, IconService5
} from '../../static/images/icon';
import {
  ImgPin, ImgRider, ImgAsOne,
  ImgServiceTransport, ImgAsTwo
} from '../../static/images';

function ServiceView() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" wrap="nowrap" className={classes.contentOne}>
        <Grid item>
          <Grid container direction="column" justify="center" alignItems="center">
            <Typography variant="h1" color="primary">접수부터 배송이 끝나는 순간까지</Typography>
            <Typography variant="subtitle2" color="textPrimary">
              믿고 맡기는 스마트 배송 퀴노스와 시작하세요.
            </Typography>
            <Grid className="subContent subTitle" container direction="row" justify="center" alignItems="center">
              <Typography variant="h2">
                고객 클레임 발생률
              </Typography>
              <Typography variant="h1" color="primary">
                0.028%
              </Typography>
            </Grid>
            <Grid className="miniboxArea" container direction="row" justify="center" alignItems="center">
              <Grid item>
                <div className="miniBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <img alt="" src={IconService1} />
                  </Grid>
                </div>
                <Typography className={classes.miniText} variant="subtitle2" color="primary">
                  <b>배송 건 별<br></br>
                  프로세스 관리</b>
                </Typography>
              </Grid>
              <Grid item>
                <div className="miniBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <img alt="" src={IconService2} />
                  </Grid>
                </div>
                <Typography className={classes.miniText} variant="subtitle2" color="primary">
                  <b>실시간 배송<br></br>
                  모니터링</b>
                </Typography>
              </Grid>
              <Grid item>
                <div className="miniBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <img alt="" src={IconService3} />
                  </Grid>
                </div>
                <Typography className={classes.miniText} variant="subtitle2" color="primary">
                  <b>전문 상담원의<br></br>
                  즉각 응대</b>
                </Typography>
              </Grid>
              <Grid item>
                <div className="miniBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <img alt="" src={IconService4} />
                  </Grid>
                </div>
                <Typography className={classes.miniText} variant="subtitle2" color="primary">
                  <b>신속 정확한<br></br>
                  업무 처리</b>
                </Typography>
              </Grid>
              <Grid item>
                <div className="miniBox miniBoxlast">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <img alt="" src={IconService5} />
                  </Grid>
                </div>
                <Typography className={clsx(classes.miniText, 'last')} variant="subtitle2" color="primary">
                  <b>배송 변수 발생 시<br></br>
                  즉각 처리</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid className="subContentTowTitle subTitle" container direction="row" justify="center" alignItems="center">
              <Typography variant="h2">
                <b>
                  <span className="pColor">중앙 관제 센터</span> 운영으로<br></br>
                  더욱 안심하고 사용하실 수 있습니다.
                </b>
              </Typography>
            </Grid>
            <Grid className="subContentTowBox" container direction="row" justify="center" alignItems="center">
              <Grid className="rowBoxArea" container direction="row" justify="flex-start" alignItems="center">
                <div className="rowBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Typography variant="subtitle2" color="primary"><b>3 STEP 배송 모니터링</b></Typography>
                  </Grid>
                </div>
                <Typography variant="subtitle2" color="textPrimary">
                  실시간으로 배송 진행 상황을 확인해 <b>변수에 즉각 대응합니다.</b>
                  {/* 중단 부분 중앙 관제 센터 운영으로 더욱 안심하고 사용하실 수 있습니다 */}
                </Typography>
              </Grid>
              <Grid className="rowBoxArea" container direction="row" justify="flex-start" alignItems="center">
                <div className="rowBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Typography variant="subtitle2" color="primary"><b>CS 교육</b></Typography>
                  </Grid>
                </div>
                <Typography variant="subtitle2" color="textPrimary">
                  퀵배송 전문 상담팀의 노하우를 바탕으로 <b>지속적인 업무 교육을 실시합니다.</b>
                </Typography>
              </Grid>
              <Grid className="rowBoxArea" container direction="row" justify="flex-start" alignItems="center">
                <div className="rowBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Typography variant="subtitle2" color="primary"><b>안전한 배송</b></Typography>
                  </Grid>
                </div>
                <Typography variant="subtitle2" color="textPrimary">
                  파손·분실 전담팀이 배송 시 일어날 수 있는 <b>각종 사고를 미연에 방지합니다.</b>
                  <br></br><Typography variant="body1" color="primary">*메리츠화재 파손·분실 보험 가입, 최대 5천만 원 보장.</Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid className="subContentTowTitle subTitle" container direction="row" justify="center" alignItems="center">
              <Typography variant="h2">
                <b>친절한 <span className="pColor">퀴노스 전문 라이더</span>와 함께하세요!</b>
              </Typography>
            </Grid>
            <Grid className="subContentTowBox" container direction="row" justify="center" alignItems="center">
              <Grid className="rowBoxArea" container direction="row" justify="flex-start" alignItems="center">
                <div className="rowBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Typography variant="subtitle2" color="primary"><b>기사 신용 보증 제도</b></Typography>
                  </Grid>
                </div>
                <Typography variant="subtitle2" color="textPrimary">
                  보증된 배송 기사를 채용하여 <b>지속적인 친절 교육과 업무 교육을 실시합니다.</b>
                </Typography>
              </Grid>
              <Grid className="rowBoxArea" container direction="row" justify="flex-start" alignItems="center">
                <div className="rowBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Typography variant="subtitle2" color="primary"><b>ONE-OUT</b></Typography>
                  </Grid>
                </div>
                <Typography variant="subtitle2" color="textPrimary">
                  <b>무책임하고 불친절한 배송 기사는 퀴노스의 배송을 담당할 수 없습니다.</b>
                </Typography>
              </Grid>
              <Grid className="rowBoxArea" container direction="row" justify="flex-start" alignItems="center">
                <div className="rowBox">
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Typography variant="subtitle2" color="primary"><b>근무복 지급</b></Typography>
                  </Grid>
                </div>
                <Typography variant="subtitle2" color="textPrimary">
                  퀴노스 유니폼을 무제한 지급하여 <b>늘 단정한 모습으로 서비스합니다.</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" wrap="nowrap" className={classes.contentTwo}>
        <Grid item>
          <Grid container direction="column" justify="center" alignItems="center">
            <Typography variant="h2">
              <b>평균 배송 시간</b>
            </Typography>
          </Grid>
          <Grid className="timeLineIcon" container direction="row" justify="center" alignItems="center">
            <Icon className="imgPin"><img alt="" src={IconPinG} /></Icon>
            <Typography className="time">5분</Typography>
            <Icon className="imgRider"><img alt="" src={ImgRider} /></Icon>
            <Typography className="time">40분</Typography>
            <Icon className="imgPin last"><img alt="" src={ImgPin} /></Icon>
          </Grid>
          <div className="line"></div>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="subtitle2"><b>서울 출발</b></Typography>
            <Typography variant="subtitle2" style={{ paddingRight: 20 }}><b>픽업</b></Typography>
            <Typography variant="subtitle2" color="primary"><b>서울 도착</b></Typography>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <div className="rowBox">
              <Grid container direction="row" justify="center" alignItems="center">
                <Typography variant="subtitle1" color="textPrimary" >
                  <b className="pColor">평균 45분</b> 타사 대비 1시간 단축
                </Typography>
              </Grid>
            </div>
          </Grid>
          <Grid className="timeLineIcon" container direction="row" justify="center" alignItems="center">
            <Icon className="imgPin"><img alt="" src={IconPinG} /></Icon>
            <Typography className="time">5분</Typography>
            <Icon className="imgRider"><img alt="" src={ImgRider} /></Icon>
            <Typography className="time">80분</Typography>
            <Icon className="imgPin last"><img alt="" src={ImgPin} /></Icon>
          </Grid>
          <div className="line"></div>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="subtitle2"><b>서울 출발</b></Typography>
            <Typography variant="subtitle2" style={{ paddingRight: 20 }}><b>픽업</b></Typography>
            <Typography variant="subtitle2" color="primary"><b>경기 도착</b></Typography>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <div className="rowBox">
              <Grid container direction="row" justify="center" alignItems="center">
                <Typography variant="subtitle1" color="textPrimary" >
                  <b className="pColor">평균 85분</b> 타사 대비 1시간 단축
                </Typography>
              </Grid>
            </div>
          </Grid>
          <Typography variant="body1" className="memo">
            * 위 사례는 교통 상황이 좋은 경우의 빠른 배송 사례로 성수기, 기상 악화 시 지연이 발생할 수 있으며 서울 지역별 배송시간의 차이가 발생할 수 있습니다.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} direction="row" justify="center" wrap="nowrap" className={classes.contentThree}>
        <Grid item xs={6} style={{ minWidth: 600 }}>
          <Grid className="contentText" container direction="row" justify="flex-end" alignItems="flex-end">
            <Typography variant="h2" color="textPrimary" style={{lineHeight: 1.37}}>
              <b>
                연계 배송을 통한 전국 배송까지!<br></br>
                <span className="pColor">모든 운송 수단 연계</span>가 가능합니다!
              </b>
            </Typography>
            <Grid className="autoBoxArea" container direction="row" justify="flex-end" alignItems="flex-start">
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>오토바이</b></Typography>
                </Grid>
              </div>
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>라보/다마스</b></Typography>
                </Grid>
              </div>
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>1톤 이상 차량</b></Typography>
                </Grid>
              </div>
            </Grid>
            <Grid container direction="row" justify="flex-end" alignItems="flex-start">
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>공항물류 탁송</b></Typography>
                </Grid>
              </div>
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>KTX 탁송</b></Typography>
                </Grid>
              </div>
              <div className="autoBox">
                <Grid className="textArea" container direction="row" justify="center" alignItems="center">
                  <Typography variant="subtitle2" color="primary"><b>고속버스 탁송</b></Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <img alt="" src={ImgServiceTransport} />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" wrap="nowrap" className={classes.contentFour}>
        <Grid item>
          <Typography variant="h2" color="textPrimary" align="center" style={{ lineHeight: 1.37 }}>
            <b>
              안전 배송, 안심 배송!<br></br>
              <span className="pColor">배송 A/S</span>까지 확실히 보장합니다.
          </b>
          </Typography>
          <Grid className="boxArea" container direction="row" justify="center" alignItems="flex-start">
            <div className="box start">
              <Grid container direction="column" justify="center" alignItems="center">
                <img alt="" src={ImgAsOne} />
              </Grid>
              <Typography className="textCenter" variant="subtitle2" color="primary" align="center">
                <b>배송 물품 분실 및 파손 시 업계 최고 금액 보장</b>
              </Typography>
              <Typography variant="subtitle2" color="textPrimary" align="center">
                메리츠화재 적재물 보험 보상<br></br>
                오토바이 기준 <span className="pColor">최대 2,000만원</span> / 차량 기준 <span className="pColor">최대 5,000만원</span>
              </Typography>
            </div>
            <div className="box">
              <Grid container direction="column" justify="center" alignItems="center">
                <img alt="" src={ImgAsTwo} />
              </Grid>
              <Typography className="textCenter" variant="subtitle2" color="primary" align="center">
                <b>배송시간 지연</b>
              </Typography>
              <Typography variant="subtitle2" color="textPrimary" align="center">
                정상 배송 시간 초과 시 요금 <span className="pColor">50% 할인</span><br></br>
                (성수기, 기상 악화 시 제외)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  contentOne: {
    paddingTop: 230,
    paddingBottom: 140,
    backgroundImage: 'linear-gradient(to bottom, #fef7f5, #ffffff)',
    '& h1': {
      paddingBottom: 12
    },
    '& .subTitle': {
      '& h2': {
        paddingRight: 10,
        fontWeight: 500,
        textAlign: 'center',
        lineHeight: 1.37
      }
    },
    '& .subContent': {
      paddingTop: 144,
      paddingBottom: 70,
    },
    '& .miniboxArea': {
      '& .miniBox': {
        marginRight: 120,
      },
      '& .miniBoxlast': {
        marginRight: 0
      },
      '& .last': {
        paddingRight: 0
      }
    },
    '& .subContentTowTitle': {
      paddingTop: 160,
      paddingBottom: 76
    },
    '& .subContentTowBox': {
      width: 900,
      minWidth: 900,
      '& .rowBoxArea': {
        marginBottom: 20,
      }
    }
  },
  miniText: {
    textAlign: 'center',
    paddingTop: 40,
    paddingRight: 120,
  },
  contentTwo: {
    // width: '100%',
    paddingTop: 140,
    paddingBottom: 140,
    backgroundColor: '#f8fbff',
    '& .timeLineIcon': {
      paddingTop: 70,
    },
    '& .last': {
      marginRight: '0 !important;'
    },
    '& .imgPin': {
      width: 40,
      height: 40,
      marginRight: 170,
      '& img': { width: '100% ', height: '100%' }
    },
    '& .time': {
      fontSize: 28,
      fontWeight: 'bold',
      lineHeight: 1.21,
      letterSpacing: -1.4,
      textAlign: 'center',
      color: '#f77c40',
      marginRight: 170,
    },
    '& .imgRider': {
      width: 64,
      height: 64,
      marginRight: 170,
      '& img': { width: '100% ', height: '100%' }
    },
    '& .line': {
      width: 920,
      height: 10,
      margin: '4px 25px 10px 27px',
      borderRadius: 30,
      boxShadow: '0 5px 15px 0 rgba(224, 234, 248, 0.8)',
      backgroundColor: '#ffffff',
    },
    '& .rowBox': {
      width: 540,
      height: 70,
      borderRadius: 60,
      marginTop: 20,
      boxShadow: '0 5px 15px 0 rgba(224, 234, 248, 0.8)',
      backgroundColor: '#ffffff',
      '& h6': {
        lineHeight: 1.3,
        letterSpacing: -1.6,
      }
    },
    '& .memo': {
      paddingTop: 60,
      fontSize: 17,
      lineHeight: 1.89,
      letterSpacing: -0.9,
      textAlign: 'left',
      color: '#a4a4ac',
    }
  },
  contentThree: {
    paddingTop: 85,
    paddingBottom: 140,
    '& .contentText': {
      paddingTop: 35,
    },
    '& .autoBoxArea': {
      marginTop: 60,
      marginBottom: 20,
      marginRight: 23,
    }
  },
  contentFour: {
    paddingBottom: 180,
    '& .boxArea': {
      paddingTop: 80,
      '& .box.start': {
        marginRight: 60,
      }
    },
    '& .box': {
      width: 535,
      height: 380,
      padding: '30px 42px 50px',
      borderRadius: 30,
      boxShadow: '0 15px 30px 0 rgba(255, 146, 92, 0.16)',
      backgroundColor: '#ffffff',
      '& .textCenter': {
        paddingTop: 30,
        paddingBottom: 20,
      }
    }
  }
}));

export default ServiceView;