import React from 'react';
import { NativeSelect } from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';

const CustomSelectbox = props => {
  const { children, ...others } = props;

  return (
    <NativeSelect
      disableUnderline={true}
      IconComponent={others.variant === 'filled' ? ArrowDropDownRoundedIcon : ExpandMoreRoundedIcon}
      {...others}
    >
      {children}
    </NativeSelect>
  );
};

export default CustomSelectbox;