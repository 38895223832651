import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { ImgApp } from '../../static/images';
import { IconOne, IconIt, IconSmart } from '../../static/images/icon';
import { IconMap} from '../../static/images';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 80,
    paddingBottom: 160,
    minWidth: 1200,
  },
  textDesc: {
    fontSize: 38,
    fontWeight: 'bold',
    lineHeight: 1.37,
    letterSpacing: -1.9,
    textAlign: 'center',
    color: '#555558',
  },
  bigBoxArea: {
    marginTop: 80,
    marginBottom: 160,
    '& .bigBox': {
      width: 386,
      height: 224,
      margin: '0 21px 0 0',
      padding: '40px 56px 40px 40px',
      borderRadius: 30,
      boxShadow: '0 15px 30px 0 rgba(255, 146, 92, 0.16)',
      backgroundColor: '#ffffff',
    },
    '& .content_desc': {
      fontSize: 20,
      lineHeight: 1.7,
      letterSpacing: -1,
      textAlign: 'left',
      color: '#555558',
    },
    '& .content_company': {
      paddingTop: 20,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.89,
      letterSpacing: -0.9,
      textAlign: 'left',
      color: '#555558',
      '& span': {
        color: '#f77c40'
      }
    }
  },
  quickService: {
    paddingTop: 80,
    // height: 700,
    paddingBottom: 160,
    width: 820,
    minWidth: 820,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: 477,
      backgroundColor: '#f77c40',
      top: 80,
      bottom: 0,
      left: '50%',
      marginLeft: -3,
    },
    '& .image': {
      '& img': {
        width: 200,
        height: 200,
      },
      paddingTop: 80,
      paddingRight: 120,
    },
    '& .year': {
      alignItems: 'center',
      width: 50,
    },
    '& p': {
      fontSize: 20,
      lineHeight: 1,
      letterSpacing: -1,
      textAlign: 'left',
      color: '#cacacf'
    },
    '& .bold': {
      color: '#f77c40',
    },
    '& .timeline': {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 26,
        height: 26,
        right: -53,
        backgroundColor: 'white',
        border: '8px solid #f77c40',
        boxShadow: '3px 5.2px 10px 0 rgba(153, 153, 153, 0.3)',
        top: -3,
        borderRadius: '50%',
        zIndex: 1,
      },
    },
    '& .timelineBefore': {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 12,
        height: 12,
        right: -47,
        backgroundColor: '#f77c40',
        top: 4,
        borderRadius: '50%',
        zIndex: 1,
      },
    },
    '& .time2019': {
      paddingTop: 40
    },
    '& .timelineText': {
      width: 'auto',
      paddingLeft: 80,
      '& .title': {
        color: '#555558',
        paddingTop: 40,
        lineHeight: 1.7,
        '& span': {
          '& b': {
            color: '#f77c40',
          }
        }
      }
    },
  },
  miniBoxArea: {
    paddingTop: 80,
    // '& .miniBox': {
    //   width: 120,
    //   height: 120,
    //   // margin: '0 120px 40px 0',
    //   marginRight: 40,
    //   padding: 36,
    //   borderRadius: 30,
    //   boxShadow: '0 15px 30px 0 rgba(255, 146, 92, 0.16)',
    //   backgroundColor: '#ffffff',
    //   '& img': {
    //     width: 48,
    //     height: 48,
    //   }
    // },
    '& .miniBoxText': {
      '& span': {
        position: 'relative',
        '&:after': {
          display: 'block',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: 16,
          borderRadius: 3,
          // background: 'rgba(255, 146, 92, 0.5)',
          opacity: 0.16,
          backgroundColor: '#ff925c',
          zIndex: -1,
          content: '""',
        }
      }
    }
  }
}));

function CompanyContent(props) {
  const cs = useStyles();
  return (
    <div className={cs.content}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Typography className={cs.textDesc}>
          기업 담당자님들이 전하는 생생한 후기
        </Typography>
      </Grid>
      <Grid className={cs.bigBoxArea} container direction="row" justify="center" alignItems="center">
        <div className="bigBox">
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <Typography className="content_desc">
              "물류비가 연간 1억 800만원 정도 
              들었는데 퀴노스를 이용하고 물류비를
              28%나 줄일 수 있었어요."
            </Typography>
            <Typography className="content_company">
              <span>광고대행</span> / A기업
            </Typography>
          </Grid>
        </div>
        <div className="bigBox">
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <Typography className="content_desc">
              "퀴노스 기사님들은 늘 친절하고
              안전하게 배송해주셔서 배송 걱정이
              사라졌어요"
            </Typography>
            <Typography className="content_company">
              <span>주류도매</span> / B기업
            </Typography>
          </Grid>
        </div>
        <div className="bigBox">
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <Typography className="content_desc">
              "중요한 계약서도 늘 빠르고 깨끗하게
              배송해주고, 배송 조회도 잘 돼서 
              좋아요. "
            </Typography>
            <Typography className="content_company">
              <span>해운</span> / C기업
            </Typography>
          </Grid>
        </div>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Typography className={cs.textDesc}>
          설립 이후 꾸준하게 성장하며<br></br>
          스마트 배송 서비스의 표준을 만들고 있습니다.
        </Typography>
      </Grid>
      <Grid container direction="row" justify="center" wrap="nowrap">
        <Grid className={cs.quickService} container direction="row" justify="flex-start" alignItems="flex-start">
          <div className="image">
            <img src={ImgApp} />
          </div>
          <Grid className="year" container direction="column" justify="flex-start" alignItems="flex-start">
            <div className="timeline" style={{height: 68}}>
              <Typography className="bold"><b>2021</b></Typography>
            </div>
            <div className="timelineBefore" style={{height: 68}}>
              <Typography><b>2020</b></Typography>
            </div>
            <div className="timelineBefore" style={{ height: 68 }}>
              <Typography ><b>2019</b></Typography>
            </div>
            <div className="timelineBefore" style={{ height: 155 }}>
              <Typography ><b>2018</b></Typography>
            </div>
            <div className="timelineBefore" style={{ height: 105 }}>
              <Typography ><b>2017</b></Typography>
            </div>
            <div className="timelineBefore" >
              <Typography ><b>2016</b></Typography>
            </div>
          </Grid>
          <Grid className="timelineText" container direction="column" justify="flex-start" alignItems="flex-start">
            <Typography className="bold"><b>웹서비스 런칭, 비대면 접수 서비스 강화</b></Typography>
            <Typography className="title"><b>이사 서비스 런칭</b></Typography>
            <Typography className="title">누적 배송90만 건 달성</Typography>
            <Typography className="title">
              <span><b>"퀴노스" 브랜드 런칭</b></span>
              <br></br>카카오톡 채널 런칭(메시지, 사진 접수 시작)
              <br></br>누적 배송 30만 건 달성
            </Typography>
            <Typography className="title">
              벤처 기업 인증<br></br>
              인터넷프로그램 접수 서비스 런징
            </Typography>
            <Typography className="title">
              누적 배송 5만건 달성<br></br>
              <b>법인 설립</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Typography className={cs.textDesc}>
          배송이 똑똑해지다! <span style={{ color: '#f77c40' }}>퀴노스</span>
        </Typography>
      </Grid>
      <Grid className={cs.miniBoxArea} container direction="row" justify="center" alignItems="center">
        <div>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <div className="miniBox">
              <Grid container direction="row" justify="center" alignItems="center">
                <img src={IconSmart} />
              </Grid>
            </div>
            <Typography className="miniBoxText" variant="subtitle2" style={{paddingRight: 225}}>
              '퀴노스만의 스마트 배송 솔루션'으로 고객님의<br></br>
              <span><b>시간과 비용을 절감해드리겠습니다!</b></span>
            </Typography>
            <div className="miniBox">
              <Grid container direction="row" justify="center" alignItems="center">
                <img src={IconIt} />
              </Grid>
            </div>
            <Typography className="miniBoxText" variant="subtitle2">
              숙련된 배송 노하우와 IT 시스템을 바탕으로<br></br>
              <span><b>빠르고 안전한 배송을 보장합니다.</b></span>
            </Typography>
          </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="center" style={{marginTop: 69}}>
            <div className="miniBox">
              <Grid container direction="row" justify="center" alignItems="center">
                <img src={IconOne} />
              </Grid>
            </div>
            <Typography className="miniBoxText" variant="subtitle2" style={{ paddingRight: 300 }}>
              1건의 배송에도 <span><b>최선을 다하겠습니다.</b></span>
            </Typography>
            <div className="miniBox">
              <Grid container direction="row" justify="center" alignItems="center">
                <img src={IconMap} />
              </Grid>
            </div>
            <Typography className="miniBoxText" variant="subtitle2">
              전국 단위의 퀴노스 전속 라이더와 함께<br></br>
              <span><b>업계 최고의 배송 서비스를 만들겠습니다.</b></span>
            </Typography>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

export default CompanyContent;