import { makeAutoObservable, action, observable } from 'mobx';
import { quickTempAPI, freightTempAPI, moveTempAPI, orderCalAPI, bannerSelAPI } from '../api/service';
import { PhoneRegExp } from '../lib/util';


const initial = {
  startAddr: "",
  startAddrDtl: "",
  startPointCode: "",
  startNm: "",
  startTel: "",
  endAddr: "",
  endAddrDtl: "",
  endPointCode: "",
  endNm: "",
  endTel: "",
  vehicleType: "Bike",
  orderType: "",
  reservationTime: "",
  route: "OneWay",
  paymentStatus: "",
  requests: "",
  // isExpress: "N",
  // documentCnt: "0",
  // smallBoxCnt: "0",
  // heavyBoxCnt: "0",
  // greateBoxCn: "0",

  paymentType: 'Card',
}
export default class QuickService {
  root;
  cmStore;
  loinStore;
  payResult = {
    orderDetails: [],
    orderNumber: "",
    totalValue: 0,
  };
  orderResult = {
    isPayment: "",
    orderDetails: null,
    totalValue: 0,
  };
  smallBoxTotalCnt = 0;
  options = initial;
  banner = null;


  constructor(root) {
    this.root = root;
    this.cmStore = this.root.cmStore;
    this.loginStore = this.root.loginStore;

    makeAutoObservable(
      this, {
        options: observable,
        orderResult: observable,
        quickerReg: action,
        orderCalAPI: action
      }
    )
  }

  handleOptions = (name, value) => {
    this.options[name] = value;

    /**
     * 서류(documentCnt)
     * 소박스(smallBoxCnt)  1
     * 중박스(heavyBoxCnt)  3
     * 대박스(greateBoxCnt) 4
     *
     * 소박스 5개 이상 부터는 오토바이 금지
     */
    if (name === "smallBoxCnt" || name === "heavyBoxCnt" || name === "greateBoxCnt") {
      const { documentCnt, smallBoxCnt, heavyBoxCnt, greateBoxCnt } = this.options;
      let cnt = 0;

      if (smallBoxCnt) cnt = cnt + parseInt(smallBoxCnt);
      if (heavyBoxCnt) cnt = cnt + (parseInt(heavyBoxCnt) * 3);
      if (greateBoxCnt) cnt = cnt + (parseInt(greateBoxCnt) * 4);
      // console.log(cnt);
      this.smallBoxTotalCnt = cnt;
    }

  }

  initialOption = () => {
    this.options = initial;
    this.orderResult = {
      isPayment: "",
      orderDetails: null,
      totalValue: 0,
    };
  }

  addressSet = () => {
    const { toAddress, toBcode, fromAddress, fromBcode, etcAddress} = this.root.cmStore;
    this.options["startAddr"] = toAddress;
    this.options["startPointCode"] = toBcode;
    this.options["endAddr"] = fromAddress;
    this.options["endPointCode"] = fromBcode;
    this.options["startAddrDtl"] = etcAddress.startAddrDtl
    this.options["startTel"] = etcAddress.startTel
    this.options["startNm"] = etcAddress.startNm
    this.options["endNm"] = etcAddress.endNm
    this.options["endAddrDtl"] = etcAddress.endAddrDtl
    this.options["endTel"] = etcAddress.endTel
    this.options["addressName"] = etcAddress.addressName
  }

  quickerReg = async (pageType) => {
    await this.addressSet()

    if (this.orderResult.isPayment === 'Y') {
      this.options.paymentStatus = 'Request';
    }

    if (this.options.route === 'LayOver') {
      this.options.paymentStatus = 'AfterConsult';
    }
    // else if (this.options.workerCnt > 0) {
    //   this.options.paymentStatus = 'AfterConsult';
    // }
    else if (this.orderResult.isPayment === 'N') {
      this.options.paymentStatus = 'AfterConsult';
    }

    if (this.options.paymentType === 'CorporateAfter') {
      this.options.paymentStatus = 'AfterConsult';
    }

    const {
      startAddr, startAddrDtl, endAddr, endAddrDtl,
      startNm, startTel, endNm, endTel,
    } = this.options;
    let msg = "";


    if (!startAddr) msg = "출발지 주소를 입력하세요.";
    else if (!startAddrDtl) msg = "출발지 상세 주소를 입력하세요.";
    else if (pageType !== 'move' && !startNm) msg = "보내는분 이름을 입력하세요.";
    else if (pageType !== 'move' && !startTel) msg = "지역번호를 포함한 전화번호 또는 핸드폰번호를 입력해주세요.";
    else if (!endAddr) msg = "도착지 주소를 입력하세요.";
    else if (!endAddrDtl) msg = "도착지 상세 주소를 입력하세요.";
    else if (pageType !== 'move' && !endNm) msg = "받는분 이름을 입력하세요.";
    else if (pageType !== 'move' && !endTel) msg = "지역번호를 포함한 전화번호 또는 핸드폰번호를 입력해주세요.";
    else if (pageType !== 'move' && !PhoneRegExp(startTel)) msg = "지역번호를 포함한 전화번호 또는 핸드폰번호를 정확히 입력해 주세요.";
    else if (pageType !== 'move' && !PhoneRegExp(endTel)) msg = "지역번호를 포함한 전화번호 또는 핸드폰번호를 정확히 입력해 주세요.";

    if (msg) {
      alert(msg);
      return;
    }

    if (pageType === "cargo") {
      this.cargoTempAPICall();
    } else if (pageType === 'move') {
      this.moveTempAPICall();
    } else{
      this.quickTempAPICall();
    }
  }

  quickTempAPICall = async () => {
    let msg = "";
    const { userInfo } = this.loginStore;
    const {
      requestTel, documentCnt, smallBoxCnt, heavyBoxCnt, greateBoxCnt,
      agree,
    } = this.options;

    if (!requestTel) msg = "요청자 연락처를 입력하세요.";
    else if (!documentCnt && !smallBoxCnt && !heavyBoxCnt && !greateBoxCnt) {
      msg = "물량 및 수량을 선택하세요.";
    }else if (documentCnt === "0" && smallBoxCnt === "0" && heavyBoxCnt === "0" && greateBoxCnt === "0") {
      msg = "물량 및 수량을 선택하세요.";
    }else if (!userInfo && (!agree || agree === 'N')) {
      msg = "개인정보 수집 이용 동의해 주세요.";
    } else if (!PhoneRegExp(requestTel)) {
      msg = "지역번호를 포함한 전화번호 또는 핸드폰번호를 정확히 입력해 주세요.";
    }

    if(msg) {
      alert(msg);
      return;
    }

    try {
      const resp = await quickTempAPI(this.options);
      const { data, status } = resp;
      if (status !== 201) throw resp;

      this.payResult = data;
      this.IMPserviceCall();

      // delete this.options["agree"];
      // delete this.options["documentCnt"];
      // delete this.options["smallBoxCnt"];
      // delete this.options["heavyBoxCnt"];
      // delete this.options["greateBoxCn"];
    } catch (error) {
      console.log('%c##### Error-quickTempAPICall: ', 'color: red', error);
    }

  }

  cargoTempAPICall = async () => {
    let msg = "";
    const { userInfo } = this.loginStore;
    const {
      paletteCnt, fuCnt, boxCnt, etcCnt,
      vehicleType, vehicleOptionType, agree
    } = this.options;

    if (!paletteCnt && !fuCnt && !boxCnt && !etcCnt) {
      msg = "물량 및 수량을 선택하세요.";
    } else if (paletteCnt === "0" && fuCnt === "0" && boxCnt === "0" && etcCnt === "0") {
      msg = "물량 및 수량을 선택하세요.";
    } else if (!vehicleType || vehicleType === ""){
      msg = "차량종류를 선택하세요.";
    } else if (!vehicleOptionType || vehicleOptionType === "") {
      msg = "차량옵션를 선택하세요.";
    } else if (!userInfo && (!agree || agree === 'N')) {
      msg = "개인정보 수집 이용 동의해 주세요.";
    }

    if (msg) {
      alert(msg);
      return;
    }

    try {
      const resp = await freightTempAPI(this.options);
      const { data, status } = resp;
      if (status !== 201) throw resp;
      this.payResult = data;
      this.IMPserviceCall();

    } catch (error) {
      console.log('%c##### Error-cargoTempAPICall: ', 'color: red', error);
    }
  }

  moveTempAPICall = async () => {
    delete this.options.isExpress;
    delete this.options.route;
    const { userInfo } = this.loginStore;
    let msg = "";
    const {
      reservationTime, vehicleType, loadingMethod,
      dropMethod, moveStartType, moveEndType,
      moveType, startFloor, endFloor, agree, startTel
    } = this.options;

    if (!moveStartType) msg = "엘리베이터 유무를 선택하세요";
    else if (!moveEndType) msg = "엘리베이터 유무를 선택하세요";
    else if (!startFloor) msg = "출발지 층수를 선택하세요.";
    else if (!endFloor) msg = "도착지 층수를 선택하세요.";
    else if (!moveType) msg = "이사 규모를 선택하세요.";
    // else if (!vehicleType) msg = "차량 종류를 선택하세요.";
    else if (!reservationTime) msg = "이사 날짜를 선택하세요.";
    else if (!loadingMethod) msg = "상차 방법을 선택하세요.";
    else if (!dropMethod) msg = "하차 방법을 선택하세요.";
    else if (!startTel) msg = "요청자 연락처를 입력하세요.";
    else if (!userInfo && !PhoneRegExp(startTel)) msg = "지역번호를 포함한 전화번호 또는 핸드폰번호를 정확히 입력해 주세요.";
    else if (!userInfo && (!agree || agree === 'N')) {
      msg = "개인정보 수집 이용 동의해 주세요.";
    }

    if (vehicleType === 'dd') delete this.options["vehicleType"];

    if (msg) {
      alert(msg);
      return;
    }


    this.options.paymentStatus = "AfterConsult";

    try {
      const resp = await moveTempAPI(this.options);
      const { data, status } = resp;
      if (status !== 201) throw resp;

      window.location.href = "/success";

    } catch (error) {
      console.log('%c##### Error-moveTempAPICall: ', 'color: red', error);
    }
  }

  // 가격계산
  orderCalAPI = async () => {
    await this.addressSet();

    try {
      const params = { ...this.options };

      // 2021.06.10 Deprecated
      // if (params.workerCnt && params.workerCnt > 0) {
      //   params.workerCnt = 1;
      // }
      const resp = await orderCalAPI(params);
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.orderResult = data;

      if (this.options.route === 'LayOver') {
        this.orderResult.isPayment = 'N';
      }

      // if (this.options.workerCnt > 0) {
      //   this.options.paymentStatus = 'AfterConsult';
      // } else {
      //   this.options.paymentStatus = '';
      // }
      this.options.paymentStatus = '';
    } catch (error) {
      console.log('%c##### Error-orderCalAPI: ', 'color: red', error);
    }
  }

  IMPserviceCall = async () => {
    let name = ""

    if (this.options.paymentStatus === 'AfterConsult') {
      window.location.href = "/success";
      return;
    }
    if (!this.payResult.orderNumber) return;
    const { userInfo } = this.loginStore;
    window.IMP.request_pay({
      amount: this.orderResult.totalValue,
      pay_method: this.options.paymentType === "Account" ? "trans" : '',
      merchant_uid: this.payResult.orderNumber,
      buyer_email: userInfo ? userInfo.email: '',
      buyer_name: userInfo ? userInfo.id : '',
      name : '퀴노스'
    }, function(rsp) {
      //결제 후 호출되는 callback함수
      if (rsp.success ) { //결제 성공
        var msg = '결제가 완료되었습니다.';
        msg += '고유ID : ' + rsp.imp_uid;
        msg += '상점 거래ID : ' + rsp.merchant_uid;
        msg += '결제 금액 : ' + rsp.paid_amount;
        msg += '카드 승인번호 : ' + rsp.apply_num;
        window.location.href = "/success";
      } else {
        alert('결제실패 : ' + rsp.error_msg);
      }
    })
  }

  bannerSelCall = async() => {
    try {
      const resp = await bannerSelAPI();
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.banner = data;

    } catch (error) {

    }
  }
}
