import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { useMediaQuery, useTheme } from '@material-ui/core';

import CargoServiceView from '../components/CargoServicePage/CargoServiceView';
import CargoServiceViewM from '../components/CargoServicePage/CargoServiceViewM';

function CargoServicePage({ qsStore }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { initialOption, addressSet } = qsStore;

  useEffect(() => {
    addressSet();

    return () => {
      initialOption();
    }
  }, [])
  
  return (
    <React.Fragment>
      {
        matches ? <CargoServiceViewM /> : <CargoServiceView />
      }
    </React.Fragment>
  );
}

export default inject('qsStore')(CargoServicePage);