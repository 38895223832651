import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { IconSearch } from '../../static/images/icon';

const BtnClear = withStyles(theme => ({
  root: {
    width: 26,
    height: 26,
    background: `url(${IconSearch}) no-repeat center`,
  },
}))(IconButton);


const ButtonClearInput = props => {
  const { ...others } = props;
  return (
    <BtnClear size="small" aria-label="입력값 삭제" className="clearInput" {...others}></BtnClear>
  );
};

export default ButtonClearInput;