import { LoginLayout, MainLayout, ServiceLayout } from '../components/layout';
import { 
  LoginPage, SignUpPage, IDInquiryPage, PWInquiryPage,
  CompanyPage, ServicePage, QuickServicePage, CargoServicePage,
  MoveServicePage, MyPage, LeaveIdPage, DeliveryTrackingPage,
  DetailsOfUsagePage, AddressRegPage, MyAddressPage, ShippingListPage,
  PolicyPage, PaymentSuccess, ChangeResult
} from '../page';

import SignUpSuccessPage from '../components/SignUpPage/Success';

export default [
  { path: `/signup`, layout: LoginLayout, component: SignUpPage, exact: true },
  { path: `/signupsuccess`, layout: LoginLayout, component: SignUpSuccessPage, exact: true },
  { path: `/login`, layout: LoginLayout, component: LoginPage, exact: true },
  { path: `/idInquiry`, layout: LoginLayout, component: IDInquiryPage, exact: true },
  { path: `/pwInquiry`, layout: LoginLayout, component: PWInquiryPage, exact: true },
  { path: `/company`, layout: MainLayout, component: CompanyPage, exact: true },
  { path: `/service`, layout: MainLayout, component: ServicePage, exact: true },
  { path: `/quickservice`, layout: ServiceLayout, component: QuickServicePage, exact: true },
  { path: `/cargoservice`, layout: ServiceLayout, component: CargoServicePage, exact: true },
  { path: `/moveservice`, layout: ServiceLayout, component: MoveServicePage, exact: true },
  { path: `/mypage`, layout: MainLayout, component: MyPage, exact: true },
  { path: `/leaveId`, layout: MainLayout, component: LeaveIdPage, exact: true },
  { path: `/deliverytracking`, layout: MainLayout, component: DeliveryTrackingPage, exact: true },
  { path: `/detailsofusage`, layout: MainLayout, component: DetailsOfUsagePage, exact: true },
  { path: `/addressreg`, layout: MainLayout, component: AddressRegPage, exact: true },
  { path: `/myaddress`, layout: MainLayout, component: MyAddressPage, exact: true },
  { path: `/shippinglist`, layout: MainLayout, component: ShippingListPage, exact: true }, // 최근이용경로
  { path: `/policy`, layout: MainLayout, component: PolicyPage, exact: true },
  { path: `/success`, layout: MainLayout, component: PaymentSuccess, exact: true },
  { path: `/changeresult`, layout: MainLayout, component: ChangeResult, exact: true },
];