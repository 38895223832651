import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Grid, IconButton, Typography, Tooltip, ClickAwayListener } from '@material-ui/core';
import { CustomSelectbox } from '../../button';
import TooltipBox from './TooltipBox';
import { optionCount } from '../../../lib/util';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    '& h6': {
      width: 77,
      lineHeight: 1.4,
      letterSpacing: -1,
    },
    '& .MuiFormControl-root': {
      margin: '0 20px',
      '& select': {
        width: 169,
      }
    },
    '& .tipBox': {
      width: 333,
      height: 130,
      margin: '0 37px 3px 24px',
      padding: '20px 20px 30px 74px',
      objectFit: 'contain',
      boxShadow: '0 5px 15px 0 rgba(202, 202, 207, 0.36)',
      backgroundColor: '#ffffff',
    },
    '&. MuiTooltip-poppe': {
      top: -50,
    },
    '& .MuiTooltip-tooltip': {
      height: 150,
    }
  },
}));
function GoodsAndCount({ item, qsStore }) {
  const classes = useStyles();
  const { handleOptions, orderCalAPI } = qsStore;
  const [tooltip, setToolTip] = useState([false, false, false, false]);

  const handleTooltipOpen = (idx, type) => {
    setToolTip((old) => {
      tooltip[idx] = type === 'close'? false : !tooltip[idx]
      return [...tooltip];
    });
  }

  const handleChange = async (e) => {
    const value = e.target.value;
    const name = e.target.name;
    await handleOptions(name, value);
    await orderCalAPI();
  }
  

  return (
    <React.Fragment>
      <Grid className={classes.root} container direction="column" justify="flex-start" alignItems="flex-start">
        {
          item.map((item, index) => {
            let cnt = 100;
            if (item.name === "documentCnt") cnt = 100;
            else if (item.name === "smallBoxCnt") cnt = 50;
            else if (item.name === "heavyBoxCnt") cnt = 40;
            else if (item.name === "greateBoxCnt") cnt = 24;
            return (
              <Grid key={item.idx} className="countArea" container direction="row" justify="flex-start" alignItems="center">
                <Typography variant="subtitle2"><b>{item.title}</b></Typography>
                <ClickAwayListener  onClickAway={() => handleTooltipOpen(item.idx, 'close')}>
                  <Tooltip
                    arrow
                    placement="top-start"
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => handleTooltipOpen(item.idx, 'close')}
                    open={tooltip[item.idx]}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <TooltipBox title={item.tipTitle} context={item.tipContext} total={item.total}/>
                    }
                  >
                    <IconButton 
                      className={tooltip[item.idx] ? 'questionMarkOn' : 'questionMark'}  
                      onClick={() => handleTooltipOpen(item.idx, 'open')}
                    />
                  </Tooltip>  
                </ClickAwayListener>
                <Grid item>
                  <FormControl fullWidth={false}>
                    <CustomSelectbox 
                      name={item.name}
                      variant="filled" 
                      onChange={handleChange}
                      inputProps={{ 'title': '수량 선택' }}
                    >
                      {
                        optionCount(cnt).map((item) => (
                          <option key={item}>{item}</option>
                        ))
                      }
                      
                      {/* <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option> */}
                    </CustomSelectbox>
                  </FormControl>
                </Grid>
                <Grid item>
                  <img alt="" src={item.icon} />
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
    </React.Fragment>
  );
}

export default inject('qsStore')(GoodsAndCount);