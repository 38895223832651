import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DialogTitle as MuiDialogTitle, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function DialogTitle(props) {
  const cs = useStyles();
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={cs.title} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" className={cs.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

export default DialogTitle;