import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Box, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';

import { ButtonClearInput, CustomButton } from '../button';
import DaumPostcodeDialog from './dialog/DaumPostcodeDialog';
import CustomTextBox from './CustomTextBox';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 656,
    borderRadius: 8,
    boxShadow: '0 5px 10px 0 rgba(247, 124, 64, 0.16)',
    backgroundColor: '#ffffff',
    padding: '30px 24px',
    '& .MuiInputBase-root': {
      marginBottom: 0,
    }
  },
  input: {
    marginLeft: 10,
    width: '83%',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
  },
  addresForm: {
    width: '100%',
    marginTop: 20,
  },
  modBtn: {
    '& .MuiGrid-item': {
      padding: '0 5px',
      width: '50%'
    }
  },
  hei: {
    height: "710px !important;",
  }
}));

function AddressRegForm({ type, cmStore, myPageStore, history, item }) {
  const cs = useStyles();
  const { myAddressReg, myAddressMod, myAddressDel } = myPageStore;
  const { toAddress, fromAddress, setEtcAddress } = cmStore;
  const [addresstype, setAddressType] = useState("to");
  const [addrOpen, setAddrOpen] = useState(false);
  const [params] = useState(item ? item : {});

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    
    params[name] = value;
  }

  const handleAddrOnOff = useCallback((type) => {
    setAddressType(type);
    setAddrOpen(!addrOpen)
  }, [addrOpen]);

  const onSubmit = () => {
    myAddressReg(params, history);
  }

  const onModSubmit = () => {
    myAddressMod(params);
  }

  const onDelSubmit = () => {
    myAddressDel(item.myAddressKey);
  }

  const handlePage = (path) => {
    setEtcAddress("each", item);
    history.push(path);
  }
  
  return (
    <React.Fragment>
      <DaumPostcodeDialog type={addresstype} open={addrOpen} handleClose={handleAddrOnOff} />
      {
        type !== "det" && (
          <CustomTextBox
            title="경로명"
            subTitle="경로명을 입력하세요."
            type="input"
            name="addressName"
            defaultValue={item && item.addressName}
            handleChange={handleChange}
          />
        )
      }
      
      <div className={cs.addresForm}>
        <Box className={clsx(cs.root, type === 'det' && cs.hei)}>
          {
            type === "det" && (
              <>
                <Grid className="" container direction="row" justify="flex-start" alignItems="center">
                  <Typography variant="subtitle2"><b>경로명</b></Typography>
                  <Grid item className={cs.input}>
                    <Box className="mainFormGroup">
                      <Typography style={{paddingLeft: 10, fontWeight: 'bold'}} color="primary" variant="body1">
                        {item && item.addressName}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid> 
                <Box height={16} />
              </>
            )
          }
          <Grid className="" container direction="row" justify="flex-start" alignItems="center">
            <Typography variant="subtitle2"><b>출발지</b></Typography>
            <Grid item className={cs.input}>
              <Box className="mainFormGroup">
                <TextField
                  fullWidth
                  defaultValue={item && item.startNm}
                  name="startNm"
                  placeholder="발신자명을 입력하세요."
                  variant="outlined"
                  className="formArea textBox"
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
          <Box height={16} />
          <TextField
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end"><ButtonClearInput /></InputAdornment>
              ),
            }}
            placeholder="주소를 검색하세요."
            variant="outlined"
            className="formArea textBox"
            value={toAddress}
            onClick={() => handleAddrOnOff('to')}
          />
          <Box height={16} />
          <TextField
            fullWidth
            name="startAddrDtl"
            placeholder="상세 주소를 입력하세요."
            variant="outlined"
            className="formArea textBox"
            defaultValue={item && item.startAddrDtl}
            onChange={handleChange}
          />
          <Box height={16} />
          <TextField
            fullWidth
            name="startTel"
            placeholder="연락처를 입력하세요."
            variant="outlined"
            className="formArea textBox"
            defaultValue={item && item.startTel}
            onChange={handleChange}
          />
          <Box height={16} />
          <Grid className="" container direction="row" justify="flex-start" alignItems="center">
            <Typography variant="subtitle2"><b>도착지</b></Typography>
            <Grid item className={cs.input}>
              <Box className="mainFormGroup">
                <TextField
                  fullWidth
                  name="endNm"
                  placeholder="수신자명을 입력하세요."
                  variant="outlined"
                  className="formArea textBox"
                  defaultValue={ item && item.endNm }
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
          <Box height={16} />
          <TextField
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end"><ButtonClearInput /></InputAdornment>
              ),
            }}
            placeholder="주소를 검색하세요."
            variant="outlined"
            className="formArea textBox"
            value={fromAddress}
            onClick={() => handleAddrOnOff('from')}
          />
          <Box height={16} />
          <TextField
            fullWidth
            name="endAddrDtl"
            placeholder="상세 주소를 입력하세요."
            variant="outlined"
            className="formArea textBox"
            defaultValue={item && item.endAddrDtl}
            onChange={handleChange}
          />
          <Box height={16} />
          <TextField
            fullWidth
            name="endTel"
            placeholder="연락처를 입력하세요."
            variant="outlined"
            className="formArea textBox"
            defaultValue={item && item.endTel}
            onChange={handleChange}
          />
          <Box height={16} />
          {
            type === 'det' && (
              <CustomButton
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => handlePage("/quickservice")}
              >접수/예약하기
              </CustomButton>
            )
          }
        </Box>
        {
          type === "det" 
            ? (
              <>
                <Box height={40} />
                <Grid className={cs.modBtn} container direction="row" justify="center" alignItems="center">
                  <Grid item>
                    <CustomButton fullWidth variant="contained" color="primary" onClick={onModSubmit}>
                      수정
                    </CustomButton>
                  </Grid>
                  <Grid item>
                    <CustomButton fullWidth variant="contained" color="inherit" onClick={onDelSubmit}>
                      삭제
                    </CustomButton>
                  </Grid>
                </Grid>
              </>
            )
            : (
              <>
                <Box height={20} />
                <CustomButton
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={onSubmit}
                >등록하기
                </CustomButton>
              </>
            )
        }
      </div>
    </React.Fragment>
  );
}

export default inject('cmStore', 'myPageStore')(withRouter(observer(AddressRegForm)));