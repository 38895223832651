import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Divider, Grid, Typography } from '@material-ui/core';
import DialogTitle from './DialogTitle';

const useStyles = makeStyles((theme) => ({
  alertPaper: {
    width: 420,
    padding: '10px',
    border: `1px solid ${theme.palette.tertiary.main}`,
    borderRadius: 30,
    boxShadow: '0 0 7px rgba(0, 0, 0, 0.15)',
    '& .MuiDialogContent-root': {
      padding: '8px 14px'
    }
  },
  tipDivider: {
    width: 1,
    height: 16,
    backgroundColor: '#ff925c',
    margin: '7px 6px 0 6px;',
  },
  divider: {
    margin: '10px 0',
  },
  mBootom: {
    marginBottom: 8,
  }
}));

function CostDialog({ open, handleClose }) {
  const cs = useStyles();

  return (
    <Dialog open={open} classes={{ paper: cs.alertPaper }} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        <Typography variant="body1" color="textPrimary" align="center">
          <b>비용 내역</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid className={cs.mBootom} container direction="row" justify="space-between" alignItems="center">
          <Typography variant="body2">비용</Typography>
          <Typography variant="body2" color="primary">20,000원</Typography>
        </Grid>
        <Grid className={cs.mBootom} container direction="row" justify="space-between" alignItems="center">
          <Typography variant="body2">옵션1 - 왕복 비용</Typography>
          <Typography variant="body2" color="primary">20,000원</Typography>
        </Grid>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Typography variant="body2">옵션2 - 왕복 비용</Typography>
          <Typography variant="body2" color="primary">20,000원</Typography>
        </Grid>
        <Divider className={cs.divider} />
        <Grid className={cs.mBootom}  container direction="row" justify="space-between" alignItems="center">
          <Typography variant="body2">배송비</Typography>
          <Typography variant="body2" color="primary"><b>20,000원</b></Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CostDialog;