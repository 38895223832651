import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Typography, TextField, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButton } from '../button';
import GuideMessageM from '../message/GuideMessageM';

const useStyles = makeStyles((theme) => ({
  inputBox: {
    maxWidth: 500,
    padding: '0 30px',
    '& .MuiBox-root': {
      marginBottom: 40,
    },
    margin: '60px 0',
  },

}));

function PasswordForm({ handleCheck, loginStore }) {
  const cs = useStyles();
  const { userInfo } = loginStore;

  return (
    <React.Fragment>
      <Grid item className={cs.title}>
        <GuideMessageM 
          title="탈퇴하기"
          subTitle="탈퇴 시 이용하셨던 모든 기록이 삭제됩니다</br>회원을 탈퇴 하시겠습니까?"
        />
      </Grid>
      <Grid className={cs.inputBox} container direction="column" justify="center" alignItems="flex-start">
        {
          userInfo && userInfo.userJoinType === "Email" && (
            <Box className="mainFormGroup">
              <TextField
                type="password"
                placeholder="비밀번호를 입력해주세요."
                variant="outlined"
                fullWidth
                className="formArea"
              />
              {/* <Typography variant="body2" style={{ color: 'red' }} align="left" gutterBottom>비밀번호가 일치하치 않습니다.</Typography> */}
            </Box>
          )
        }
        <CustomButton
          // disabled
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleCheck('checked')}
        >탈퇴하기</CustomButton>
      </Grid>
    </React.Fragment>
  );
}

export default inject('loginStore')(observer(PasswordForm));