import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomVacantButton } from '../../button';
import CostDialog from '../../common/dialog/CostDialog';
import { numberComma } from '../../../lib/util';
import TotalDetDialog from '../dialog/TotalDetDialog';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  rootM: {
    zIndex: 1,
    backgroundImage: 'linear-gradient(to right, #ff925c 0%, #f77c40 75%, #f77c40 100%);',
    height: 100,
    width: '100%',
    padding: '20px 30px'
  },
  btn: {
    width: 100,
    fontSize: 16,
    backgroundColor: 'transparent',
    borderColor: '#ffffff',
    color: '#ffffff',
    height: 35,
    marginRight: 10
  },
  total: {
    paddingTop: 10,
  },
  fix: {
    position: 'fixed',
    bottom: 0
  },
}));
function TotalBoxM({ qsStore, pageType }) {
  const cs = useStyles();
  const [dialog, setDialog] = useState(false);
  const { orderResult, options } = qsStore;
  const [anchorEl, setAnchorEl] = useState(null);
  const [fix, setFix] = useState(true);

  const scrollEvent = () => {
    const target = document.body;
    const targetOffset = Math.ceil(target.getBoundingClientRect().top);
    if (targetOffset < -1800) {
      setFix(false);
    } else {
      setFix(true);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent);
    return () => {
      window.removeEventListener("scroll", scrollEvent);
    }
  }, []);

  const handleTooltipOpen = (idx, type) => {
    setDialog(!dialog);
  }

  const handlePayInfo = (e) => {
    setAnchorEl(e.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <CostDialog open={dialog} handleClose={handleTooltipOpen}/>
      <div className={clsx(cs.rootM, fix ? cs.fix : '')} >
        <Grid container direction="row"  justify="space-between" alignItems="center">
          <Typography variant="body1" style={{ color: '#ffffff' }}><b>TOTAL</b></Typography>
          {
            (orderResult.isPayment === 'N' || options.paymentStatus === 'AfterConsult' || pageType === "move")
              ? (
                <Typography  variant="subtitle2" style={{ color: '#ffffff' }}><b>접수/예약 완료 시 상담이 진행됩니다.</b></Typography>
              )
              : (
                <Grid style={{ width: 'auto' }} container direction="row" justify="flex-end" alignItems="center">
                  {
                    (orderResult && orderResult.orderDetails) && (
                      <div>
                        <CustomVacantButton
                          aria-describedby={id}
                          variant="contained"
                          color="secondary"
                          className={cs.btn}
                          onClick={handlePayInfo}
                        >세부내역
                        </CustomVacantButton>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                          <TotalDetDialog />
                        </Popover>
                      </div>
                    )
                  }
                  <Typography variant="subtitle2" style={{ color: '#ffffff' }}><b>{numberComma(orderResult.totalValue)} 원</b></Typography>
                </Grid>
                
              )
          }
        </Grid>
        {/* <Grid
          className={cs.total}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          {
            (orderResult.isPayment === 'N' || options.paymentStatus === 'AfterConsult' || pageType === "move")
              ? (
                <Typography variant="subtitle2" style={{ color: '#ffffff' }}><b>접수/예약 완료 시 상담이 진행됩니다.</b></Typography>
              )
              : (
                <Typography variant="subtitle2" style={{ color: '#ffffff' }}><b>{numberComma(orderResult.totalValue)} 원</b></Typography>
              )
          }
        </Grid> */}
      </div>
    </React.Fragment>
  );
}

export default inject('qsStore')(observer(TotalBoxM));
