import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Slider from 'react-slick';

const useStyles = makeStyles((theme) => ({
  sliderMain: {
    width: '100%'
  },
  companySlider: {
    '& .slick-track': {
      left: 30,
    }
  },
  bigBox: {
    width: 310,
    height: 210,
    padding: 30,
    marginBottom: 15,
    borderRadius: 30,
    boxShadow: '0 15px 30px 0 rgba(255, 146, 92, 0.16)',
    backgroundColor: '#ffffff',
    marginLeft: 20,
    centerMode: true,
  }
}));

function ReviewSliderM(props) {
  const cs = useStyles();
  const settings = { 
    dots: false,
    infinite: false,
    autoplay: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: "slider variable-width",
    variableWidth: true
  };
  return (
    <Box className={cs.sliderMain}>
      <Slider className={cs.companySlider} {...settings}>
        <div>
          <div className={cs.bigBox}>
            <Typography variant="body2" color="textPrimary">
              "물류비가 연간 1억 800만원 정도<br></br>
                들었는데 퀴노스를 이용하고 물류비를<br></br>
                28%나 줄일 수 있었어요."
              </Typography>
            <Box height={30} />
            <Typography variant="body1" color="textPrimary">
              <b><span className="pColor">광고대행</span> / A기업</b>
            </Typography>
          </div>
        </div>
        <div>
          <div className={cs.bigBox}>
            <Typography variant="body2" color="textPrimary">
              "퀴노스 기사님들은 늘 친절하고<br></br>
              안전하게 배송해주셔서 배송 걱정이<br></br>
              사라졌어요"
              </Typography>
            <Box height={30} />
            <Typography variant="body1" color="textPrimary">
              <b><span className="pColor">주류도매</span> / B기업</b>
            </Typography>
          </div>
        </div>
        <div>
          <div className={cs.bigBox}>
            <Typography variant="body2" color="textPrimary">
              "중요한 계약서도 늘 빠르고 깨끗하게<br></br>
              배송해주고, 배송 조회도 잘 돼서<br></br>
              좋아요. "
              </Typography>
            <Box height={30} />
            <Typography variant="body1" color="textPrimary">
              <b><span className="pColor">해운</span> / C기업</b>
            </Typography>
          </div>
        </div>
      </Slider>
    </Box>
  );
}

export default ReviewSliderM;