import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomVacantButton = withStyles((theme) => ({
  root: {
    height: 50,
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 28,
    fontSize: 20,
    color: '#ff925c',
    '& .MuiButton-endIcon': {
      right: 0,
      position: 'absolute',
      width: 26,
      height: 26,
      margin: '12px 20px',
    }
  },
  containedPrimary: {
    border: '1px solid #ff925c',
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#fef7f5',
      boxShadow: 'none',
      color: '#ff925c',
    },
  },
  containedSecondary: {
    border: '1px solid #ff925c',
    boxShadow: 'none',
    backgroundColor: '#fef7f5',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      color: '#ff925c',
    },
  },
  disabled: {
    color: '#ffff !important;',
    backgroundColor: '#e4e4e9 !important;',
  }
}))(Button);

export default CustomVacantButton;