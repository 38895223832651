import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import MoveServiceViewM from '../components/MoveServicePage/MoveServiceViewM'
import MoveServiceView from '../components/MoveServicePage/MoveServiceView'

function MoveServicePage({ qsStore }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const { initialOption, addressSet } = qsStore;

  useEffect(() => {
    addressSet();

    return () => {
      initialOption();
    }
  }, [])
  
  return (
    <React.Fragment>
      {
        matches ? <MoveServiceViewM /> : <MoveServiceView />
      }
    </React.Fragment>
  );
}

export default inject('qsStore')(MoveServicePage);