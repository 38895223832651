import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomButton = withStyles((theme) => ({
  root: {
    height: 50,
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 28,
    fontSize: 20,
    color: '#fff',
    boxShadow: 'none',
    '& .MuiButton-endIcon': {
      right: 0,
      position: 'absolute',
      width: 26,
      height: 26,
      margin: '0px 20px',
    }
  },
  containedPrimary: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.white,
    },
  },
  containedSecondary: {
    border: '1px solid #ff925c',
    boxShadow: 'none',
    color: '#ff925c',
    backgroundColor: '#fef7f5',
    '&:hover': {
      backgroundColor: '#fef7f5',
      boxShadow: 'none'
    },
  },
  colorInherit: {
    borderColor: '#a4a4ac',
    backgroundColor: '#a4a4ac',
    '&:hover': {
      backgroundColor: '#555558',
      color: theme.palette.white,
    },
  },
  disabled: {
    color: '#ffff !important;',
    backgroundColor: '#e4e4e9 !important;',
  }
}))(Button);

export default CustomButton;