import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent,Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import DialogTitle from './DialogTitle';

const useStyles = makeStyles((theme) => ({
  alertPaper: {
    width: 420,
    padding: '10px',
    // border: `1px solid ${theme.palette.tertiary.main}`,
    borderRadius: 30,
    boxShadow: '0 0 7px rgba(0, 0, 0, 0.15)',
    '& .MuiDialogContent-root': {
      padding: '8px 14px'
    }
  },
  tooltipVehicleType: {
    '& .MuiTableHead-root': {
      borderBottom: '1px solid #ff925c',
    }, 
    '& .MuiTableCell-root': {
      borderBottom: 'none'

    },
    '& .MuiTableRow-root': {
      '& td': {
        color:'#ff925c',
        fontSize: 18,
        fontWeight: 'bold'
      } 
    }
  }
}));
function VehicleTooltipDialogM({ type, open, item, handleClose }) {
  const cs = useStyles();
  const [loadage, setLoadage] = useState([0, 0, 0, 0, 0, 0, 0]);
  const names = {
    Bike: "바이크",
    Damas: "다마스",
    Labo: "라보",
    Ben: "벤",
    OntT: "1t 트럭",
    OntPointFourT: "1.4t 트럭",
    TwoPointFiveT: "2.5t 트럭",
    ThreePointFiveT: "3.5t 트럭",
    FiveT: "5t 트럭",
    EightT: "8t 트럭",
    ElevenT: "11t 트럭",
  }

  useEffect(() => {
    let temp = [];
    switch (item) {
      case 'Bike':
        temp = [1, 10, 1, 4, 1, 2, 1, 1]
        break;
      case 'Damas':
        if (type === 'quick') {
          temp = [1, 70, 1, 30, 1, 20, 1, 12]
        } else {
          temp = [1.10, 1.60, 0.70, 1.10, 0.45, 2.00, 2.00]
        }
        break;
      case 'Labo':
        if (type === 'quick') {
          temp = [1, 100, 1, 50, 1, 40, 1, 24]
        } else {
          temp = [1.40, 2.20, 0.70, 0, 0.55, 4.00, 2.00]
        }
        break;
      case 'OntT':
        temp = [1.6, 2.8, 0.7, '-', 1.5, 6, 2.2]
        break;
      case 'OntPointFourT':
        temp = [1.7, 3.1, 0.8, '-', 2.5, 8, 2.3]
        break;
      case 'TwoPointFiveT':
        temp = [1.8, 4.3, 1, '-', 4, 14, 2.6]
        break;
      case 'ThreePointFiveT':
        temp = [2.05, 4.6, 1.05, '-', 6, 18, 2.7]
        break;
      case 'FiveT':
        temp = [2.3, 6.2, '1.1~1.2', '-', '6.0~9.9', 26, '3.0~3.2']
        break;
      case 'EightT':
        temp = [2.3, 7.2, 1.4, '-', 10, '-', 2.5]
        break;
      case 'ElevenT':
        temp = [2.35, 9, '1.3~1.4', '-', 24, 58, '3.5~3.8']
      default: break;
    }
    setLoadage(temp);
  }, [item]);

  if (!item) return null;

  return (
    <Dialog open={open} classes={{ paper: cs.alertPaper }} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        <Typography variant="body1" color="textPrimary" align="center">
          <b>{names[item]}</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
         {
           type === 'quick'
           ? (
             <>
                <div className={cs.tooltipVehicleType}>
                  <Table className={cs.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 18 }}>
                          최소 수량
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 18 }}>
                          최대 수량
                         </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                      <TableCell align="center" style={{ color: '#555558' }}>서류</TableCell>
                      <TableCell align="center" style={{ color: '#555558' }}>{loadage[0]}</TableCell>
                      <TableCell align="center" style={{ color: '#555558' }}>{loadage[1]}</TableCell>
                      </TableRow>
                    <TableRow>
                      <TableCell align="center" style={{ color: '#555558' }}>소박스</TableCell>
                      <TableCell align="center" style={{ color: '#555558' }}>{loadage[2]}</TableCell>
                      <TableCell align="center" style={{ color: '#555558' }}>{loadage[3]}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" style={{ color: '#555558' }}>중박스</TableCell>
                      <TableCell align="center" style={{ color: '#555558' }}>{loadage[4]}</TableCell>
                      <TableCell align="center" style={{ color: '#555558' }}>{loadage[5]}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" style={{ color: '#555558' }}>대박스</TableCell>
                      <TableCell align="center" style={{ color: '#555558' }}>{loadage[6]}</TableCell>
                      <TableCell align="center" style={{ color: '#555558' }}>{loadage[7]}</TableCell>
                    </TableRow>
                    </TableBody>
                  </Table>
                </div>
             </>
           )
           : (
             <>
             <div className={cs.tooltipVehicleType}>
              <Table className={cs.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      적재넓이<br></br>
                      (단위:m)
                    </TableCell>
                    <TableCell align="center">
                      길이<br></br>
                      (단위:m)
                    </TableCell>                
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center" className="pColor">{loadage[0]}</TableCell>
                    <TableCell align="center" className="pColor">{loadage[1]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={cs.tooltipVehicleType}>
              <Table className={cs.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                  <TableCell align="center">
                      바닥높이<br></br>
                      (단위:m)
                    </TableCell>
                    <TableCell align="center">
                      탑높이<br></br>
                      (단위:m)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                  <TableCell align="center" className="pColor">{loadage[2]}</TableCell>
                    <TableCell align="center" className="pColor">{loadage[3]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={cs.tooltipVehicleType}>
              <Table className={cs.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      적재중량<br></br>
                      (단위:t)
                    </TableCell>
                    <TableCell align="center">
                      적재부피<br></br>
                      (단위:cbm)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center" className="pColor">{loadage[4]}</TableCell>
                    <TableCell align="center" className="pColor">{loadage[5]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={cs.tooltipVehicleType}>
              <Table className={cs.table} aria-label="simple table" style={{width: 'auto'}}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      차량총높이<br></br>
                      (단위:m)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center" className="pColor">{loadage[6]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
             </>
           )
         }
        
      </DialogContent>
    </Dialog>
  );
}

export default VehicleTooltipDialogM;