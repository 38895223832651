import axios from 'axios';
import { makeAutoObservable, action, observable } from 'mobx';
import { meAPI, snsLoginAPI, snsJoinAPI, emailJoinAPI, emailLoginAPI, leaveAPI, findPwdAPI, changePwdAPI, phoneChcekResultAPI, phoneChangeAPI, findIdAPI } from '../api/login';
import { EmailRegExp, PhoneRegExp } from '../lib/util';

export default class login {
  root;
  userInfo = null;
  naverAuth2 = null;
  userKey = null;
  phoneCheck = false;
  impUid = null;
  findId = null;

  constructor(root) {
    this.root = root;
    makeAutoObservable(
      this, {
        adminInfo: observable,
        userKey: observable,
        snsLogin: action,
        emailLogin: action,
        meCall: action
      }
    )
  };

  meCall = async () => {
    try {
      const resp = await meAPI();
      const { data, status } = resp;
      if (status !== 200) throw status;

      this.userInfo = data;
    } catch (error) {
      console.log('%c##### Error-meCall: ', 'color: red', error);
    }
  };


  //SNS가입
  snsJoin = async (params) => {
    try {
      const resp = await snsJoinAPI(params);
      const { status } = resp;
      if (status === 409 || status === 201) {
        this.snsLogin(params);
      }
    } catch (error) {
      console.log('%c##### Error-snsJoin: ', 'color: red', error);
    }
  }

  // SNS로그인
  snsLogin = async (params) => {
    try {
      const resp = await snsLoginAPI(params);
      const { data, status } = resp;

      await localStorage.setItem('qwdmtk', data.token);
      // await this.meCall();
      window.location.href = "/";
    } catch (error) {
      console.log('%c##### Error-snsLogin error: ', 'color: red', error);
    }
  }

  // kakao 로그인
  kakaologin = async () => {
    const accessToken = new Promise((resolve, reject) => {
      window.Kakao.Auth.login({
        success: function (authObj) {
          const {
            access_token,
            // token_type,
            // refresh_token,
            // expires_in,
            // refresh_token_expires_in
          } = authObj;

          resolve(access_token);
        },
        fail: function (error) {
          console.log('%c##### debug-error: ', 'color: #058FD7', JSON.stringify(error));
        },
      })
    })

    accessToken.then((token) => {
      this.snsJoin({ socialToken: token, socialType: 'Kakao' });
    })
  }

  googleLoginInit = () => {
    const googleAuth2 = new Promise((resolve) => {
      window.gapi.load('auth2', function () {
        resolve(
          window.gapi.auth2.init({
            client_id: '614361534660-969uun7v7knjmldsolltncttv0jjmfoj.apps.googleusercontent.com',
            // cookiepolicy: 'single_host_origin',
            prompt: 'consent'
          })
        );
      });
    })
    googleAuth2.then((res) => {
      this.googleLogin(res, document.getElementById('googleBtn'));
    });
  }

  // 구글 로그인
  googleLogin = (googleAuth2, element) => {
    // console.log(element.id);
    const google = new Promise((resolve) => {
      googleAuth2.attachClickHandler(element, {
        prompt: 'consent'
      },
        function (googleUser) {
          const { uc } = googleUser;
          resolve(uc.access_token);
        }, function (error) {
          resolve(error);
        }
      );
    });

    google.then((res) => {
      this.snsJoin({ socialToken: res, socialType: 'Google' });
    })
  }

  // 네이버 로그인
  naverLogin = async () => {
    const naverAuth2 = new window.naver.LoginWithNaverId(
      {
        clientId: "qbS2hes5VHizgMLTehZw",
        callbackUrl: "http://www.quinors.com/login",
        isPopup: false, /* 팝업을 통한 연동처리 여부 */
        callbackHandle: true,
        loginButton: {
          color: "green", type: 1, height: 60
        } /* 로그인 버튼의 타입을 지정 */
      }
    );
    naverAuth2.init();
    this.naverEvent();
    window.addEventListener('load', this.naverEvent(naverAuth2));
  }

  naverEvent = (naverAuth2) => {

    if (!naverAuth2) return;
    this.naverAuth2 = naverAuth2;
    const naver = new Promise((resolve) => {
      naverAuth2.getLoginStatus(function (status) {
        if (status) {
          resolve(naverAuth2.accessToken.accessToken);
        } else {
          console.log("callback 처리에 실패하였습니다.");
        }
      });
    });

    naver.then((res) => {
      this.snsJoin({ socialToken: res, socialType: 'Naver' });
    })
  }

  // 네이버 회원 탈퇴 / 로그아웃
  naverLeaveAPI = (cTk) => {
    axios({
      url: `https://nid.naver.com/oauth2.0/token?grant_type=delete&client_id=qbS2hes5VHizgMLTehZwclient_secret=R_Hbrsn_eX&access_token=${cTk}&service_provider=NAVER`,
    })
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response;
      })
  }

  // 구글 회원 탈퇴 / 로그아웃
  googleLeave = (type) => {
    const auth2 = window.gapi.auth2.getAuthInstance()
    if (type === 'logout') {
      localStorage.clear();
      // auth2.signOut().then(() => {
      //   console.log("## google logout");
      // })
    } else {
      auth2.disconnect();
    }
  }

  // 카카오 회원탈퇴 / 로그아웃
  kakaoLeave = (type) => {
    if (type === 'logout') {
      if (!window.Kakao.Auth.getAccessToken()) {
        console.log('Not logged in.');
        return;
      }
      window.Kakao.Auth.logout(function () {
        console.log(window.Kakao.Auth.getAccessToken());
      });
    } else {
      window.Kakao.API.request({
        url: '/v1/user/unlink',
        success: function (response) {
          console.log(response);
        },
        fail: function (error) {
          console.log(error);
        },
      });
    }
  };

  // 로그아웃 토큰 삭제 및 회원정보 초기화
  logoutAndInitialUser = (type) => {
    const { userJoinType } = this.userInfo;
    if (userJoinType === "Naver") {
      // this.naverLeaveAPI(type);
      localStorage.removeItem('com.naver.nid.access_token');
      localStorage.removeItem('com.naver.nid.oauth.state_token');
    } else if (userJoinType === 'Kakao') {
      this.kakaoLeave(type);
    } else if (userJoinType === 'Google') {
      this.googleLeave(type);
    }

    localStorage.removeItem('qwdmtk');
    this.userInfo = null;
  }

  // 회원탈퇴
  leaveCall = async (params, func) => {
    try {
      const resp = await leaveAPI(params);
      const { status } = resp;
      if (status !== 200) throw resp;
      this.logoutAndInitialUser()
      func();
    } catch (error) {
      console.log('%c##### Error-levaeCall: ', 'color: red', error);
    }
  }

  phoneInitial = () => {
    this.phoneCheck = false;
    this.impUid = null;
  }

  //핸드폰 인증
  phoneAuth = () => {

    window.IMP.certification({ // param
      merchant_uid: 'merchant_' + new Date().getTime()
    }, rsp => { // callback
      if (rsp.success) {
        if (rsp.success) {
          this.impUid = rsp.imp_uid;
          this.phoneCheck = rsp.success;
        }
      } else {
        console.log('%c##### Error-res: ', 'color: red', rsp);
      }
    });
  }

  phoneCheckResultCall = async (imp_uid) => {
    // console.log("##### phoneCheckResultCall");
    try {
      const resp = phoneChcekResultAPI(imp_uid);
      // console.log({ resp });
    } catch (error) {
      console.log('%c##### Error-res: ', 'color: red', error);
    }
  }

  // 이메일 회원 가입
  emailJoin = async (params, history) => {
    const { email, password } = params;
    let msg = "";

    if (!this.phoneCheck) msg = "핸드폰 인증을 해주세요.";

    if (!email) msg = "이메일을 이력하세요.";
    else if (!password) msg = "비밀번호를 입력해주세요..";

    if (msg) {
      alert(msg);
      return;
    }

    params["uid"] = this.impUid;
    // delete params.password1;

    try {
      const resp = await emailJoinAPI(params);
      const { data, status } = resp;

      if (status !== 201) throw resp;
      history.push('/signupsuccess');
    } catch (error) {
      if (error.data && error.data.message) {
        alert(error.data.message);
      }
      console.log('%c##### Error-emailJoin: ', 'color: red', error);
    }
  }

  // 이메일 로그인
  emailLogin = async (params, history) => {
    let msg = "";
    if (!params.email) msg = "이메일을 입력하세요.";
    else if (!params.password) msg = "비밀번호를 입력해주세요.";

    if (msg) {
      alert(msg);
      return;
    }

    try {
      const resp = await emailLoginAPI(params);
      const { data, status } = resp;

      if (status !== 200) {
        if (!data.message) throw status;
        else alert(data.message);

        throw status;
      }

      // 아이디 체크박스가 true면 저장
      if (params.saveId) {
        localStorage.setItem('qadmid', params.adminId);
      } else {
        localStorage.removeItem('qadmid');
      }

      await localStorage.setItem('qwdmtk', data.token);
      await this.meCall();

      history.push('/quickservice');

    } catch (error) {
      console.log('%c##### Error-emailLogin: ', 'color: red', error);
    }
  }

  findPwdCall = async (p, func) => {
    if (!this.impUid) {
      console.log("##### not impUid");
      return;
    }

    const params = {
      email: p.email,
      uid: this.impUid
    };

    try {
      const resp = await findPwdAPI(params);
      const { status, data } = resp;
      if (status !== 200) throw resp;
      this.phoneInitial();
      this.userKey = data.userKey;
      func();
      console.log('%c##### debug-userKey: ', 'color: #058FD7', this.userKey);
    } catch (error) {
      console.log('%c##### Error-findPwdCall: ', 'color: red', error);
    }
  }

  changePwdCall = async (params, func) => {
    let msg = "";
    if (!params.password1 || !params.password) msg = "비밀번호를 입력해주세요..";
    if (params.password1 !== params.password) msg = "비밀번호가 일치하지 않습니다.";

    if (msg) {
      alert(msg);
      return;
    }

    try {
      params["userKey"] = this.userKey;

      const resp = await changePwdAPI(params);
      const { status } = resp;
      if (status !== 200) throw resp;

      alert("변경되었습니다.");
      func();
    } catch (error) {
      console.log('%c##### Error-changePwdCall: ', 'color: red', error);
    }
  }

  // 핸드폰 번호 변경
  phoneChangeCall = async (func) => {
    if (!this.impUid) {
      console.log("##### not impUid");
      return;
    }

    const params = {
      uid: this.impUid
    };

    try {
      const resp = await phoneChangeAPI(params);
      const { status } = resp;
      if (status !== 200) throw resp;
      this.phoneInitial();
      func();
    } catch (error) {
      if (error.data && error.data.message) {
        alert(error.data.message);
      }
      console.log('%c##### Error-phoneChangeCall: ', 'color: red', error);
    }
  }

  findIdCall = async (func) => {
    if (!this.impUid) {
      console.log("##### not impUid");
      return;
    }

    const params = {
      uid: this.impUid
    };

    try {
      const resp = await findIdAPI(params);
      const { status, data } = resp;
      if (status !== 200) throw resp;
      this.findId = data.email;
      this.phoneInitial();
      func();

    } catch (error) {
      console.log('%c##### Error-findIdCall: ', 'color: red', error);
    }

  }
}
