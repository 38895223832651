import React from 'react';
import { Grid } from '@material-ui/core';
import { Address, MovingSize, PayBox, ServiceTitle, TotalBox } from '../../components/common/eachService';
import UpAndDown from '../../components/MoveServicePage/UpAndDown';

function MoveServiceView(props) {
  const moveItem = [
    { 
      idx: 0,
      value: 'OneRoom',
      title: '원룸이사',
      tip: '' ,
    },
    { 
      idx: 1,
      value: 'SmallFamilyMoving',
      title: '가정이사(작은 짐)',
      tip: '' 
    },
    { 
      idx: 2,
      value: 'HeavyFamilyMoving',
      title: '가정이사(많은 짐)',
      tip: '' 
    },
    { 
      idx: 3,
      value: 'Office',
      title: '사무실이사',
      tip: '' 
    },
  ]
  return (
    <React.Fragment>
      <div className="servicePageArea">
        <Grid className="addressRoot" container direction="row" justify="flex-start" alignItems="flex-start">
          <div className="addrs">
            <Grid className="left" container direction="column" justify="flex-start" alignItems="flex-start">
              <ServiceTitle
                isBtn
                title="출발지와 도착지를 선택해주세요."
                subTitle="로그인하시면 자주 쓰는 경로와 최근 이용 경로 조회가 가능합니다."
              />
              <Address type="move"/>
            </Grid>
          </div>
          <div className="count">
            <Grid className="right" container direction="column" justify="flex-start" alignItems="flex-start">
              <ServiceTitle
                title="이사규모"
                subTitle="물음표를 누르시면, 크기와 무게 기준을 알려드려요."
              />
            </Grid>
            <MovingSize items={moveItem} name="moveType"/>
          </div>
        </Grid>
        <UpAndDown />
        <PayBox pageType="move"/>
      </div>
      <TotalBox pageType="move"/>
    </React.Fragment>
  );
}

export default MoveServiceView;