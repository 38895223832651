import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { ServiceTitle, Address, GoodsAndCount, PayBox, TotalBox } from '../../components/common/eachService';
import { ImgBoxLarge, ImgBoxMedium, ImgBoxSmall, ImgPapers } from '../../static/images';
import DeliveryOption from '../../components/QuickServicePage/DeliveryOption';

const useStyles = makeStyles((theme) => ({
}));

function QuickServiceView(props) {

  const goodsItem = [
    { 
      idx: 0, 
      title: '서류', 
      tip: '팔레트', 
      icon: ImgPapers, 
      name: 'documentCnt',
      total: '10',
      tipTitle: '<span class="pColor">계약서, 일반A4서류, 핸드폰, USB</span> 등',
      tipContext: '<span class="pColor">중량 1kg 이하, A4용지</span> 수납이 가능한 일반 서류 및 소형 물품'
    },
    { 
      idx: 1, 
      title: '소박스', 
      tip: '박스', 
      icon: ImgBoxSmall, 
      name: 'smallBoxCnt',
      total: '4',
      tipTitle: '<span class="pColor">A4박스, 쇼핑백, 운동화박스, 노트북가방, 쇼핑백 </span> 등',
      tipContext: '<span class="pColor">중량 10kg 미만,</span> 3변의 합이 90cm 미만의 물품'
    },
    { 
      idx: 2, 
      title: '중박스', 
      tip: '가구/집기', 
      icon: ImgBoxMedium, 
      name: 'heavyBoxCnt',
      total: '2',
      tipTitle: '<span class="pColor">사과박스, 라면박스, PC, 중봉</span> 등',
      tipContext: '<span class="pColor">중량 15kg 미만,</span> 3변의 합이 120cm 미만의 물품'
    },
    { 
      idx: 3, 
      title: '대박스', 
      tip: '기타', 
      icon: ImgBoxLarge, 
      name: 'greateBoxCnt',
      total: '1',
      tipTitle: '<span class="pColor">이사용박스, 카툰박스, 대봉, 종이컵박스</span> 등',
      tipContext: '<span class="pColor">중량 15kg 초과,</span> 3변의 합이 120cm 초과의 물품'
    },
  ]

  return (
    <React.Fragment>
      <div className="servicePageArea">
        <Grid className="addressRoot" container direction="row" justify="flex-start" alignItems="flex-start">
          <div className="addrs">
            <Grid className="left" container direction="column" justify="flex-start" alignItems="flex-start">
              <ServiceTitle
                isBtn
                title="출발지와 도착지를 선택해주세요."
                subTitle="로그인하시면 자주 쓰는 경로와 최근 이용 경로 조회가 가능합니다."
              />
              <Address type="quick" />
            </Grid>
          </div>
          <div className="count" >
            <Grid className="right" container direction="column" justify="flex-start" alignItems="flex-start">
              <ServiceTitle
                title="물품 및 수량"
                subTitle="물음표를 누르시면, 크기와 무게 기준을 알려드려요."
              />
              <GoodsAndCount item={goodsItem} />
            </Grid>
          </div>
        </Grid>
        <DeliveryOption />
        <PayBox />
      </div>
      <TotalBox />
    </React.Fragment>
  );
}

export default QuickServiceView;