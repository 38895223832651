import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Footer from './Footer';
import Header from './Header';
import { useMediaQuery, useTheme } from '@material-ui/core';
import FooterM from './FooterM';
import HeaderM from './HeaderM';

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function LoginLayout(props) {
  const { children } = props;
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      {
        matches
          ? <HeaderM />
          : <Header type="loginAppbar" />
      }
      <div className={classes.container}>{children}</div>
      {
        matches
          ? <FooterM />
          : <Footer />
      }
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    // minHeight: 600,
    paddingTop: 110,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  footer: {
    marginTop: 120,
    paddingTop: 17,
    paddingBottom: 17,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  copy: {
    fontSize: 11,
    lineHeight: 1.8,
    '& .divider': {
      height: 8,
    },
  },
}));

export default LoginLayout;