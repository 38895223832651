import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import { makeStyles } from '@material-ui/core/styles';

function GuideMessageM({ title, subTitle, resultTitle, mTitle, cmStore}) {
  const { setMobileTitle } = cmStore;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setMobileTitle(mTitle ? mTitle : title);
  }, [])

  return (
    <React.Fragment>
      { !matches && (
          <Typography 
            variant={matches ? 'subtitle1' : 'h2'} 
            color="primary" 
            gutterBottom 
            align="center">
            <b><span dangerouslySetInnerHTML={{ __html: title }} /></b>
          </Typography>
        ) 
      }
      { (matches && resultTitle) && (
          <Typography 
            variant={matches ? 'subtitle1' : 'h2'} 
            color="primary" 
            gutterBottom 
            align="center"
            style={{lineHeight: 1.5}}
          >
            <b><span dangerouslySetInnerHTML={{ __html: resultTitle }} /></b>
          </Typography>
        )
      }
      <Typography variant="body1" color="textPrimary" align="center">
        <span dangerouslySetInnerHTML={{ __html: subTitle }} />
      </Typography>
    </React.Fragment>
  );
}

export default inject('cmStore')(GuideMessageM);