import React,  { useState, useEffect } from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { MenuIconKakao, ImgProfile } from '../../../static/images';
import { CustomButton, CustomVacantButton } from '../../button';

const useStyles = makeStyles((theme) => ({
  panel: {
    padding: '10px 20px 20px',
  },
  line: {
    width: '100%',
    margin: '20px 0'
  },
  iconProfile: {
    width: 30,
    height: 30,
    margin: '0 10px',
  },
  icon: {
    width: 30,
    height: 30,
    marginLeft: 10,
  },
  btn: { margin: '10px 0' },
  alink: {
    color: theme.palette.primary.main
  },
  bgW: {
    background: '#ffffff',
    '&:hover': {
      background: 'transparent'
    }
  },
  hi: {
    cursor: 'pointer'
  }
}));
function LoginMenu({ handlePage, loginStore }) {
  const cs = useStyles();
  const { userInfo, logoutAndInitialUser } = loginStore;
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    let login = false;
    if (userInfo && userInfo.userKey) login = true;

    setIsLogin(login);
  }, [userInfo, isLogin])

  const kakaoTest = () => {
    window.Kakao.Channel.chat({
      channelPublicId: '_AsExjC'
    });
  }

  const logout = async () => {
    await logoutAndInitialUser('logout')
    handlePage('/');
  }

  return (
    <Grid className={cs.panel} container direction="column" justify="center" alignItems="center">
      {
        isLogin && (
          <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.hi} onClick={() =>  handlePage('/mypage')}>
            <img className={cs.iconProfile} src={ImgProfile} />
            <Typography variant="body1" className="pageHead" color="textPrimary">
              안녕하세요 <b style={{ color: '#f77c40' }}>{userInfo ? userInfo.name : '회원'}</b>님
            </Typography>
          </Grid>
        )
      }
      <Box height={40} />
      {
        isLogin && (
          <>
            {/* <Button onClick={() => handlePage('/mypage')}>
              <Typography variant="subtitle2" className="pageHead" color="textPrimary"><b>마이페이지</b></Typography>
            </Button> */}
            <Button onClick={() => handlePage('/deliverytracking')}>
              <Typography variant="subtitle2" className="pageHead" color="textPrimary"><b>배송조회</b></Typography>
            </Button>
            <Divider className={cs.line} />
            <Button onClick={() => handlePage('/myaddress')}>
              <Typography variant="subtitle2" className="pageHead" color="textPrimary"><b>자주쓰는 경로</b></Typography>
            </Button>
            <Divider className={cs.line} />
            <Button onClick={() => handlePage('/shippinglist')}>
              <Typography variant="subtitle2" className="pageHead" color="textPrimary"><b>최근 이용경로</b></Typography>
            </Button>
            <Divider className={cs.line} />
            <Button onClick={() => handlePage('/policy')}>
              <Typography variant="subtitle2" className="pageHead" color="textPrimary"><b>약관/정책보기</b></Typography>
            </Button>
            <Divider className={cs.line} />
          </>
        )
      }
      <Button onClick={() => handlePage('/company')}>
        <Typography variant="subtitle2" className="pageHead" color="textPrimary"><b>회사 소개</b></Typography>
      </Button>
      <Divider className={cs.line} />
      <Button onClick={() => handlePage('/service')}>
        <Typography variant="subtitle2" className="pageHead" color="textPrimary"><b>서비스 소개</b></Typography>
      </Button>
      <Divider className={cs.line} />
      <Grid container direction="row" justify="center" alignItems="center">
        <Button onClick={kakaoTest}>
          <Typography variant="subtitle2" className="pageHead" color="primary"><b>실시간 상담</b></Typography>
          <img className={cs.icon} src={MenuIconKakao} />
        </Button>
      </Grid>
      <Box height={60} />
      {
        !isLogin && (
          <>
            <CustomButton
              fullWidth
              className={clsx(cs.btn, cs.bgW)}
              variant="contained"
              color="secondary"
              onClick={() => handlePage("/login")}
            >로그인
            </CustomButton>
            <CustomButton
              fullWidth
              className={clsx(cs.btn, cs.bgW)}
              variant="contained"
              color="secondary"
              onClick={() => handlePage("/signup")}
            >회원가입
            </CustomButton>
          </>
        )
      }
      <CustomButton
        fullWidth
        className={cs.btn}
        variant="contained"
        color="primary"
        onClick={() => handlePage("/quickservice")}
      >접수/예약하기
      </CustomButton>
      <Box height={40} />
      {
        isLogin && (
          <a onClick={logout} className={cs.alink}>
            <Typography variant="body2" color="primary">로그아웃</Typography>
          </a>
        )
      }
    </Grid>
  );
}

export default inject("loginStore")(observer(LoginMenu));