import React, { useEffect } from 'react';
import clsx from 'clsx';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

import { ImgCompanyMainM } from '../../static/images';
import GuideMessageM from '../message/GuideMessageM';
import CompanySlider from './CompanySlider';
import ReviewSliderM from './ReviewSliderM';
import { IconOne, IconIt, IconRider, IconSmart } from '../../static/images/icon';
import { IconMap } from '../../static/images';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    paddingTop: 0,
    paddingBottom: 50,
  },
  main: { backgroundColor: '#ff925c' },
  titleArea: {
    height: 400,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${ImgCompanyMainM})`,
  },
  contentOne: {
    paddingTop: 100,
    '& h6': { color: '#ffffff'},
    '& p': { color: '#ffffff' },
  },
  oneSub: {
    padding: '50px 20px 0 20px'
  },
  subTitleArea: {
    paddingBottom: 30
  },
  textCount: {
    '& .title': {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.07,
      letterSpacing: -1.4,
      textAlign: 'left',
      color: '#ffe7d4',
      display: 'inline-block',
      position: 'relative',
      zIndex: 2,
      '&:after': {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 16,
        borderRadius: 3,
        background: '#F44424',
        zIndex: -1,
        content: '""',
      }
    },
    '& .count': {
      fontSize: 28,
      fontWeight: 'bold',
      lineHeight: 1.33,
      letterSpacing: -3,
      textAlign: 'left',
      color: '#ffffff',
    }
  },
  mb20: {
    marginBottom: 20,
  },
  contentfour: {
    padding: '20px 20px 0 20px'
  },
  bigBoxArea: {
    marginTop: 40,
    marginBottom: 60,
  },
  quickService: {
    paddingTop: 80,
    // height: 700,
    // paddingBottom: 160,
    width: 338,
    minWidth: 338,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: 430,
      backgroundColor: '#f77c40',
      top: 80,
      bottom: 0,
      left: '24.6%',
      marginLeft: -3,
    },
    '& .image': {
      '& img': {
        width: 200,
        height: 200,
      },
      paddingTop: 80,
      paddingRight: 120,
    },
    '& .year': {
      alignItems: 'center',
      width: 50,
    },
    '& p': {
      fontSize: 14,
      lineHeight: 1,
      letterSpacing: -1,
      textAlign: 'left',
      color: '#cacacf'
    },
    '& .bold': {
      color: '#f77c40',
    },
    '& .timeline': {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 26,
        height: 26,
        right: -53,
        backgroundColor: 'white',
        border: '8px solid #f77c40',
        boxShadow: '3px 5.2px 10px 0 rgba(153, 153, 153, 0.3)',
        top: -3,
        borderRadius: '50%',
        zIndex: 1,
      },
    },
    '& .timelineBefore': {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 12,
        height: 12,
        right: -47,
        backgroundColor: '#f77c40',
        top: 4,
        borderRadius: '50%',
        zIndex: 1,
      },
    },
    '& .time2019': {
      paddingTop: 40
    },
    '& .timelineText': {
      width: 'auto',
      paddingLeft: 60,
      '& .title': {
        color: '#555558',
        lineHeight: 1.7,
        '& span': {
          '& b': {
            color: '#f77c40',
          }
        }
      }
    },
  },
  contentfive: {
    paddingTop: 100,
  },
  miniBoxArea: {
    padding: '20px 20px',
  },
  miniBoxText: {
    '& span': {
      position: 'relative',
      zIndex: 1,
      '&:after': {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 10,
        borderRadius: 3,
        opacity: 0.16,
        backgroundColor: '#ff925c',
        zIndex: -1,
        content: '""',
      },
      '& br': {
        '&:after': {
          display: 'block',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: 10,
          borderRadius: 3,
          background: 'rgba(255, 146, 92, 0.5)',
          zIndex: 1,
          content: '""',
        }
      }
    }
  },
  box: { paddingBottom: 20 },
}));
function CompanyInfoViewM({ cmStore }) {
  const cs = useStyles();
  const { setMobileTitle } = cmStore;

  useEffect(() => {
    setMobileTitle('회사 소개');
  }, []);

  return (
    <React.Fragment>
      <div className={clsx("mainLayout", cs.root)}>
        <Box className={cs.main}>
          <div className={cs.titleArea}>
            <Grid className={cs.contentOne} container direction="column" justify="center" alignItems="center">
              <Grid item>
                <GuideMessageM
                  resultTitle="우리는</br>빠르고 안전합니다."
                  subTitle="스마트 배송 퀴노스와 함께하세요."
                />
              </Grid>
            </Grid>
            <Grid className={cs.oneSub} container direction="row" justify="center" alignItems="flex-start">
              <Typography variant="subtitle2" color="secondary">
                <b>
                  퀴노스,<br></br>
                  기업 회원님들과 함께 성장하고 있습니다.
                </b>
              </Typography>
            </Grid>
          </div>
          <div className={cs.subTitleArea} style={{ paddingLeft: 25 }} >
            <div className={cs.textCount}>
              <Grid container direction="column" justify="center" alignItems="flex-start">
                <Typography className={cs.mb20}>
                  <span className="title">기업 회원 수</span>
                  <br></br>
                  <span className="count">35,713명</span>
                </Typography>
                <Typography className={cs.mb20}>
                  <span className="title">누적 배송 건</span>
                  <br></br>
                  <span className="count">832,662건</span>
                </Typography>
                <Typography className={cs.mb20}>
                  <span className="title">누적 거래액</span>
                  <br></br>
                  <span className="count">12,459,350,000원</span>
                </Typography>
              </Grid>
            </div>
          </div>
        </Box>
        <CompanySlider isM={true} />
        <div className={cs.contentThree}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography variant="subtitle2" color="textPrimary">
              <b>기업 담당자님들이 전하는 생생한 후기</b>
            </Typography>
          </Grid>
          <Grid className={cs.bigBoxArea} container direction="row" justify="center" alignItems="center">
            <ReviewSliderM />
          </Grid>
        </div>
        <div className={cs.contentfour}>
          <Grid container direction="column" justify="center" alignItems="center">
            <Typography variant="subtitle2" color="textPrimary" style={{marginRight: 100}}>
              <b>설립 이후 꾸준하게 성장하며<br></br>
              스마트 배송 서비스의 표준을<br></br>
              만들고 있습니다.</b>
            </Typography>
            <Grid className={cs.quickService} container direction="row" justify="flex-start" alignItems="flex-start">
              <Grid className="year" container direction="column" justify="flex-start" alignItems="flex-start">
                <div className="timeline" style={{ height: 68 }}>
                  <Typography className="bold"><b>2021</b></Typography>
                </div>
                <div className="timelineBefore" style={{ height: 68 }}>
                  <Typography className="bold"><b>2020</b></Typography>
                </div>
                <div className="timelineBefore" style={{ height: 68 }}>
                  <Typography ><b>2019</b></Typography>
                </div>
                <div className="timelineBefore" style={{ height: 98 }}>
                  <Typography ><b>2018</b></Typography>
                </div>
                <div className="timelineBefore" style={{ height: 120 }}>
                  <Typography ><b>2017</b></Typography>
                </div>
                <div className="timelineBefore" >
                  <Typography ><b>2016</b></Typography>
                </div>
              </Grid>
              <Grid className="timelineText" container direction="column" justify="flex-start" alignItems="flex-start">
                <Typography className="bold" style={{paddingTop: 0}}><b>웹서비스 런칭,<br></br> 비대면 접수 서비스 강화</b></Typography>
                <Typography className="title" style={{ paddingTop: 40 }}><b>이사 서비스 런칭</b></Typography>
                <Typography className="title" style={{ paddingTop: 45 }}>누적 배송 90만 건 달성</Typography>
                <Typography className="title" style={{ paddingTop: 45 }}>
                  <span><b>"퀴노스" 브랜드 런칭</b></span><br></br>
                  카카오톡 채널 런칭(메시지, 사진 접수 시작)<br></br>
                  누적매출 30억 원 달성
                </Typography>
                <Typography className="title" style={{ paddingTop: 25 }}>
                  벤처 기업 인증<br></br>
                  인터넷 프로그램 접수 서비스 런칭
                </Typography>
                <Typography className="title" style={{paddingTop: 70}}>
                  누적 배송 5만건 달성<br></br>
                  <b>법인 설립</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={cs.contentfive}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Typography variant="subtitle2" color="textPrimary" style={{paddingLeft: 35}}>
              <b>배송이 똑똑해지다! <span className="pColor">퀴노스</span></b>
            </Typography>
          </Grid>
          <Grid className={cs.miniBoxArea} container direction="column" justify="flex-start" alignItems="center">
            <Grid className={cs.box}  container direction="row" justify="start" alignItems="center" >
              <div className="miniBox">
                <Grid container direction="row" justify="start" alignItems="center">
                  <img src={IconSmart} />
                </Grid>
              </div>
              <Typography className={cs.miniBoxText}  variant="caption" style={{ paddingRight: 10 }}>
                '퀴노스만의 스마트 배송 솔루션'으로<br></br>
                고객님의 <span><b>시간과 비용을 절감해</b></span><br></br>
                <span><b>드리겠습니다!</b></span>
              </Typography>
            </Grid>
            <Grid className={cs.box} container direction="row" justify="start" alignItems="center" >
              <div className="miniBox">
                <Grid container direction="row" justify="center" alignItems="center">
                  <img src={IconIt} />
                </Grid>
              </div>
              <Typography className={cs.miniBoxText} variant="caption" style={{ paddingRight: 10 }}>
                숙련된 배송 노하우와<br></br>
                IT 시스템을 바탕으로<br></br>
                <span><b>빠르고 안전한 배송을 보장합니다.</b></span>
              </Typography>
            </Grid>
            <Grid className={cs.box} container direction="row" justify="start" alignItems="center" >
              <div className="miniBox">
                <Grid container direction="row" justify="center" alignItems="center">
                  <img src={IconOne} />
                </Grid>
              </div>
              <Typography className={cs.miniBoxText} variant="caption" style={{ paddingRight: 10 }}>
                1 건의 배송에도 <span><b>최선을 다하겠습니다.</b></span>
              </Typography>
            </Grid>
            <Grid className={cs.box} container direction="row" justify="start" alignItems="center" >
              <div className="miniBox">
                <Grid container direction="row" justify="center" alignItems="center">
                  <img src={IconMap} />
                </Grid>
              </div>
              <Typography className={cs.miniBoxText} variant="caption" style={{ fontSize: 13 }}>
                전국 단위의 퀴노스 전속 라이더와 함께<br></br>
                <span><b>업계 최고의 배송 서비스를 만들겠습니다.</b></span>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}

export default inject("cmStore")(CompanyInfoViewM);