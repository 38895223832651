import React, { useEffect } from 'react';
import { CompanySlider, CompanyTitle, CompanyContent } from './';


function CompanyInfoView(props) {

  return (
    <React.Fragment>
      <CompanyTitle />
      <CompanySlider />
      <CompanyContent />
    </React.Fragment>
  );
}

export default CompanyInfoView;