import React, { useEffect } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import { numberComma } from '../../../lib/util';
import { OPTIONS } from '../../../lib/TEXT';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    width: 200
  },
  line: {
    background: '#efefef',
    width: '100%',
    margin: '10px 0'
  }
}));
function TotalDetDialog({ qsStore }) {
  const cs = useStyles();
  const { orderResult } = qsStore;

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={cs.root}
    >
      {/* <Grid container direction="row" justify="flex-end" alignItems="center">
      </Grid> */}
      <Typography variant="body2" color="textPrimary">배송 내역</Typography>
      <Box height={10} />
      {
        (orderResult && orderResult.orderDetails.length > 0) && (
          orderResult.orderDetails.map((item, index) => (
            <Grid key={index} container direction="row" justify="space-between" alignItems="center">
              <Typography variant="caption" color="textPrimary">{OPTIONS[item.priceOption]}</Typography>
              <Typography variant="caption" color="primary">{numberComma(item.value)}원</Typography>
            </Grid>
          ))
        )
      }
      <Divider className={cs.line}/>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Typography variant="caption" color="textPrimary">총 금액</Typography>
        <Typography variant="caption" color="primary">{orderResult && numberComma(orderResult.totalValue)}원</Typography>
      </Grid>
    </Grid>
  );
}

export default inject('qsStore')(observer(TotalDetDialog));