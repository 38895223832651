import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Header from './Header';
import Footer from './Footer';
import { MainSliders, MainSlidersM, ServiceTab } from '../common';
import { Grid, useMediaQuery } from '@material-ui/core';
import HeaderM from './HeaderM';
import FooterM from './FooterM';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 110,
    backgroundImage: 'linear-gradient(to bottom, #fef7f5, #ffffff)',
  },
  containerM: {
    maxWidth: 767,
    margin: '0 auto'
  },
  serviceM: {}.type,
  [theme.breakpoints.down('md')]: {
  }
}));

function ServiceLayout({ children, loginStore, myPageStore }) {
  const cs = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { userInfo } = loginStore;
  const { myAddressSel, latestAddressSel } = myPageStore;

  useEffect(() => {
    if (!userInfo) return;
    myAddressSel();
    latestAddressSel();
  }, [userInfo])

  return (
    <React.Fragment>
      {
        matches
         ? (
           <>
              <HeaderM />
              <div className={cs.containerM}>
                <div style={{margin: '0 10px'}}>
                  <MainSlidersM />
                </div>
                <Grid container direction="column" justify="center" alignItems="center" >
                  {children}
                </Grid>
              </div>
              <FooterM />
           </>
         )
         : (
           <>
              <Header type="" />
              <div className={cs.container}>
                <MainSliders />
                <ServiceTab />
                <Grid container direction="row" justify="center" alignItems="center">
                  {children}
                </Grid>
              </div>
              <Footer />
           </>
         )
      }
      
    </React.Fragment>
  );
}

export default inject('loginStore', 'myPageStore')(observer(ServiceLayout));
