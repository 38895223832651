import React, { useState } from 'react';
import clsx from 'clsx';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Grid, IconButton, Typography } from '@material-ui/core';

import { CustomSelectbox } from '../../button';
import TooltipDialog from '../dialog/TooltipDialog';
import { optionCount } from '../../../lib/util';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50
  },
  title: {
    paddingBottom: 30,
  },
  countselbox: {
    paddingLeft: 10
  },
  countTitle: {
    width: 77,
  },
  icon: {
    width: 60,
    height: 60
  },
  tipIcon: {
    marginLeft: 5
  }
}));
function GoodsAndCountM({ goodsItem, qsStore }) {
  const cs = useStyles();
  const [dialog, setDialog] = useState(false);
  const [goodsTemp, setGoodsItem] = useState(null);

  const { handleOptions, orderCalAPI } = qsStore;
  const [tooltip, setToolTip] = useState([false, false, false, false]);

  // const handleTooltipOpen = (idx, type) => {
  //   setToolTip((old) => {
  //     tooltip[idx] = type === 'close' ? false : !tooltip[idx]
  //     return [...tooltip];
  //   });
  // }

  const handleChange = async (e) => {
    const value = e.target.value;
    const name = e.target.name;
    await handleOptions(name, value);
    await orderCalAPI();
  }

  const handleTooltipOpen = (idx, item, type) => {
    setGoodsItem(item);
    handleOnOff();
  }

  const handleOnOff = () => {
    setDialog(!dialog);
  }

  return (
    <React.Fragment>
      <TooltipDialog open={dialog} item={goodsTemp} handleClose={handleOnOff} />
      <Grid className={cs.root} container direction="column" justify="center" alignItems="center">
        <Grid className={cs.title} container direction="column" justify="center" alignItems="center">
          <Typography variant="body1" color="primary">
            <b>물품 및 수량</b>
          </Typography>
          <Typography variant="caption" color="textPrimary" align="center">
            <span className="pColor"><b>TIP.</b></span>
            물음표를 누르시면, 크기와 무게기준을 알려드려요.
          </Typography>
        </Grid>
        {
          goodsItem.map((item, index) => (
            <Grid key={item.idx} className="countArea" container direction="row" justify="space-between" alignItems="center">
              <Grid container direction="row" justify="flex-start" alignItems="center" className={cs.countTitle}>
                <Typography variant="body2"><b>{item.title}</b></Typography>
                <IconButton
                  className={clsx('questionMark', cs.tipIcon)}
                  onClick={() => handleTooltipOpen(item.idx, item, 'open')}
                />
              </Grid>
              <Grid item xs={7} className={cs.countselbox}>
                <FormControl fullWidth={true}>
                  <CustomSelectbox
                    name={item.name}
                    variant="filled"
                    onChange={handleChange}
                    inputProps={{ 'title': '수량 선택' }}
                  >
                    {
                      optionCount(100).map((item) => (
                        <option key={item}>{item}</option>
                      ))
                    }
                  </CustomSelectbox>
                </FormControl>
              </Grid>
              <Grid item>
                <img className={cs.icon} alt="" src={item.icon} />
              </Grid>
            </Grid>
          ))
        }
      </Grid>
    </React.Fragment>
  );
}

export default inject('qsStore')(GoodsAndCountM);