import { client } from '../lib/client';

// 퀵 배송
export const quickTempAPI = (data) => (
  client({
    url: '/v1/quick/temp',
    method: 'post',
    data
  }, true)
);

// 화물 배송
export const freightTempAPI = (data) => (
  client({
    url: '/v1/freight/temp',
    method: 'post',
    data
  }, true)
);

export const moveTempAPI = (data) => (
  client({
    url: '/v1/move/temp',
    method: 'post',
    data
  }, true)
);

export const orderCalAPI = (data) => (
  client({
    url: '/v1/order/cal',
    method: 'post',
    data
  }, true)
);

// 배너 조회
export const bannerSelAPI = () => (
  client({
    url: '/v1/banner/list',
  })
);
