import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, } from '@material-ui/core';
import DialogTitle from './DialogTitle';

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 20,
  }
}));
function DaumPostcodeDialog({ name, open, type, handleClose, handleChange, cmStore }) {
  const cs = useStyles();
  const { setDaumAddress } = cmStore;

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let bcode = data.bcode; // 법정동코드
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }

    // 공통 store 안쓰고 직접 주소를 받을 때
    if (name && handleChange) {
      handleChange({ target: { name, value: fullAddress}});
    } else {
      setDaumAddress(type, fullAddress, bcode);
    }

    handleClose();
  }

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth="md" aria-labelledby="dialogTitle" open={open} onClose={handleClose}>
        <DialogTitle onClose={handleClose}></DialogTitle>
        <DialogContent style={{padding: '20px 0'}}>
          <DaumPostcode
            onComplete={handleComplete}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default inject('cmStore')(DaumPostcodeDialog);