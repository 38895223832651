import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { CustomVacantButton } from '../button';
import { numberComma } from '../../lib/util';
import { OPTIONS } from '../../lib/TEXT';

const useStyles = makeStyles((theme) => ({
  tableArea: {
    position: 'relative',
    marginBottom: 16,
    paddingTop: 10,
    paddingBottom: 20,
    minHeight: 288,
    borderRadius: 10,
    boxShadow: '0 5px 15px 0 rgba(247, 124, 64, 0.2)',
    backgroundColor: '#ffffff',
    // '&:after': {
    //   display: 'block',
    //   position: 'absolute',
    //   top: -1,
    //   bottom: -1,
    //   left: '50%',
    //   width: 16,
    //   marginLeft: -8,
    //   backgroundColor: '#fff',
    //   content: '""',
    // },
  },
  table: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  tableRow: {
    display: 'table-row',
  },
  tableCell: {
    display: 'table-cell',
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 20,
    // paddingRight: 20,
  },
  tableCaption: {
    paddingBottom: 11,
    color: '#000',
  },
  gap: {
    width: 16,
    padding: 0,
    '&$tableCell': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  th: {
    fontWeight: 700,
  },
  alignLeft: {
    textAlign: 'left',
    paddingRight: 10
  },
  title: {
    width: '28%'
  },
  btnBox: {
    padding: '0 30px',
    '& button': { height: 40 }
  },
}));

function UseCard({ item }) {
  const cs = useStyles();
  const [open, setOpen] = useState(false);

  
  const orderType = {
    Move: '이사',
    Freight: '화물',
    Quick: '퀵 서비스'
  };

  const handleOnOff = () => {
    setOpen(!open);
  };

  const testData = [
    { title: '서비스 구분', value: orderType[item.orderType]},
    { title: '요청시간', value: item.createdDate ? moment(item.createdDate).format('YYYY-MM-DD HH:mm') : "-" },
    {
      title: item.orderType === 'Move' ? '이사날짜' : '완료시간',
      value: item.orderType === 'Move'
        ? item.reservationTime ? moment(item.reservationTime).format('YYYY-MM-DD HH:mm') : "-"
        : item.completeDate ? moment(item.completeDate).format('YYYY-MM-DD HH:mm') : "-"
    },
    { title: '출발지', value: item.startAddr },
    { title: '도착지', value: item.endAddr },
    { title: '배송차량', value: OPTIONS[item.vehicleType] },
    // { title: '배송경로', value: "??" },
    { title: '배송물품', value: item.stuff === "Move" ? '이사' : item.stuff},
    { title: '결제방법/금액', value: `${numberComma(item.totalValue)}원` },
    { title: '기타', value: item.requests ? item.requests : '-' },
  ]

  return (
    <div className={cs.tableArea}>
      <div className={cs.table}>
        {
          testData.map((item, index) => {
            if (!open && index > 4 ) {
              return <React.Fragment key={index}></React.Fragment>
            }
            return (
              <div key={index} className={cs.tableRow}>
                <div className={clsx(cs.tableCell, cs.title)}>
                  <Typography variant="body2" color="primary">{item.title}</Typography>
                </div>
                <div className={clsx(cs.tableCell, cs.alignLeft)}>
                  {item.value}
                </div>
              </div>
            )
          })
        }
      </div>
      <Box height={24} />
      <div className={cs.btnBox}>
        <CustomVacantButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleOnOff}
        >자세히 보기
        </CustomVacantButton>
      </div>
    </div>
  );
}

export default UseCard;