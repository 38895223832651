import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tipArea: {
    paddingTop: 10,
    '& .tipSubText': {
      fontWeight: 500
    }
  },
  tipDivider: {
    width: 1,
    height: 16,
    backgroundColor: '#ff925c',
    marginTop: 6,
    marginRight: 6,
    float: 'right'
  },
  table: {
    paddingTop: 20,
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  tableRow: {
    display: 'table-row',
  },
  tableCell: {
    display: 'table-cell',
    paddingBottom: 7,
    paddingLeft: 5,
    // paddingRight: 20,
  },
  title: {
    width: 50,
  }
}));
function TooltipBox({ title, context, total }) {
  const cs = useStyles();
//<div className={classes.tipDivider} />
  return (
    <React.Fragment>
      <div className={clsx(cs.table)}>
        <div className={cs.tableRow}>
          <div className={clsx(cs.tableCell, cs.title)}>
            <Typography variant="body2" color="textPrimary">
              <b>정의</b>
              <span className={cs.tipDivider} />
            </Typography>
          </div>
          <div className={clsx(cs.tableCell, cs.alignLeft)}>
            <Typography className="tipSubText" variant="body2" color="textPrimary">
              <span dangerouslySetInnerHTML={{ __html: context }} />
            </Typography>
          </div>
        </div>
        <div className={cs.tableRow}>
          <div className={clsx(cs.tableCell, cs.title)}>
            <Typography variant="body2" color="textPrimary">
              <b>비고</b>
              <span className={cs.tipDivider} />
            </Typography>
          </div>
          <div className={clsx(cs.tableCell, cs.alignLeft)}>
            <Typography className="tipSubText" variant="body2" color="textPrimary">
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TooltipBox;