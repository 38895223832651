import { makeAutoObservable, action, observable } from 'mobx';

export default class CommonStore {
  root;
  qsStore;
  headerTitle = '';
  toAddress = '';
  toBcode = '';
  fromAddress = '';
  fromBcode = '';
  etcAddress = {
    startAddrDtl: '',
    startTel: '',
    startNm: '',
    endNm: '',
    endAddrDtl: '',
    endTel: '',
    addressName: '',
  }


  constructor(root) {
    this.root = root;
    this.qsStore = this.root.qsStore;
    makeAutoObservable(
      this, {
        headerTitle: observable,
        toAddress: observable,
        fromAddress: observable,
        etcAddress: observable,
        setMobileTitle: action,
        setDaumAddress: action,
        setEtcAddress: action,
        initialAddress: action,
      }
    );
  }

  initialAddress = () => {
    this.toAddress = '';
    this.toBcode = '';
    this.fromAddress = '';
    this.fromBcode = '';

    // this.etcAddress = {
    //   startAddrDtl: '',
    //   startTel: '',
    //   startNm: '',
    //   endNm: '',
    //   endAddrDtl: '',
    //   endTel: '',
    // }
  }

  // mobile header title
  setMobileTitle = (title) => {
    this.headerTitle = title;
  };

  // 주소 검색
  setDaumAddress = (type, address, bcode) => {
    if (type === 'to') {
      this.toAddress = address;
      this.toBcode = bcode;
    } else if (type === 'from') {
      this.fromAddress = address;
      this.fromBcode = bcode;
    }
  }

  // 기타 주소 내용 각각 입력
  setEtcAddress = (name, value) => {
    const temp = this.etcAddress;

    if (name === 'each') {
      for (const [k, v] of Object.entries(value)) {
        if (k === "startAddr") this.toAddress = v;
        else if (k === "endAddr") this.fromAddress = v;
        else if (k === "startPointCode") this.toBcode = v;
        else if (k === "endPointCode") this.fromBcode = v;
        temp[k] = v ? v : "";
      }
    } else {
      if (temp.hasOwnProperty(name)) temp[name] = value ? value : "";
    }
    this.etcAddress = { ...temp };
  }
}