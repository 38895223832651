import Commons from './commons';
import Login from './login';
import QuickService from './quickService';
import MyPage from './mypage';
class RootStore {
  cmStore;
  loginStore;
  qsStore;
  myPageStore;

  constructor() {
    this.cmStore = new Commons(this);
    this.loginStore = new Login(this);
    this.qsStore = new QuickService(this);
    this.myPageStore = new MyPage(this);
  }
}

export default RootStore;