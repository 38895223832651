import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { Route, Switch, Redirect } from 'react-router-dom';

import theme from './static/theme';
import routes from './lib/router';
import './static/css/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";

const MyRouter = ({ layout: Layout, component: Component, strict = false, ...rest }) => {
  return (
    <Route
      strict
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

function App({ loginStore }) {
  const { meCall } = loginStore;
  useEffect(() => {
    window.Kakao.init('15a47e163ff40cf9ac569dc7a94c7b79');
    window.Kakao.isInitialized();
    window.IMP.init("imp13090698");

    meCall();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Switch>
        {
          routes.map((r, idx) => {
            return (
              <MyRouter
                exact={r.exact}
                path={r.path}
                layout={r.layout}
                component={r.component}
                strict={r.strict}
                key={idx}
              />
            )
          })
        }
        <Redirect path="/" to="/quickservice" />
      </Switch>
    </ThemeProvider>
  );
}

export default inject('loginStore')(App);
