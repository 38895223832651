import React from 'react';
import moment from 'moment';
import { FormControl, Grid, Typography } from '@material-ui/core';
import { CustomSelectbox } from '../../button';
import { dayString, timeOptions } from '../../../lib/util';
import Calendar from '../Calendar';

function SelectTime({ name, handleChange }) {
  return (
    <Grid item style={{ marginLeft: 0, marginBottom: 30 }}>
      <Grid className="" container direction="row" justify="flex-start" alignItems="center">
        <Grid item style={{ marginRight: 20 }}>
          <Typography variant="subtitle2"><b></b></Typography>
        </Grid>
        <Grid item>
          <Calendar
            name="moveDate"
            id={name}
            handleChange={handleChange}
          />
          {/* <Typography variant="body2" color="primary">
            {`${moment(new Date()).format('YYYY-MM-DD')}(${dayString()})`}
          </Typography> */}
        </Grid>
        <Grid item style={{ margin: '0 16px' }}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <FormControl className="time" fullWidth={false}>
              <CustomSelectbox
                variant="filled"
                name="hour"
                id={name}
                onChange={handleChange}
                inputProps={{ 'title': '시간 선택' }}
              >
                <option>00</option>
                {
                  timeOptions('time').map((item) => (
                    <option key={item.value} value={item.value}>{item.title}</option>
                  ))
                }
              </CustomSelectbox>
            </FormControl>
            <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500 }}>시</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <FormControl className="time" fullWidth={false}>
              <CustomSelectbox
                variant="filled"
                name="minute"
                id={name}
                onChange={handleChange}
                inputProps={{ 'title': '분 선택' }}
              >
                <option value="00">00</option>
                {
                  timeOptions('').map((item) => (
                    <option key={item.value} value={item.value}>{item.title}</option>
                  ))
                }
              </CustomSelectbox>
            </FormControl>
            <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500 }}>분</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SelectTime;