import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { IconMinus, IconPlus } from '../../static/images/icon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .iconBtn': {
      width: 40,
      height: 40,
      padding: 7,
      borderRadius: 60,
      border: 'solid 1px #ff925c',
      marginLeft: 20,
      marginRight: 16
    },
    '& .minus': {
      background: `url(${IconMinus}) no-repeat center`,
    },
    '& .plus': {
      background: `url(${IconPlus}) no-repeat center`,
    },
    '& .countbox': {
      width: 50,
      height: 40,
      backgroundColor: '#f6f7f9',
      textAlign: 'center',
      borderRadius: 6,
      '& p': {
        color: '#a4a4ac',
        padding: 5
      }
    }
  },
  tooltipText: {
    color: '#555558',
    fontSize: 16,
    textAlign: 'center',
    maxWidth: 'none !important;',
    // height: 30,
    padding: 15
  }
}));
function CountUpDown({ title, name, handleChange, tip }) {
  const classes = useStyles();
  const [count, setCount] = useState(0);

  const handleClick = (type) => {
    let c = count;
    if (type === 'm' && c > 0) c--;
    else if (type === 'p') {
      if (name === 'passengerCnt' && count >= 1) return;
      c++;
    }

    
    setCount(c);

    handleChange({target: { name, value: c}});
  }
  return (
    <Grid className={classes.root} container direction="row" justify="flex-start" alignItems="center">
      <Typography className="countUpDownTitle" variant="body2" color="textPrimary" style={{fontWeight: 500}}>
        {title}
      </Typography>
      <IconButton className="iconBtn minus" onClick={() => handleClick('m')} />
      <div className="countbox">
        <Typography variant="body2">{count}</Typography>
      </div>
      {
        !tip
          ? (
            <IconButton className="iconBtn plus" onClick={() => handleClick('p')} />
          )
          : (
            <Tooltip classes={{ tooltip: classes.tooltipText }} title={tip} placement="top-end">
              <IconButton className="iconBtn plus" onClick={() => handleClick('p')} />
            </Tooltip>
          )
      }      
    </Grid>
  );
}

export default CountUpDown;