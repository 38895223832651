import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { CompanyInfoView, CompanyInfoViewM } from '../components/CompanyPage';


function CompanyPage(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <React.Fragment>
      {
        matches ? <CompanyInfoViewM /> : <CompanyInfoView />
      }
    </React.Fragment>
  );
}

export default CompanyPage;