import React, { useEffect } from 'react';
import clsx from 'clsx';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, FormControlLabel, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';

import CustomCheckbox from '../components/button/CustomCheckbox';
import GuideMessage from '../components/message/GuideMessage';
import { BtnLoginKakao, BtnLoginGoogle } from '../static/images';
import { CustomButton, CustomVacantButton } from '../components/button';
import { useTheme } from '@material-ui/styles';

function LoginPage({ history, loginStore }) {
  const cs = useStyles();
  const saveId = localStorage.getItem('qadmid') ? localStorage.getItem('qadmid') : '';
  const { kakaologin, googleLoginInit, naverLogin, emailLogin } = loginStore;

  const params = {
    email: saveId,
    password: '',
    saveId: Boolean(saveId)
  };

  useEffect(() => {
    googleLoginInit();
    naverLogin();
  }, []);

  const handleChange = (e, checked) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'saveId') params[name] = checked;
    else params[name] = value;
  };

  const handlePage = (path) => {
    history.push(path);
  };

  const onSubmit = () => {
    emailLogin(params, history);
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" wrap="nowrap" className={cs.loginWrap}>
        <Grid item className={cs.loginForm}>
          <Grid container direction="column" justify="center" className="narrowFormArea">
            <Grid item>
              <Grid container direction="column" justify="center" alignItems="center">
                <GuideMessage 
                  title="로그인이 필요하신가요?"
                  mTitle="로그인"
                  resultTitle="로그인이 필요하신가요?"
                  subTitle="로그인하시면 서비스 이용이 더 편해집니다."
                />
              </Grid>
            </Grid>
            <Grid item className={cs.inputForm}>
              <Box className={clsx(cs.formUserInfoWrap, "mainFormGroup")}>
                <TextField
                  name="email"
                  placeholder="아이디(e-mail)"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  className={clsx("formArea")}
                />
                <TextField
                  name="password"
                  type="password"
                  placeholder="비밀번호"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  className={clsx("formArea")}
                />
              </Box>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={cs.idSaveForm}
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <CustomCheckbox 
                        name="saveId"
                        onChange={handleChange}
                        defaultChecked={Boolean(saveId)}
                      />
                    }
                    label={
                      <Typography className={cs.idSaveText}>
                        아이디 저장
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item>
                  <Button className={cs.notHover} onClick={() => handlePage('/idInquiry')}>
                    <Typography className="txtDesc">아이디 찾기</Typography>
                  </Button>
                  <span className="divider" />
                  <Button className={cs.notHover} onClick={() => handlePage('/pwInquiry')}>
                    <Typography className="txtDesc in">비밀번호 찾기</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <CustomButton
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={onSubmit}
                >로그인
                </CustomButton>
                <CustomVacantButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={cs.signupBtn}
                  onClick={() => handlePage("/signup")}
                >초간단 회원가입
                </CustomVacantButton>
              </Grid>
              <Grid container direction="row" justify="space-around" alignItems="center">
                <Grid item>
                  <Grid container direction="column" justify="center" alignItems="center">
                    <div id="naverIdLogin" className={cs.naverBtn} />
                    <Typography variant="body1" align="center">{!matches ? "네이버로 로그인" : <span>네이버로<br></br>로그인</span>}</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" justify="center" alignItems="center">
                    <img className={cs.snsBtn} src={BtnLoginKakao} onClick={kakaologin}/>
                    <Typography variant="body1" align="center">{!matches ? "카카오로 로그인" : <span>카카오로<br></br>로그인</span>}</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" justify="center" alignItems="center">
                    <img className={cs.snsBtn} id="googleBtn" data-onsuccess="onSignIn" src={BtnLoginGoogle} />
                    <Typography variant="body1" align="center">{!matches ? "구글로 로그인" : <span>구글로<br></br>로그인</span>}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  loginWrap: {
    paddingTop: 100,
    paddingBottom: 180,
  },
  loginForm: {
    width: 420,
    marginLeft: 20,
    marginRight: 20,
  },
  formUserInfoWrap: {
    position: 'relative',
  },
  inputForm: {
    marginTop: 60,
    marginBottom: 20
  },
  idSaveForm: {
    paddingBottom: 30,
    '& .txtDesc': {
      fontSize: 18,
      color: '#a4a4ac'
    },
    '& .divider': {
      width: 2,
      height: 16,
      margin: 0,
      backgroundColor: '#ff925c'
    }
  },
  idSaveText: {
    fontSize: 18,
    color: '#555558',
  },
  signupBtn: {
    marginTop: 20,
    marginBottom: 40,
  },
  kakaoBtn: {
    margin: '20px 0'
  },
  naverBtn: {
    '& a': {
      '& img': {
        borderRadius: 30
      }
    }
  },
  snsBtn: {
    width: 60,
    height: 60,
    cursor: 'pointer',
    marginBottom: 5
  },
  notHover: {
    '&:hover': {
      background: 'transparent'
    }
  }
}));

export default inject('loginStore')(LoginPage);