import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent,Grid, Typography } from '@material-ui/core';
import DialogTitle from './DialogTitle';

const useStyles = makeStyles((theme) => ({
  alertPaper: {
    width: 420,
    padding: '10px',
    // border: `1px solid ${theme.palette.tertiary.main}`,
    borderRadius: 30,
    boxShadow: '0 0 7px rgba(0, 0, 0, 0.15)',
    '& .MuiDialogContent-root': {
      padding: '8px 14px'
    }
  },
  tipDivider: {
    width: 1,
    height: 16,
    backgroundColor: '#ff925c',
    margin: '7px 6px 0 6px;',
  }
}));

function TooltipDialog({ open, item, handleClose }) {
  const cs = useStyles();
  if (!item) return null;
  return (
    <Dialog open={open} classes={{ paper: cs.alertPaper }} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        <Typography variant="body1" color="textPrimary" align="center">
          <b>{item.title}</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid className={cs.tipArea} container direction="column" justify="center" alignItems="lex-start">
          <Grid item>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <Typography variant="caption" color="textPrimary"><b>정의</b></Typography>
              <div className={cs.tipDivider} />
              <Typography className="tipSubText" variant="caption" color="textPrimary">
                <span dangerouslySetInnerHTML={{ __html: item.tipContext }} />
            </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <Typography variant="caption" color="textPrimary"><b>비고</b></Typography>
              <div className={cs.tipDivider} />
              <Typography className="tipSubText" variant="caption" color="textPrimary">
                <span dangerouslySetInnerHTML={{ __html: item.tipTitle }} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default TooltipDialog;