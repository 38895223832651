import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import { PasswordForm, OpinionForm, LeaveResult } from '../components/LeaveIdPage';

function LeaveIdPage(props) {
  const [page, setPage] = useState("pw");

  const handleCheck = (pageType) => {
    setPage(pageType);
  }

  return (
    <React.Fragment>
      <Grid className="mainLayout" container direction="column" justify="center" alignItems="center">
        {page === 'pw' && <PasswordForm handleCheck={handleCheck}/> }
        {page === 'checked' && <OpinionForm handleCheck={handleCheck}/> }
        {page === 'result' && <LeaveResult /> }
        
      </Grid>
    </React.Fragment>
  );
}

export default LeaveIdPage;