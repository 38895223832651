import React, { useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Box, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ServiceTitle } from '../common/eachService';
import CustomSelectGroup from '../common/CustomSelectGroup';
import { CustomButtonGroup, CustomSelectbox } from '../button';
import { dayString, timeOptions } from '../../lib/util';
import { Calendar } from '../common';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '60px 0 60px 80px',
    '& .csgSelectbox': {
      width: 421
    },
    '& .selectbox': {
      marginTop: 50,
      marginBottom: 30,
    },
    '& .time': {
      width: 90
    },
    '& .mainFormGroup': {
      width: 1140,
      '& .MuiInputBase-root': {
        boxShadow: 'none',
      },
      '& input': {
        border: 'solid 1px #e4e4e9',
        backgroundColor: '#ffffff',
      }
    }
  },
  textfieldMul: {
    '& textarea': {
      height: '50px !important;',
    }
  }
}));
function DeliveryOption({ qsStore }) {
  const classes = useStyles();
  const { handleOptions, orderCalAPI, options, smallBoxTotalCnt } = qsStore;
  const params = {};

  useEffect(() => {
    handleOptions('vehicleType', 'Bike');
  }, [])

  useEffect(() => {

    console.log({ smallBoxTotalCnt });
    if (smallBoxTotalCnt > 6 || options.documentCnt >= 10) {
      handleOptions('vehicleType', 'Damas');
    }
    
    if (smallBoxTotalCnt >= 30 || options.documentCnt >= 70) {
      handleOptions('vehicleType', 'Labo');
    }
    
  }, [smallBoxTotalCnt, options.documentCnt])
  
  const vehicleType = [
    { title: '오토바이', value: 'Bike', disabled: (smallBoxTotalCnt > 6 || options.documentCnt >= 10) },
    { title: '다마스', value: 'Damas', disabled: (smallBoxTotalCnt >= 30 || options.documentCnt >= 70) },
    { title: '라보', value: 'Labo' },
  ];

  const pathItem = [
    { title: '편도', value: 'OneWay'},
    { title: '왕복', value: 'RoundTrip'},
    { title: '경유', value: 'LayOver', tip: '상담 후 결제가 이뤄집니다.'},
  ];

  const etcItems = [
    { title: '선택없음', value: null },
    { title: '급송', value: 'Y' },
    { title: '예약', value: 'N', tip: '<span class="pColor">운영시간</span> 월~금: 08:00 ~ 21:00 / 토,일,공휴일 : 09:00 ~ 18:00' },
  ];

  const handleChange = async (e) => {
    let name = e.target.name;
    let value = e.target.value;
    params[name] = value;

    if (name === 'minute' || name === 'hour' || name === 'moveDate') {
      let time = moment(options.reservationTime ? options.reservationTime  : new Date());
      if (name === 'moveDate') time = moment(value);

      if (params.minute) time.minute(parseInt(params.minute));
      else time.minute(0);

      if (params.hour) time.hour(parseInt(params.hour));
      else time.hour(0);      
      
      name = "reservationTime";
      value = new Date(time.format('YYYY-MM-DD HH:mm')).getTime();
    } else if (name === 'isExpress') {
      if (value === 'Y') handleOptions("reservationTime", "");
    }
    
    await handleOptions(name, value);
    await orderCalAPI();
  }

  return (
    <Grid className={clsx('serviceBox', classes.root)} container direction="column" justify="flex-start" alignItems="flex-start">
      <Grid className="left" container direction="column" justify="flex-start" alignItems="flex-start">
        <ServiceTitle
          title="배송 옵션"
          subTitle="선택하신 배송 물품정보를 바탕으로 배송수단을 추천해드립니다."
        />
      </Grid>
      <Grid className="selectbox" item>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item>
            <CustomSelectGroup 
              title="배송수단" 
              name="vehicleType"
              handleChange={handleChange}
              options={vehicleType}
              value={options.vehicleType}
              type="quick"
              tip="tip내용 추가해야됨" 
            />
          </Grid>
          <Grid item style={{marginLeft: 100}}>
            <Grid className="" container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 43 }} variant="subtitle2"><b>기타</b></Typography>
              <CustomButtonGroup 
                name="isExpress"
                items={etcItems}
                defaultValue={""}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{marginBottom: 30}}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item>
            <Grid className="" container direction="row" justify="flex-start" alignItems="center">
              <Typography style={{ paddingRight: 43 }} variant="subtitle2"><b>배송경로</b></Typography>
              <CustomButtonGroup
                name="route"
                items={pathItem} 
                defaultValue={options.route}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          {
            options.isExpress === 'N' && (
              <Grid item style={{ marginLeft: 100 }}>
                <Grid className="" container direction="row" justify="flex-start" alignItems="center">
                  <Grid item style={{marginRight: 20}}>
                    <Typography variant="subtitle2"><b>예약일정</b></Typography>
                  </Grid>
                  <Grid item>
                    <Calendar
                      name="moveDate"
                      handleChange={handleChange}
                    />
                    {/* <Typography variant="body2" color="primary">
                      {`${moment(new Date()).format('YYYY-MM-DD')}(${dayString()})`}
                    </Typography> */}
                  </Grid>
                  <Grid item style={{ margin: '0 16px' }}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                      <FormControl className="time" fullWidth={false}>
                        <CustomSelectbox 
                          variant="filled" 
                          name="hour"
                          onChange={handleChange}
                          inputProps={{ 'title': '시간 선택' }}
                        >
                          <option>00</option>
                          {
                            timeOptions('time').map((item) => (
                              <option key={item.value} value={item.value}>{item.title}</option>
                            ))
                          }
                        </CustomSelectbox>
                      </FormControl>
                      <Typography variant="body2" color="textPrimary" style={{ fontWeight: 500 }}>시</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                      <FormControl className="time" fullWidth={false}>
                        <CustomSelectbox 
                          variant="filled" 
                          name="minute"
                          onChange={handleChange}
                          inputProps={{ 'title': '분 선택' }}
                        >
                          <option value="00">00</option>
                          {
                            timeOptions('').map((item) => (
                              <option key={item.value} value={item.value}>{item.title}</option>
                            ))
                          }
                        </CustomSelectbox>
                      </FormControl>
                      <Typography variant="body2" color="textPrimary" style={{fontWeight: 500}}>분</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Typography style={{ paddingRight: 48 }} variant="subtitle2"><b>요청사항</b></Typography>
          <Box className="mainFormGroup">
            <TextField
              fullWidth
              style={{height: 80}}
              multiline
              variant="outlined"
              placeholder=""
              name="requests"
              onChange={handleChange}
              className={clsx("formArea textBox", classes.textfieldMul)}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default inject('qsStore')(observer(DeliveryOption));