import React from 'react';
import CountUp from 'react-countup';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid, Typography } from '@material-ui/core';

import { ImgCompanyMain } from '../../static/images';

const useStyles = makeStyles((theme) => ({
  main: {
    height: 1055,
    backgroundColor: '#ff925c'
  },
  titleArea: {
    height: 700,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${ImgCompanyMain})`,
    '& .area': {
      paddingTop: 230,
      paddingBottom: 200
    },
    '& .title': {
      fontSize: 60,
      fontWeight: 'bold',
      lineHeight: 1.33,
      letterSpacing: -3,
      textAlign: 'center',
      color: '#ffffff',
    },
    '& .subTitle': {
      paddingTop: 20,
      fontSize: 20,
      lineHeight: 1.6,
      letterSpacing: -1,
      textAlign: 'left',
      color: '#ffffff',
    }
  },
  subTitle2: {
    fontSize: 38,
    fontWeight: 'bold',
    lineHeight: 1.37,
    letterSpacing: -1.9,
    textAlign: 'left',
    color: '#ffffff',
  },
  subTitleArea: {
    height: 328,
    maxWidth: 1190,
    minWidth: 1180,
    padding: 0,
  },
  textCount: {
    marginTop: 80,
    '& .title': {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.07,
      letterSpacing: -1.4,
      textAlign: 'left',
      color: '#ffe7d4',
      display: 'inline-block',
      position: 'relative',
      zIndex: 2,
      '&:after': {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 16,
        borderRadius: 3,
        background: '#F44424',
        zIndex: -1,
        content: '""',
      }
    },
    '& .count': {
      fontSize: 60,
      fontWeight: 'bold',
      lineHeight: 1.33,
      letterSpacing: -3,
      textAlign: 'left',
      color: '#ffffff',
    }
  },
}));

function CompanyTitle(props) {
  const cs = useStyles();

  return (
    <Box className={cs.main}>
      <div className={cs.titleArea}>
        <Grid className="area" container direction="column" justify="center" alignItems="center">
          <Typography className="title">
            우리는<br></br>
            빠르고 안전합니다.
            </Typography>
          <Typography className="subTitle">
            스마트 배송 퀴노스와 함께하세요.
            </Typography>
        </Grid>
      </div>
      <Container className={cs.subTitleArea} style={{ paddingLeft: 24 }} maxWidth="lg">
        <Grid container direction="column" justify="center" alignItems="flex-start">
          <Typography className={cs.subTitle2}>
            퀴노스,
            </Typography>
          <Typography className={cs.subTitle2}>
            기업 회원님들과 함께 성장하고 있습니다.
            </Typography>
          <div className={cs.textCount}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Typography>
                <span className="title">기업 회원 수</span>
                <br></br>
                <span className="count">
                  <CountUp
                    duration={2.75}
                    start={0}
                    separator=","
                    end={35713} />명
                </span>
              </Typography>
              <Typography style={{ padding: '0 80px' }}>
                <span className="title">누적 배송 건</span>
                <br></br>
                <span className="count">
                  <CountUp
                    start={0}
                    duration={2.75}
                    separator=","
                    end={832662} />건
                </span>
              </Typography>
              <Typography>
                <span className="title">누적 거래액</span>
                <br></br>
                <span className="count">
                  <CountUp
                    start={0}
                    duration={2.75}
                    separator=","
                    end={12459350000} />원
                </span>
              </Typography>
            </Grid>
          </div>
        </Grid>
      </Container>
    </Box>
  );
}

export default CompanyTitle;