import React, { useEffect } from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { withRouter, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { AppBar, Drawer, IconButton, Slide, Toolbar, Typography, useMediaQuery, useScrollTrigger } from '@material-ui/core';

import LoginMenu from '../common/mobile/LoginMenu';
import { Logo, BtnBack } from '../../static/images';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  appbar: {
    height: 50,
    // borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
    backgroundColor: 'transparent',
    '&.white': {
      // borderBottom: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: '#fff',
    },
    '&:not(.white)': {
      '&.on': {
        // background: theme.palette.backgroundGradient[100],
      },
    },
    '& .pageHead': {
      flex: '1 1 auto',
      textAlign: 'center',
      paddingTop: 10,
      // paddingLeft: 40,
    },
  },
  mainAppbar: {
    '& .pageHead': {
      color: '#ffff'
    },
    '& .icoMenu': {
    }
  },
  toolbar: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 50,
    padding: '10px 0',
    border: 'none',
  },
  btnMenuLabel: {
    display: 'block',
    opacity: 0.8,
    '&:before': {
      display: 'block',
      width: 21,
      height: 2,
      marginBottom: 6,
      borderRadius: 1,
      backgroundColor: '#000',
      content: '""',
    },
    '&:after': {
      display: 'block',
      width: 21,
      height: 2,
      marginTop: 6,
      borderRadius: 1,
      backgroundColor: '#000',
      content: '""',
    },
    '& .icoMenu': {
      display: 'block',
      width: 16,
      height: 2,
      borderRadius: 1,
      backgroundColor: '#000',
    },
  },
  drawer: {
    width: '100%',
  },
  sideWrap: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  btnCloseMenu: {
    position: 'absolute',
    right: 2,
    top: 0,
  },
  icoCloseMenu: {
    color: '#000000',
    fontSize: 30,
  },
  company: {
    '& span': {
      '& span': {
        backgroundColor: '#ffffff !important;'
      },
      '&::after': {
        backgroundColor: '#ffffff'
      },
      '&::before': {
        backgroundColor: '#ffffff'
      }
    }
  }
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    threshold: 100,
    target: window ? window() : undefined
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function HeaderM({ cmStore, history, type }, props) {
  const cs = useStyles();
  const location = useLocation();
  const { headerTitle } = cmStore;
  const [state, setScrollState] = React.useState(false);
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const PATH = [
    "/detailsofusage", "/shippinglist", "/addressreg", "/policy", "/myaddress"
  ]

  const scrollEvent = () => {
    setScrollState(window.pageYOffset > 0 ? true : false);
  }

  useEffect(() => {
    // console.log({ location });
    window.addEventListener('scroll', scrollEvent);
    return () => {
      window.removeEventListener("scroll", scrollEvent);
    }
  }, [])

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
    // console.log('open');
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
    // console.log('close');
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handlePage = (path) => {
    if (path) history.push(path);
    else history.goBack();

  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar elevation={0} className={clsx(cs.appbar, cs[type])}>
          <Toolbar className={cs.toolbar}>
            {
              PATH.indexOf(location.pathname) > -1
                ? <img onClick={() => handlePage()} src={BtnBack} alt="" style={{ width: 50, paddingTop: 10 }} />
                : <img onClick={() => handlePage("/")} src={Logo} alt="" style={{ width: 60, marginLeft: 10 }} />
            }
            <Typography variant="body1" className="pageHead"><b>{headerTitle}</b></Typography>
            <IconButton
            color="primary" aria-label="메뉴" classes={{ label: cs.btnMenuLabel }}
              className={clsx({ [cs.btnMenu]: true, [cs.company]: location.pathname === '/company'})} onClick={handleSidebarOpen}>
              <span
                className={clsx({
                  'icoMenu': true,
                })}
              ></span>
            </IconButton>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Drawer anchor="right" classes={{ paper: cs.drawer }} open={openSidebar} onClose={handleSidebarClose}>
        <div className={cs.sideWrap}>
          {/* <NotLoginMenu handlePage={handlePage}/> */}
          <LoginMenu handlePage={handlePage}/>
          <IconButton className={cs.btnCloseMenu} onClick={handleSidebarClose}>
            <Typography variant="srOnly">메뉴 닫기</Typography>
            <CloseRoundedIcon className={clsx(cs.icoCloseMenu)} />
          </IconButton>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default inject('cmStore')(withRouter(observer(HeaderM)));
