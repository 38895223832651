import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { Grid, Icon, Typography, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import GuideMessage from '../../components/message/GuideMessage';
import { CustomButton, CustomVacantButton } from '../../components/button';
import { IconCheckLW } from '../../static/images/icon';
import { BtnLoginKakao, BtnLoginGoogle } from '../../static/images';
import { useTheme } from '@material-ui/styles';

function IDInquiryForm({ handleIdentifyBtn, loginStore }) {
  const history = useHistory();
  const classes = useStyles();
  const { phoneAuth, phoneInitial, phoneCheck, findIdCall, kakaologin, googleLoginInit, naverLogin, emailLogin } = loginStore;

  useEffect(() => {
    phoneInitial();
    googleLoginInit();
    naverLogin();
  }, []);

  useEffect(() => {
    if (phoneCheck) {
      findIdCall(() => { handleIdentifyBtn() });
    }
  }, [phoneCheck]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" wrap="nowrap" className={classes.idInquiryWrap}>
        <Grid item className="loginFormArea">
          <Grid container direction="column" justify="center" className="narrowFormArea">
            <Grid item>
              <Grid container direction="column" justify="center" alignItems="center">
                <GuideMessage
                  title="아이디를 잊어버리셨나요?"
                  mTitle="아이디찾기"
                  resultTitle="아이디를 잊어버리셨나요?"
                  subTitle="간단한 정보 확인 후 안내를 도와드릴게요."
                />
              </Grid>
            </Grid>
            <Grid item className={classes.inputForm}>
              <CustomButton
                fullWidth 
                variant="contained" 
                color="primary"
                onClick={phoneAuth}
                endIcon={
                  phoneCheck && (
                    <Icon>
                      <img alt="" src={IconCheckLW}/>
                    </Icon>
                  )
                }
                >가입한 핸드폰번호 인증하기
              </CustomButton>
              {/* <Box className="mainFormGroup">
                <TextField
                  id="phone"
                  placeholder="가입 시 인증받은 핸드폰번호"
                  variant="outlined"
                  fullWidth
                  className="formArea"
                />
                <TextField
                  id="phone"
                  placeholder="인증번호 입력"
                  variant="outlined"
                  fullWidth
                  className="formArea"
                  InputProps={{
                    endAdornment: (
                      <Button variant="contained" className={classes.button}>
                        인증확인
                      </Button>
                    ),
                    inputProps: { 'title': '비밀번호 입력' },
                  }}
                />
              </Box> */}
            </Grid>
            <Grid item>
              <CustomVacantButton
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.signupBtn}
                onClick={() => history.push('/signup')}
              >초간단 회원가입
              </CustomVacantButton>
            </Grid>
            <Grid container direction="row" justify="space-around" alignItems="center" style={{marginTop: 50}}>
              <Grid item>
                <Grid container direction="column" justify="center" alignItems="center">
                  <div id="naverIdLogin" className={classes.naverBtn} />
                  <Typography variant="body1" align="center">{!matches ? "네이버로 로그인" : <span>네이버로<br></br>로그인</span>}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" justify="center" alignItems="center">
                  <img className={classes.snsBtn} src={BtnLoginKakao} onClick={kakaologin} />
                  <Typography variant="body1" align="center">{!matches ? "카카오로 로그인" : <span>카카오로<br></br>로그인</span>}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" justify="center" alignItems="center">
                  <img className={classes.snsBtn} id="googleBtn" data-onsuccess="onSignIn" src={BtnLoginGoogle} />
                  <Typography variant="body1" align="center">{!matches ? "구글로 로그인" : <span>구글로<br></br>로그인</span>}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  idInquiryWrap: {
    paddingTop: 100,
    paddingBottom: 190,
  },
  idInquiryForm: {
    width: 420,
    marginLeft: 20,
    marginRight: 20,
  },
  inputForm: {
    paddingTop: 60,
    paddingBottom: 10,
  },
  signupBtn: {
    marginTop: 20,
  },
  button: {
    width: 96,
    height: 53,
    borderRadius: 8,
    padding: 0,
    backgroundColor: '#e4e4e9',
    position: 'absolute',
    right: 0,
    color: '#fff',
    fontSize: 16,
    boxShadow: 'none'
  },
  naverBtn: {
    '& a': {
      '& img': {
        borderRadius: 30
      }
    }
  },
  snsBtn: {
    width: 60,
    height: 60,
    cursor: 'pointer',
    marginBottom: 5
  }
}));

export default inject('loginStore')(observer(IDInquiryForm));
