import React from 'react';
import { Button } from '@material-ui/core';
import { IconCalendar } from '../../static/images/icon';

const ButtonCalendar = (props) => {
  const { disabled } = props;
  return (
    <Button
      disabled={(disabled ? true : false)}
      aria-label="날짜선택"
    >
      <img src={IconCalendar} alt="" /> 
    </Button>
  );
};

export default ButtonCalendar;