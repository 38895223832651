import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Footer from './Footer';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import HeaderM from './HeaderM';
import FooterM from './FooterM';

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    'html, body, #root': {
      height: '100%',
      backgroundColor: '#fff',
    },
  },
  container: {
    width: '100%',
    padding: 0,
  }
}));

function MainLayout({ match, children }) {
  const classes = useStyles();
  
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      {
        matches
          ? <HeaderM type={match.path === '/company' ? 'mainAppbar' : ''} />
          : <Header type={match.path === '/company' ? 'mainAppbar' : ''} />
      }
      <div className={classes.container}>{children}</div>
      {
        matches
          ? <FooterM />
          : <Footer />
      }
    </React.Fragment>
  );
}

export default withRouter(MainLayout);