import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ServiceView from '../components/ServicePage/ServiceView';
import ServiceViewM from '../components/ServicePage/ServiceViewM';

function ServicePage(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <React.Fragment>
      {
        matches ? <ServiceViewM /> : <ServiceView />
      }
    </React.Fragment>
  );
}

export default ServicePage;