import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { inject } from 'mobx-react';
import { Typography, useTheme, useMediaQuery } from '@material-ui/core';

function GuideMessage({ title, mTitle, subTitle, resultTitle, cmStore }) {
  const classes = useStyles();
  const { setMobileTitle } = cmStore;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  
  useEffect(() => {
    setMobileTitle(mTitle ? mTitle : title);
  }, [])

  return (
    <React.Fragment>
      { !matches && <Typography className={classes.title}>{title}</Typography> }
      { (matches && resultTitle) && <Typography className={classes.title}>{resultTitle}</Typography>}
      <Typography className={classes.sub_title} align={matches ? 'center' : 'left'}>
        <span dangerouslySetInnerHTML={{ __html: subTitle }} />
      </Typography>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#F77C40',
    fontSize: 36,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  sub_title: {
    color: '#63554e',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  }
}));

export default inject('cmStore')(GuideMessage);