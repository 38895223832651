import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { ServiceTitleM, AddressM, GoodsAndCountM, PayBoxM, TotalBoxM } from '../common/eachService';
import { ImgBoxLarge, ImgBoxMedium, ImgBoxSmall, ImgPapers } from '../../static/images';
import DeliveryOptionM from './DeliveryOptionM';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contentone:  {
    width: '100%',
    background: '#ffffff',
    margin: '20px 0',
    borderRadius: 10,
    padding: '40px 10px',
  }
}));
function QuickServiceViewM({ cmStore }) {
  const cs = useStyles();
  const { setMobileTitle } = cmStore;

  const goodsItem = [
    {
      idx: 0,
      title: '서류',
      tip: '팔레트',
      icon: ImgPapers,
      name: 'documentCnt',
      total: '10',
      tipTitle: '<span class="pColor">계약서, 일반A4서류, 핸드폰, USB</span> 등',
      tipContext: '<span class="pColor">중량 1kg 이하, A4용지</span><br/> 수납이 가능한 일반 서류 및 소형 물품'
    },
    {
      idx: 1,
      title: '소박스',
      tip: '박스',
      icon: ImgBoxSmall,
      name: 'smallBoxCnt',
      total: '4',
      tipTitle: '<span class="pColor">A4박스, 쇼핑백, 운동화박스, 노트북가방, 쇼핑백 </span> 등',
      tipContext: '<span class="pColor">중량 10kg 미만,</span><br/> 3변의 합이 90cm 미만의 물품'
    },
    {
      idx: 2,
      title: '중박스',
      tip: '가구/집기',
      icon: ImgBoxMedium,
      name: 'heavyBoxCnt',
      total: '2',
      tipTitle: '<span class="pColor">사과박스, 라면박스, PC, 중봉</span> 등',
      tipContext: '<span class="pColor">중량 15kg 미만,</span><br/> 3변의 합이 120cm 미만의 물품'
    },
    {
      idx: 3,
      title: '대박스',
      tip: '기타',
      icon: ImgBoxLarge,
      name: 'greateBoxCnt',
      total: '1',
      tipTitle: '<span class="pColor">이사용박스, 카툰박스, 대봉, 종이컵박스</span> 등',
      tipContext: '<span class="pColor">중량 15kg 초과,</span><br/> 3변의 합이 120cm 초과의 물품'
    },
  ]

  useEffect(() => {
    setMobileTitle('');
  }, []);
  
  return (
    <React.Fragment>
      <Container style={{ padding: '0 10px' }}>
        <div className={cs.contentone}>
          <ServiceTitleM />
          <AddressM type="quick" />
          <GoodsAndCountM goodsItem={goodsItem} />
        </div>
        <div className={cs.contentone}>
          <DeliveryOptionM />
        </div>
        <div className={cs.contentone}>
          <PayBoxM />
        </div>
      </Container>
      <TotalBoxM />
    </React.Fragment>
  );
}

export default inject('cmStore')(QuickServiceViewM);