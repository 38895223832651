import React, { useEffect } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Icon, Paper, Typography } from '@material-ui/core';
import { IconDropdownMore } from '../static/images/icon';
import { CustomTextBox } from '../components/common';
import GuideMessageM from '../components/message/GuideMessageM';
import { CustomButton } from '../components/button';

const useStyles = makeStyles((theme) => ({
  inputBox: {
    maxWidth: 500,
    padding: '0 30px',
  },
  button: {
    height: 54,
    borderRadius: 8,
    boxShadow: '0 5px 10px 0 rgb(247 124 64 / 16%)',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
    backgroundColor: '#ffffff',
    '& .imgPin': { width: 30, height: 30 },
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: '0 5px 10px 0 rgb(247 124 64 / 16%)',
    },
  },
  noMsg: {
    marginTop: 10,
    height: 50,
    padding: 10,
    textAlign: 'center',
  }
}));

function DeliveryTrackingPage({ history, myPageStore }) {
  const cs = useStyles();
  const { deliverySel, delivery } = myPageStore;
  const handlePage = (page) =>  history.push(page);

  useEffect(() => {
    deliverySel();
  }, []);

  const orderType = {
    Move: '이사',
    Freight: '화물',
    Quick: '퀵 서비스'
  };

  const kakaoTest = () => {
    window.Kakao.Channel.chat({
      channelPublicId: '_AsExjC'
    });
  }


  return (
    <React.Fragment>
      <Grid className="mainLayout" container direction="column" justify="center" alignItems="center">
        <Grid item className={cs.title}>
          <GuideMessageM 
            title="배송조회"
            subTitle="현재 진행 중인 배송상태를 알려드립니다."
          />
        </Grid>
        <Box height={60}/>
        <Grid className={cs.inputBox} container direction="column" justify="center" alignItems="flex-start">
          <Button
            fullWidth
            variant="contained"
            className={cs.button}
            onClick={(() => handlePage('/detailsofusage'))}
            endIcon={
              <Icon className="imgPin"><img alt="" src={IconDropdownMore} /></Icon>
            }
          >
            <Typography variant="body2" color="primary">사용내역 조회</Typography>
          </Button>
          
          <Box height={40} />
          <Typography variant="body1" color="primary">진행 중인 서비스 ({delivery.length}건)</Typography>
          {
            delivery.length === 0 && (
              <div style={{ width: '100%' }}>
                <Paper className={cs.noMsg} elevation={0} >
                  <Typography variant="body2" color="primary">사용 내역이 존재하지 않습니다.</Typography>
                </Paper>
              </div>
            )
          }
          {
            delivery.map((item, index) => (
              <div style={{ width: '100%' }} key={index}>
                <Box height={15} />
                <CustomTextBox title="서비스 구분" subTitle={orderType[item.orderType]} />
                <Box height={15} />
                <CustomTextBox title="요청시간" subTitle={moment(item.createdDate).format('YYYY-MM-DD HH:mm')} />
                <Box height={15} />
                <CustomTextBox title="출발" subTitle={item.startAddr} />
                <Box height={15} />
                <CustomTextBox title="도착" subTitle={item.endAddr} />
                <Box height={25} />
                <CustomButton
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={kakaoTest}
                >
                  상담하기
                </CustomButton>
                <Box height={50} />
             </div>

            ))
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject('myPageStore')(observer(DeliveryTrackingPage));