import React from 'react';
import Slider from "react-slick";
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Logodonga, Logochosunmedia, Logofujlxerox, Logohana, Logohite, Logohyundai, Logoium,
  Logojgdang, Logokaic, Logokbbank, Logokicc, Logokobaduk, Logolotteglogis,
  Logomoorim, Logonamyangi, Logonccad, Logonestle, Logonhbank, Logonice, Logoonopharma,
  Logopoongsan2, Logopoongsan, Logormhc, Logosamsungwell, Logoshinsegae, Logoskm, Logoskbroad,
  Logostyle, Logotgi, Logotomorrow,
} from '../../static/images/companyLogo';


const useStyles = makeStyles(() => ({
  sliderMain: {
    height: 200,
  },
  companySlider: {
    '& img': {
      width: '200px !important;'
    },
    '& button': {
      display: 'none !important;'
    }
  },
}));

const CompamyImage = ({ url, ...rest }) => (
  <div
    style={{
      width: '200px',
      height: '200px',
      marginRight: '30px',
      backgroundImage: `url(${url})`,
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: 'contain'
    }}
    {...rest}
  />
)

function CompanySlider({isM}) {
  const cs = useStyles();
  const settings = {
    infinite: true,
    slidesToShow: isM ? 2: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <Box className={cs.sliderMain}>
      <Slider className={cs.companySlider} {...settings}>
        <div><CompamyImage url={Logodonga} /></div>
        <div><CompamyImage url={Logochosunmedia} /></div>
        <div><CompamyImage url={Logofujlxerox} /></div>
        <div><CompamyImage url={Logohana} /></div>
        <div><CompamyImage url={Logohite} /></div>
        <div><CompamyImage url={Logohyundai} /></div>
        <div><CompamyImage url={Logoium} /></div>
        <div><CompamyImage url={Logojgdang} /></div>
        <div><CompamyImage url={Logokaic} /></div>
        <div><CompamyImage url={Logokbbank} /></div>
        <div><CompamyImage url={Logokicc} /></div>
        <div><CompamyImage url={Logokobaduk} /></div>
        <div><CompamyImage url={Logolotteglogis} /></div>
        <div><CompamyImage url={Logomoorim} /></div>
        <div><CompamyImage url={Logonamyangi} /></div>
        <div><CompamyImage url={Logonccad} /></div>
        <div><CompamyImage url={Logonestle} /></div>
        <div><CompamyImage url={Logonhbank} /></div>
        <div><CompamyImage url={Logonice} /></div>
        <div><CompamyImage url={Logoonopharma} /></div>
        <div><CompamyImage url={Logopoongsan2} /></div>
        <div><CompamyImage url={Logopoongsan} /></div>
        <div><CompamyImage url={Logormhc} /></div>
        <div><CompamyImage url={Logosamsungwell} /></div>
        <div><CompamyImage url={Logoshinsegae} /></div>
        <div><CompamyImage url={Logoskm} /></div>
        <div><CompamyImage url={Logoskbroad} /></div>
        <div><CompamyImage url={Logostyle} /></div>
        <div><CompamyImage url={Logotgi} /></div>
        <div><CompamyImage url={Logotomorrow} /></div>
      </Slider>

    </Box>
  );
}

export default CompanySlider;